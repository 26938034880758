import Swal from 'sweetalert2'
import $ from 'jquery';
import { reactive, toRaw } from 'vue'
const BASE_TOAST_CONFIGURATION = {
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
    }
}; 
const BASE_CONFIRMATION_CONFIGURATION = {
    showCancelButton: true,
    confirmButtonColor: "#2f5eb3",
    cancelButtonColor: "#2f5eb3",
    confirmButtonText: "Confirm",
    cancelButtonText: "Close"
};
//https://sweetalert2.github.io/
class AlertService {
    closeAlert(timeout = 0){
        //set timeout for 2 seconds before closing
        setTimeout(() => {
            Swal.close();
        }, timeout);
    }
    showDisplayInfoMessage(message){
        const Toast = Swal.mixin(BASE_TOAST_CONFIGURATION);
        Toast.fire({
          icon: "info",
          title: message
        });         
    }
    showDisplaySuccessMessage(message) {
        let defaultSucessConfiguration = {timer: 2000};
        let configuration = JSON.parse(JSON.stringify(BASE_TOAST_CONFIGURATION));        
        $.extend(configuration, defaultSucessConfiguration);
        const Toast = Swal.mixin(configuration);
        Toast.fire({
          icon: "success",
          title: message
        });      
    }      
    showDisplayErrorMessage(message, timer = 15000) {
        let config = BASE_TOAST_CONFIGURATION;
        config.timer = timer;
        const Toast = Swal.mixin(config);
        Toast.fire({
          icon: "error",
          title: message
        });   
    }        
    showInProgressMessage(content, title = 'In progress....'){
        let configuration = JSON.parse(JSON.stringify(BASE_CONFIRMATION_CONFIGURATION));        
        let inProgress = {
            title: '<h5 class="modal-title">'+title+'</h5>',
            html: content,
            showConfirmButton: false,
            showCloseButton: true            
        };
        $.extend(configuration, inProgress);
        Swal.fire(configuration);
    }
    showModalContentMessage(title, content){    
        let configuration = JSON.parse(JSON.stringify(BASE_CONFIRMATION_CONFIGURATION));
        let contentConfiguration = {
            title: '<h5 class="modal-title">'+title+'</h5>',
            html: content,
            showConfirmButton: false,
            showCloseButton: true
        };
        $.extend(configuration, contentConfiguration);
        if (typeof content !== 'undefined') {        
            Swal.fire(configuration);
        }        
    }
    showModalMessage(title, contentList){
        let content = contentList.find((content) => content.title === title);
        if (content !== undefined) {
            content = toRaw(content);
            this.showModalContentMessage(title, content.contentText);
        }
    }
    showConfirmationMessage(callback, title="Cancel Confirmation", content="Are you sure you want revert your changes?"){ 
        let configuration = JSON.parse(JSON.stringify(BASE_CONFIRMATION_CONFIGURATION));
        configuration.title = title;
        configuration.text = content;
        Swal.fire(configuration).then((result) => {
            if (result.isConfirmed) {
                callback();
            }
        });       
    }
    showConfirmationMessageWithParameter(callback, parameter, title="Cancel Confirmation", content="Are you sure you want revert your changes?"){ 
        let configuration = JSON.parse(JSON.stringify(BASE_CONFIRMATION_CONFIGURATION));
        configuration.title = title;
        configuration.text = content;
        Swal.fire(configuration).then((result) => {
            if (result.isConfirmed) {
                callback(parameter);
            }
        });       
    }
}
export default new AlertService();
