<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title  d-none">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> <h4>TEST 1 - NPS Email Template</h4> </div>
      <div class="col-4"> <h4>Download Verbatims</h4> </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="previewEmail">Preview Email</button>
         </div>  
      </div>
      <div class="col-4"> 
        <a
          href="/api/v1/chart/download-survey-verbatims"
          @click.prevent="downloadItem()"
          id="DownloadSurveyRespondentTemplate"
          :class="[this.surveySelected ? '' : 'disabled disable-link']"
          role="button"
          aria-disabled="true"
        >
          <button type="button" class="btn btn-primary mr-2" :disabled="disableButton">
            <span class="align-text-bottom"> Download <font-awesome-icon :icon="['fa', 'download']"/></span>
            <span v-show="modalLoading" class="spinner-border spinner-border-sm"></span>
          </button>
        </a>
      </div>
    </div>
    <div class="row">
      <div v-show="previewEmailClicked && !getDisplayView()" class="text-success advisor-preview-email modal-body">  
            Email is being rendered please hold......
        </div>      
        <div v-show="previewEmailClicked && getDisplayView()" class="advisor-preview-email modal-body">        
            <div>
                <div>From: {{ emailFromAddress }}</div>
                <div>Subject: {{ emailSubject }}</div>
                
            </div>
            <div class="email-body" v-html="emailBody" style="width: 600px;"></div>
        </div>            
    </div>
    <div id="cp-by-functional-need-e-chart" class="chart-row chart-wrapper" style="">
      <div class="e-chart-container" style="width:1075px">
        <div class="title-and-chart" style="width:1075px">      
          <div :id="eChartId" class="e-chart" style="width:1075px">
            <chart :options="chart.options"></chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import UserService from "../services/user.service";
import ErrorService from "../services/error.service";
import AdvisorService from "../services/advisor.service";
import axios from "axios";
import authHeader from "../services/auth-header";
import EChartsMixins from "../mixins/EChartsMixins.vue";
import $ from "jquery";
import { markRaw } from 'vue'; 
import Echarts from "vue-echarts";
import echarts from "echarts";

export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins, DataTableMixins,EChartsMixins],
 components: {},
  props: {},
  data() {
    return {
      title: "At the Playground",
      eChartId: "profile-chart",
        chartOptions: {
  "legend": {
    "show": true,
    "textStyle": {
      "color": "#000",
      "fontSize": 11,
      "fontFamily": "Franklin Gothic Demi Cond"
    },
    "borderColor": "#BFBFBF",
    //"width": "85%",
    "borderWidth": 0,
    "data": [
      "Expectations",
      "TEST 2",
      "TEST 1",
      "TEST 3",
      "Shamrock",
      "Cheney Brothers",
      "Pricing Power (Bar)"
    ],
    //"left": 100,
    orient: 'vertical',
    left: 725,
    top:40,
  //  width: 50,
    type: 'scroll', 
  },
  "toolbox": {
    "show": true,
    "width": 70,
    "height": "auto",
    "itemGap": 20,
    "left": 0,
    //right: 0,
    "feature": {
      "myCustBut2": {
        "name": "snap",
        "title": "Snap",
        "type": "svg",
        "icon": "image://http://localhost:8080/img/chart-headers/camera-duotone.png"
      },
      "saveAsImage": {
        "title": "Save as Image",
        "icon": "image://http://localhost:8080/img/chart-headers/download-duotone.png",
        "pixelRatio": 2
      }
    }
  },
  "tooltip": {
    "axisPointer": {
      "type": "shadow"
    },
    "trigger": "axis",
    "textStyle": {
      "color": "#000",
      "fontSize": 11,
      "fontFamily": "Franklin Gothic Medium Cond"
    },
    "backgroundColor": "#ffffff"
  },
  "grid": [
    {
      //"top": 90,
      left:60,
      top:80,
      "width": 450,
      "containLabel": true,
      "bottom": 16,
      "backgroundColor": "#e4e6e9",
      "show": true
    },
    {
      top:80,
      //"top": 90,
      //"right": 5,
      left: 515,
      "width": 200,
      "containLabel": true,
      "bottom": 16,
      "backgroundColor": "#e4e6e9",
      "show": true
    }
  ],
  "yAxis": [
    {
      "type": "value",
      "gridIndex": 0,
      "axisLabel": {
        "color": "#000",
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond"
      },
      "splitLine": {
        "lineStyle": {
          "color": "#fff"
        }
      },
      "min": 1,
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "max": 10,
      "splitNumber": 10
    },
    {
      "type": "value",
      "gridIndex": 0,
      "axisLabel": {
        "color": "#000",
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond",
        "show": false,
        "formatter": "{value}%"
      },
      "splitLine": {
        "lineStyle": {
          "color": "#fff"
        },
        "show": false
      },
      "min": 0,
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "boundaryGap": true,
      "max": 6
    },
    {
      "type": "value",
      "gridIndex": 1,
      "axisLabel": {
        "color": "#000",
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond",
        "show": false
      },
      "splitLine": {
        "lineStyle": {
          "color": "#fff"
        }
      },
      "min": 1,
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "max": 10,
      "splitNumber": 10
    },
    {
      "type": "value",
      "gridIndex": 1,
      "axisLabel": {
        "color": "#000",
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond",
        "show": true,
        "formatter": "{value}%"
      },
      "splitLine": {
        "lineStyle": {
          "color": "#fff"
        },
        "show": false
      },
      "min": 0,
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "boundaryGap": true,
      "max": 6
    }
  ],
  "xAxis": [
    {
      "type": "category",
      "gridIndex": 0,
      "data": [
        "Food Quality",
        "On-Time, In-Full Delivery",
        "Ease of Doing Business",
        "Support Responsiveness",
        "Food & Operating Safety",
        "Value Overall",
        "Pricing",
        "Business Relationship",
        "Personal Relationship",
        "Insights & Innovation",
        "Food Variety",
        "Technology & Knowledge-Sharing"
      ],
      "axisLabel": {
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond",
        "color": "#000",
        "rotate": 25,
        "hideOverlap": true,
        "interval": 0
      },
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "splitLine": {
        "show": false,
        "lineStyle": {
          "color": "#C0C0C0"
        }
      }
    },
    {
      "gridIndex": 0,
      "data": [
        "",
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12
      ],
      "position": "top",
      "scale": "value",
      "axisTick": {
        "show": true,
        "length": 0,
        "lineStyle": {
          "color": "#000"
        }
      },
      "axisLabel": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "splitLine": {
        "show": true,
        "lineStyle": {
          "color": "#C0C0C0"
        }
      }
    },
    {
      "type": "category",
      "gridIndex": 1,
      "data": [
        "                                           Connected",
        "                                         Like-Minded",
        "                                              Stable",
        "                                         Imaginative",
        "                                             Precise"
      ],
      "axisLabel": {
        "fontSize": 10.5,
        "fontFamily": "Franklin Gothic Demi Cond",
        "color": "#000",
        "rotate": 25,
        "hideOverlap": true,
        "interval": 0
      },
      "axisTick": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "splitLine": {
        "show": false
      }
    },
    {
      "gridIndex": 1,
      "data": [
        1,
        2,
        3,
        4,
        5
      ],
      "position": "top",
      "scale": "value",
      "axisTick": {
        "show": true,
        "length": 0,
        "lineStyle": {
          "color": "#000"
        }
      },
      "axisLabel": {
        "show": false,
        "interval": 0
      },
      "axisLine": {
        "show": false
      },
      "splitLine": {
        "show": false,
        "lineStyle": {
          "color": "#C0C0C0"
        }
      }
    }
  ],
  "series": [
    {
      "name": "Expectations",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "9.611",
        "9.588",
        "9.556",
        "9.556",
        "9.529",
        "9.471",
        "9.333",
        "9.222",
        "9.056",
        "8.778",
        "8.722",
        "8.722"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "rec",
      "symbolSize": [
        21,
        4
      ],
      "zLevel": 10,
      "z": 10
    },
    {
      "name": "TEST 2",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#2F5EB3",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "8.762",
        "8.619",
        "8.476",
        "8.316",
        "8.45",
        "8.286",
        "8.143",
        "8.8",
        "8.524",
        "7.222",
        "8.143",
        "7.85"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "diamond",
      "symbolSize": 13,
      "zLevel": 12,
      "z": 12,
      "lineStyle": {
        "width": 5
      }
    },
    {
      "name": "TEST 1",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "9.0",
        "8.333",
        "8.667",
        "7.667",
        "8.333",
        "8.5",
        "8.333",
        "8.333",
        "8.333",
        "7.667",
        "9.333",
        "8.0"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "TEST 3",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "8.429",
        "6.429",
        "8.714",
        "7.333",
        "7.667",
        "7.857",
        "8.0",
        "8.0",
        "7.667",
        "7.6",
        "8.143",
        "8.143"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Shamrock",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "9.5",
        "9.0",
        "9.25",
        "8.5",
        "9.5",
        "8.75",
        "8.25",
        "8.75",
        "8.75",
        "7.75",
        "8.75",
        "8.0"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Cheney Brothers",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "data": [
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--",
        "--"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Pricing Power (Bar)",
      "type": "bar",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 0,
      "yAxisIndex": 1,
      "gridIndex": 0,
      "data": [
        "3.91",
        "4.33",
        "3.94",
        "3.55",
        "3.55",
        "3.50",
        "3.94",
        "4.88",
        "3.50",
        "4.80",
        "4.00",
        "4.00"
      ],
      "itemStyle": {
        "color": "#D7E4BD"
      },
      "barWidth": 25
    },
    {
      "name": "Header",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 1,
      "yAxisIndex": 0,
      "gridIndex": 0,
      "markArea": {
        "label": {
          "backgroundColor": "#2F5EB3",
          "color": "white",
          "padding": [
            0,
            0,
            10,
            0
          ],
          "fontSize": 8,
          "fontWeight": "bold",
          "height": 24,
          "distance": 1
        },
        "data": [
          [
            {
              "name": "Top Quartile",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 101.76934814453124,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Top Quartile}",
                "offset": [
                  9,
                  0
                ]
              }
            },
            {
              "xAxis": "3"
            }
          ],
          [
            {
              "name": "Second Quartile",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 101.76934814453126,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Second Quartile}"
              },
              "xAxis": "4"
            },
            {
              "xAxis": "6"
            }
          ],
          [
            {
              "name": "Third Quartile",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 101.7693481445313,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Third Quartile}"
              },
              "xAxis": "7"
            },
            {
              "xAxis": "9"
            }
          ],
          [
            {
              "name": "Fourth Quartile",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 101.7693481445313,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Fourth Quartile}"
              },
              "xAxis": "10"
            },
            {
              "xAxis": "12"
            }
          ]
        ]
      }
    },
    {
      "name": "Expectations",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "7.278",
        "7.123",
        "6.85",
        "6.17",
        "4.758"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "rec",
      "symbolSize": [
        21,
        4
      ],
      "zLevel": 10,
      "z": 10
    },
    {
      "name": "TEST 2",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "5.038",
        "4.844",
        "5.063",
        "5.704",
        "4.566"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "diamond",
      "symbolSize": 13,
      "zLevel": 12,
      "z": 12,
      "lineStyle": {
        "width": 5
      }
    },
    {
      "name": "TEST 1",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "7.196",
        "4.318",
        "5.239",
        "6.68",
        "5.446"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "TEST 3",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "6.155",
        "5.928",
        "4.895",
        "6.414",
        "4.748"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Shamrock",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "3.797",
        "6.353",
        "5.045",
        "6.651",
        "4.794"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Cheney Brothers",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "data": [
        "--",
        "--",
        "--",
        "--",
        "--"
      ],
      "emphasis": {
        "disabled": true
      },
      "animation": false,
      "symbol": "circle"
    },
    {
      "name": "Pricing Power (Bar)",
      "type": "bar",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 2,
      "yAxisIndex": 3,
      "gridIndex": 1,
      "data": [
        "5.62",
        "4.00",
        "5.62",
        "3.25",
        "5.62"
      ],
      "itemStyle": {
        "color": "#D7E4BD"
      },
      "barWidth": 25
    },
    {
      "name": "Header",
      "type": "scatter",
      "label": {
        "show": false,
        "color": "#000",
        "fontSize": 11,
        "fontFamily": "Franklin Gothic Medium Cond"
      },
      "avoidLabelOverlap": true,
      "xAxisIndex": 3,
      "yAxisIndex": 2,
      "gridIndex": 1,
      "markArea": {
        "label": {
          "backgroundColor": "#2F5EB3",
          "color": "white",
          "padding": [
            0,
            0,
            10,
            0
          ],
          "fontSize": 8,
          "fontWeight": "bold",
          "height": 24,
          "distance": 1
        },
        "data": [
          [
            {
              "name": "Activity",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 42.93085937499998,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Activity}",
                "offset": [
                  10.75,
                  0
                ]
              }
            },
            {
              "xAxis": "1"
            }
          ],
          [
            {
              "name": "Trust",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 42.93085937500001,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Trust}"
              },
              "xAxis": "2"
            },
            {
              "xAxis": "2"
            }
          ],
          [
            {
              "name": "Assurance",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 42.93085937499998,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Assurance}"
              },
              "xAxis": "3"
            },
            {
              "xAxis": "3"
            }
          ],
          [
            {
              "name": "Curiosity ",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 42.93085937500001,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Curiosity }"
              },
              "xAxis": "4"
            },
            {
              "xAxis": "4"
            }
          ],
          [
            {
              "name": "Strictness",
              "itemStyle": {
                "color": "none"
              },
              "label": {
                "width": 42.93085937500001,
                "overflow": "overflow",
                "ellipsis": "...",
                "rich": {
                  "a": {
                    "align": "center",
                    "fontSize": 12,
                    "fontFamily": "Franklin Gothic Medium Cond",
                    "lineHeight": 35,
                    "color": "#fff",
                    "verticalAlign": "middle"
                  }
                },
                "formatter": "{a|Strictness}"
              },
              "xAxis": "5"
            },
            {
              "xAxis": "5"
            }
          ]
        ]
      },
      "markLine": {
        "name": "line",
        "symbol": "none",
        "label": {
          "show": false
        },
        "lineStyle": {
          "color": "#C0C0C0",
          "type": "solid"
        },
        "data": [
          {
            "name": "start",
            "yAxis": 5.5
          }
        ]
      }
    }
  ],
  "color": [
    "#1C408E",
    "#1C408E",
    "#56821F",
    "#008BDB",
    "#009A66",
    "#CC0001",
    "#D7E4BD"
  ]
},
      disableButton: false,
      modalLoading: false,
      surveyId: SurveyService.getSurveyId(),
      clientTimePeriodId: 11155,
      currentSurveyName: SurveyService.getSurveyName(),
      surveySelected: true,

      advisorId: 1782,
      emailBody: "",
      emailFromAddress: "",
      emailSubject: "",
      refreshModal: true ,
      previewEmailClicked: false     
    };
  },
  methods: {
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - Start*/    
    getDownloadFilename() {
      return "Survey Verbatims - " + this.currentSurveyName;
    },    
    async downloadItem() {
      this.modalLoading = true;
      this.previewEmailClicked = false;
      this.setupDownloadItem();
      await axios({
        url: this.baseUrl + "/api/v1/chart/download-survey-verbatims?survey_id=" + this.surveyId + "&period="+ this.clientTimePeriodId + "&user_id=" + UserService.getUserId(),
        method: "GET",
        responseType: "blob"
      })
      .then(response => this.downloadItemCallback(response))
      .catch(error => this.downloadItemError(error));
      this.modalLoading = false;
    },    
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - End */        
    previewEmail: function(){
      this.previewEmailClicked = true;
      // preview-us-foods-email
      this.previewUSFoodsEmail(this.advisorId, this.previewAdvisorEmailCallback, this);
    },
    previewUSFoodsEmail: async  function(advisorId, callback, chartApp){
      const params = new URLSearchParams();
      params.append("advisorId", advisorId);
      params.append("userId", UserService.getUserId());
      await axios
        .post( AdminService.getBaseUrl() + "/api/v1/import-export" + "/preview-us-foods-email", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("Playground.previewUSFoodsEmail", error, "admin", chartApp);
        });
    },    
    previewAdvisorEmailCallback(response){
      this.$root.processServerResponse();
      this.setDisplayView(true);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.emailFromAddress = response.data.data.from; 
        this.emailSubject = response.data.data.subject; 
        this.emailBody = response.data.data.body; 
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    },    
    initChart: function() {
        let chartDiv = $("#" + this.eChartId)[0];
        this.chart = markRaw(echarts.init(chartDiv));
        this.printChartOptions(this.chartOptions);
        this.chart.setOption(this.chartOptions, true);
        if (this.addWrapper == true){
          $(".e-chart-container")
                  .parent()
                  .parent()
                  .addClass("chart-wrapper");            

        }
        let vm = this;
        this.chart.on('finished', () => {
            vm.saveImage();
        });  
    },    
  },
  created() {},
  mounted() {
    
    let vm = this;
    setTimeout(function() {
      vm.initChart();
      }, 1250);        
  }
};
</script>
<style lang="scss">
</style>
