<template>
  <div class="survey-taker-localization">
    <div v-for="locale in surveyLocaleList" :key="locale.id" class="mr-3" @click="activateLanguage(locale)">
        <div :class="getClass(locale)"></div>
        <div :class="getTranslationTextClass(locale)">{{ locale.translation }}</div>
    </div>
  </div>
</template>

<script>
import LocaleService from "../../services/locale.service";
export default {
  name: "SurveyTakerLocalization",
  mixins: [],
  components: {},
  data() {
    return {
        selectedLanguage: 'en', // Default language
        useDefaultLanguage: true,
        selectedLocale: {}
    };
  },
  props: {
    surveyLocaleList: {
      type: Array,
      default: function() {
        return [];
      }
    }, 
    entryLanguageCode: {
      type: String,
      default: "en"
    },       
  },
  computed: {},
  methods: {
    activateLanguage (locale) {
        if (locale.id == this.selectedLocale.id){
            return;
        }
        this.selectedLanguage = locale.languageCode;
        this.$i18n.locale = locale.languageCode;
        this.selectedLocale = locale;
    },    
    getClass(locale){
      return LocaleService.getFlagClass(locale);
    },
    getTranslationTextClass(locale){
      return LocaleService.getTranslationTextClass(locale);
    }
  },
  created() {
  },
  mounted() {},
  watch: {
    surveyLocaleList: {
      handler(newVal, oldVal) {
        if (this.useDefaultLanguage){
          let useEntryLanguageCode = this.entryLanguageCode != "en"
          let defaultLocale = useEntryLanguageCode ? newVal.find(x => x.languageCode == this.entryLanguageCode) : 
                                                    newVal.find(x => x.selected == true);
          if (typeof defaultLocale !== 'undefined'){
              this.activateLanguage(defaultLocale);
          } else {
              this.selectedLanguage = 'en';
              this.$i18n.locale = 'en';
          }
          this.useDefaultLanguage = false;
        }
      },
      deep: true
    },      
/*    Remove once everything is working with vue 3 migration
    surveyLocaleList: function () {
      let useEntryLanguageCode = this.entryLanguageCode != "en"
      let defaultLocale = useEntryLanguageCode ? this.surveyLocaleList.find(x => x.languageCode == this.entryLanguageCode) : 
                                          this.surveyLocaleList.find(x => x.selected == true);
        if (typeof defaultLocale !== 'undefined'){
            this.activateLanguage(defaultLocale);
        } else {
            this.selectedLanguage = 'en';
            this.$i18n.locale = 'en';
        }
    },
*/    
    selectedLocale: function () {
        this.$emit('selectedLocale', this.selectedLocale);
    }
  }
};
</script>
