<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import AlertService from "../services/alert.service";

export default {
  name: "DataTableMixins",
  data() {
    return {
      table: {
        id: "",
        class: "table display wrap row-border table-hover",
        data: [],
        dataTable: {},
        configuration: {
          dom: 'B<"top"if>rt<"bottom"lp><"clear">',
          searching: true,
          paging: true,
          select: true,
          processing: true,
          responsive: true,
          destroy: true,
          info: true,
          ordering: true,
          keys: true,
          bSort: false,
          pageLength: 25,
          buttons: [
            {
              extend: "copy",
              text: "Copy to Clipboard",
              className: "btn btn-primary"
            },
            {
              extend: "csv",
              text: "Download Data",
              className: "btn btn-primary"


              ,

exportOptions : {
  modifier : {
      // DataTables core
      order : 'current',  // 'current', 'applied', 'index',  'original'
      page : 'all',      // 'all',     'current'
      search : 'applied'     // 'none',    'applied', 'removed'
  }
}


            }
          ]
        },
        rowSelected: false,
        rowSelectedId: 0
      }
    };
  },
  props: {},
  methods: {
    getDataFromSelectedRow() {
      if (this.table.data) {
        return this.table.data.find(x => x.id == this.table.rowSelectedId);
      } else {
        return {};
      }
    },
    getTableDataWithDataCallback(response, data) {
      this.$root.processServerResponse();
      this.table.data = data;
      this.setupTable();
    },
    getTableDataCallback(response) {
      this.$root.processServerResponse();
      this.table.data = response.data.data;
      this.setupTable();
    },
    setupTable(){
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      const vm = this;
      this.table.dataTable
        .on("click", "tr", function(e, dt, type, indexes) {
          vm.table.rowSelected = true;
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          $("#" + vm.table.id + " .selected").removeClass("selected");
          $(this).toggleClass("selected");
          if (!jQuery.isEmptyObject(rowData)) {
            vm.table.rowSelectedId = rowData[0].id;
            vm.postRowSelect(rowData[0], true);
          }
        })
        .on("deselect", function(e, dt, type, indexes) {
          vm.table.rowSelected = false;
          vm.table.rowSelectedId = 0;
          if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
          }
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          if (!jQuery.isEmptyObject(rowData)) {
            vm.postRowSelect(rowData[0], false);
          }
        });
      this.buildResponsiveTable();
    },
    buildResponsiveTable() {
      if (this.table.configuration.responsive) {
        const vm = this;
        setTimeout(function() {
          var responsiveTable = vm.table.dataTable;
          if (typeof responsiveTable != "undefined" && typeof responsiveTable.responsive != "undefined"){
            responsiveTable.responsive.rebuild();
            responsiveTable.responsive.recalc();
          }
        }, 500);
      }
    },
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DataTableMixins.downloadItem", error, "survey-designer", this);
      this.$gtag.exception("Error Downloading the file");
    },
    getDownloadFilename() {
      return this.getDataFromSelectedRow().downloadSurveyName;
    },
    clickLink(blob, includeExtention = false, extension = ".xlsx"){
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.getDownloadFilename();
      if (includeExtention == true){
        link.download += extension;
      }
      link.click();
      URL.revokeObjectURL(link.href);      
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      this.clickLink(blob, true);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },
    async setupDownloadItem(setModalLoading = true) {
      this.$root.processServerRequest();
      if (setModalLoading == true){
        this.setModalLoading(true);
      }
      AdminService.displayInfoMessage({
        text: "Please stand by as we generate your file….Once complete, the file will appear in your Downloads folder.",
        duration: -1
      })
      this.table.rowSelected = false;
    },
    async downloadItem() {
      this.setupDownloadItem();
      await axios({
        url: this.downloadItemUrl,
        method: "GET",
        responseType: "blob"
      })
        .then(response => this.downloadItemCallback(response))
        .catch(error => this.downloadItemError(error));
    },
    postRowSelect(rowData) {}
  },
  created() {}
};
</script>
