import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import ClientService from "./client.service";
import TableService from "./table.service";
import AlertService from "./alert.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_LOCALE_URL = AdminService.getBaseUrl() + "/api/v1/locale";

class LocaleService {
    async importSurveyLocaleFile(file, surveyLocaleId, callback, chartApp) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("surveyLocaleId", surveyLocaleId);
      formData.append("userId", UserService.getUserId());
      await axios
        .post(API_LOCALE_URL + "/import-survey-locale-file", formData, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("LocaleService.importSurveyLocaleFile", error, "survey-designer", chartApp);
        });
    }
    async downloadSurveyLocaleFile(surveyLocaleId, callback, chartApp) {
      await axios({
        url: API_LOCALE_URL + "/download-survey-locale-template?id=" + surveyLocaleId + "&userId=" + UserService.getUserId(),
        method: "GET",
        responseType: "blob"
      })
      .then(response => callback(response))
      .catch(error => {});
    }
    async deleteLocale(localeId, callback, chartApp) {
      const params = new URLSearchParams();
      params.append("localeId", localeId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_LOCALE_URL + "/delete-locale", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("LocaleService.deleteLocale", error, "survey-designer", chartApp);
        });
    }

    async saveLocale(locale, mode, callback, app){
      let params = new URLSearchParams();
      params.append("localeId", locale.localeId);
      params.append("languageId", locale.languageId);
      params.append("countryId", locale.countryId);
      params.append("description", locale.localeDescription);
      params.append("mode", mode);
      params.append("userId", UserService.getUserId());
      await axios
      .post(API_LOCALE_URL + "/save-locale", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
          AlertService.closeAlert();
          console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
          error.message += ErrorService.mergeError(error.response);
          error.message += app.surveyTakerMessage;
          ErrorService.processError("LocaleService.saveLocale", error, "survey-taker", app);
      });          
    }
    async getLocaleOptions(callback, app){
          let params = new URLSearchParams();
          params.append("userId", UserService.getUserId());
          await axios
          .post(API_LOCALE_URL + "/get-locale-options", params, { headers: await authHeader() })
          .then(response => callback(response))
          .catch(error => {
              AlertService.closeAlert();
              console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
              error.message += ErrorService.mergeError(error.response);
              error.message += app.surveyTakerMessage;
              ErrorService.processError("LocaleService.getLocaleOptions", error, "survey-taker", app);
          });          
    }
    async getLocaleList(callback, app){
        let params = new URLSearchParams();
        params.append("userId", UserService.getUserId());
        await axios
            .post(API_LOCALE_URL + "/get-locale-list", params, { headers: await authHeader() })
            .then(response => callback(response))
            .catch(error => {
                AlertService.closeAlert();
                console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
                error.message += ErrorService.mergeError(error.response);
                error.message += app.surveyTakerMessage;
                ErrorService.processError("LocaleService.getLocaleList", error, "survey-taker", app);
            });
    }
    async deleteSurveyLocale(surveyLocaleId, callback, chartApp) {
      const params = new URLSearchParams();
      params.append("surveyLocaleId", surveyLocaleId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_LOCALE_URL + "/delete-survey-locale", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveyService.deleteSurveyLocale", error, "survey-designer", chartApp);
        });
    }

    async saveSurveyLocale(locale, callback, app){
      let params = new URLSearchParams();
      params.append("surveyLocaleId", locale.surveyLocaleId);
      params.append("localeId", locale.localeId);
      params.append("surveyId", locale.surveyId);
      params.append("isDefault", locale.isDefault);
      params.append("userId", UserService.getUserId());
      await axios
      .post(API_LOCALE_URL + "/save-survey-locale", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
          AlertService.closeAlert();
          console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
          error.message += ErrorService.mergeError(error.response);
          error.message += app.surveyTakerMessage;
          ErrorService.processError("LocaleService.saveSurveyLocale", error, "survey-taker", app);
      });          
    }

    async getSurveyLocaleOptions(callback, app){
      let params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      await axios
      .post(API_LOCALE_URL + "/get-survey-locale-options", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
          AlertService.closeAlert();
          console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
          error.message += ErrorService.mergeError(error.response);
          error.message += app.surveyTakerMessage;
          ErrorService.processError("LocaleService.getSurveyLocaleOptions", error, "survey-taker", app);
      });          
    }
    async getSurveyLocaleList(callback, app){
        let params = new URLSearchParams();
        params.append("userId", UserService.getUserId());
        await axios
            .post(API_LOCALE_URL + "/get-survey-locale-list", params, { headers: await authHeader() })
            .then(response => callback(response))
            .catch(error => {
                AlertService.closeAlert();
                console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
                error.message += ErrorService.mergeError(error.response);
                error.message += app.surveyTakerMessage;
                ErrorService.processError("LocaleService.setupSurvey", error, "survey-taker", app);
            });
    }
    async getSurveyLocaleListBySurveyId(surveyId, callback, app){
      let params = new URLSearchParams();
      params.append("surveyId", surveyId);
      params.append("userId", UserService.getUserId());
      await axios
          .post(API_LOCALE_URL + "/get-survey-locale-list-by-survey-id", params, { headers: await authHeader() })
          .then(response => callback(response))
          .catch(error => {
              AlertService.closeAlert();
              console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
              error.message += ErrorService.mergeError(error.response);
              error.message += app.surveyTakerMessage;
              ErrorService.processError("LocaleService.setupSurvey", error, "survey-taker", app);
          });
  }
    getFlagClass(locale){
      let classText = "flag-layout " + locale.countryCode.toLowerCase();
      if (locale.selected == true){
          classText += " selected ";
      }
      return classText;
    }   
    getTranslationTextClass(locale){
      let classText = "translation-text ";
      if (locale.selected == true){
          classText += " selected ";
      }
      return classText;
    }
    getFullLocaleUrl(locale){
      if (typeof locale.languageCode != "undefined" && locale.languageCode != null && locale.languageCode != "" &&
        typeof locale.surveyUrl != "undefined" && locale.surveyUrl != null && locale.surveyUrl != "") {
            return SurveyService.getBaseInterviewUrl() + "/" + locale.languageCode + "/" + locale.clientId + "/s/"+ locale.surveyUrl;
        } else {
            return "";
        }
    }
    getDefaultLocale(){
      return  {
        localeId: 0,
        languageId: 0,
        countryId: 0,
        localeDescription: "",
      };
    }
    getSurveyDefaultLocale(){
      return  {
        id: 0,
        surveyLocaleId: 0,
        localeId: 0,
        name: "Select Survey",
        surveyId: "Select Survey",
        isDefault: 'N'
      };
    }
    getLocaleColumns(){
        return [
            {
                title: "Locale Id",
                data: "id",
                createdCell: function (td, cellData, rowData, row, col) {
                    $(td).addClass("text-center");
                },
                className: "",
                name: "id",
                width: "3rem"
            },
            { title: "Country", data: "countryName", name: "countryName", width: "5rem" },
            { title: "Country Code", data: "countryCode", name: "countryCode", width: "2rem" },
            { title: "Language", data: "languageName", name: "languageName", width: "5rem" },
            { title: "Language Code",data: "languageCode", name: "languageCode", width: "3rem" },
            { title: "Description",data: "localeDescription", name: "localeDescription", width: "9rem" },
            {
                title: "Date Created",
                data: "dateCreated",
                type: "date",
                name: "dateCreated",
                width: "5rem",
                render: function(data, type, row) {
                  return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
                }
              },
              {
                title: "Date Updated",
                data: "dateModified",
                type: "date",
                name: "dateModified",
                width: "5rem",
                render: function(data, type, row) {
                  return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
                }
              }            
        ];
    }
    getSurveyLocaleColumnsMini(){
      return [
          {
            title: "Survey Locale Id",
            data: "id",
            createdCell: function (td, cellData, rowData, row, col) {
                $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "3rem"
        },
        { title: "Locale Id", data: "localeId", name: "localeId", width: "3rem" },
        { title: "Country", data: "countryName", name: "countryName", width: "5rem" },
        { title: "Country Code", data: "countryCode", name: "countryCode", width: "3rem" },
        { title: "Language", data: "languageName", name: "languageName", width: "5rem" },
        { title: "Language Code",data: "languageCode", name: "languageCode", width: "4rem" },
        {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Date Updated",
            data: "dateModified",
            type: "date",
            name: "dateModified",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          }            
      ]; 
    }
    getSurveyLocaleColumns(){
        return [
            {
                title: "Survey Locale Id",
                data: "id",
                createdCell: function (td, cellData, rowData, row, col) {
                    $(td).addClass("text-center");
                },
                className: "",
                name: "id",
                width: "6rem"
            },
            {
                title: "Survey Id",
                data: "surveyId",
                createdCell: function(td, cellData, rowData, row, col) {
                  $(td).addClass("text-left");
                },
                className: "",
                name: "surveyId",
                width: "5rem"
              },
              {
                title: "Survey Name",
                data: "surveyName",
                name: "surveyName",
                width: "10rem",
                createdCell: function(td, cellData, rowData, row, col) {
                  $(td).addClass("text-left");
                },
                className: ""
              },
            { title: "Default", data: "isDefault", name: "isDefault", class: "text-left", width: "5rem" },
            { title: "Locale Id", data: "localeId", name: "localeId", class:"text-left", width: "5rem" },
            { 
              title: "URL", 
              data: "surveyUrl", 
              name: "surveyUrl",
              class: "text-left",  
              width: "27rem", 
              render: function(data, type, row) {
                let languageCode = row.languageCode;
                let surveyUrl = row.surveyUrl;
                if (typeof languageCode != "undefined" && languageCode != null && languageCode != "" &&
                    typeof surveyUrl != "undefined" && surveyUrl != null && surveyUrl != "") {
                    return SurveyService.getBaseInterviewUrl() + "/" + row.languageCode + "/" + row.clientId + "/s/"+ row.surveyUrl;
                } else {
                    return data;
                }
              }
            },
            { title: "Country", data: "countryName", name: "countryName",class: "text-left", width: "5rem" },
            { title: "Country Code", data: "countryCode", name: "countryCode", class: "text-left", width: "5rem" },
            { title: "Language", data: "languageName", name: "languageName", class: "text-left", width: "6rem" },
            { title: "Language Code",data: "languageCode", name: "languageCode", class: "text-left", width: "6rem" },
            {
                title: "Date Created",
                data: "dateCreated",
                type: "date",
                name: "dateCreated",
                width: "6rem",
                render: function(data, type, row) {
                  return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
                }
              },
              {
                title: "Date Updated",
                data: "dateModified",
                type: "date",
                name: "dateModified",
                width: "6rem",
                render: function(data, type, row) {
                  return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
                }
              }            
        ];
    }
}

export default new LocaleService();
