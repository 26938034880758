<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <design-menu></design-menu>
    <div v-if="permissionToView">
      <div v-if="getDisplayView()" class="include-sections">
        <div class="row no-gutters">
          <div class="col-lg-6 col-sm-12">
            <div class="row border-bottom pb-3 no-gutters">
              <div class="col-4">
                <modal-link modal-id="Include Sections" :content-list="pageContent"></modal-link>
                <span class="section-label">Sections to rate</span>
              </div>
              <div class="col-8">
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-actual-section')"
                  field-type="checkbox"
                  field-class="form-group form-inline"
                  field-label="My Company"
                  :build-survey="true"
                  default-field-name="include-actual-section"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-ideal-section')"
                  field-type="checkbox"
                  field-class="form-group form-inline"
                  field-label="The Ideal"
                  default-field-name="include-ideal-section"
                  :build-survey="true"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-competitor-section')"
                  field-type="checkbox"
                  field-class="form-group form-inline"
                  field-label="My Competitors"
                  default-field-name="include-competitor-section"
                  :build-survey="true"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
              </div>
            </div>
            <div class="row border-bottom pb-3 pt-3 no-gutters">
              <div class="col-4 text-left">
                <modal-link modal-id="Competitive Strengths" :content-list="pageContent"></modal-link>
                <span class="section-label pr-3">Pricing Power</span>
              </div>
              <div class="col-8">
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('enable-competitive-trends')"
                  field-type="checkbox"
                  field-label="Include Pricing Power Section "
                  field-class="form-group form-inline"
                  default-field-name="enable-competitive-trends"
                  :build-survey="true"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="row no-gutters">
              <div class="col-4 text-left">
                <modal-link modal-id="Alert(s)" :content-list="pageContent"/>
                <span class="section-label">Alert(s)</span>
              </div>
              <div class="col-8">
                <survey-field
                  ref="enableNpsField"
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('enable-nps')"
                  field-type="checkbox"
                  field-label="Include Net Promoter Score "
                  field-class="form-group form-inline"
                  default-field-name="enable-nps"
                  default-field-value="Y"
                  :build-survey="true"
                  @save="this.enableNPSNotificationEvent"
                ></survey-field>
              </div>
            </div>
            <div v-show="displayNPSQuestions" class="row no-gutters">
              <div class="col-4"></div>
              <div class="col-8">
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-nps-comment')"
                  field-type="checkbox"
                  field-label="Include Net Promoter Score Comments (open text box)"
                  field-class="form-group form-inline"
                  default-field-name="include-nps-comment"
                  default-field-value=""
                  :build-survey="true"
                  @save="this.saveSurveyField"
                ></survey-field>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('enable-nps-notification')"
                  field-type="checkbox"
                  field-label="Notify for any Score less than: "
                  field-class="form-group form-inline"
                  default-field-name="enable-nps-notification"
                  default-field-value=""
                  :disable-field="disableNpsNotificationField"
                  @save="this.enableNpsNotificationFieldsEvent"
                ></survey-field>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('nps-minimum-score')"
                  field-class="form-control col-1 p-1"
                  field-type="text-box"
                  field-label=""
                  default-field-name="nps-minimum-score"
                  :disable-field="disableNpsMinimumScoreField"
                  @save="this.saveSurveyField"
                ></survey-field>
                <survey-field
                  :disable-field="disableNpsEmailaddresses"
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('nps-notification-email-addresses')"
                  field-class=""
                  field-type="text-area"
                  field-label="Send notifications to these email addresses"
                  default-field-name="nps-notification-email-addresses"
                  default-field-value=""
                  @save="this.saveSurveyField"
                ></survey-field>
                <span class="section-label">[Please separate email address with semi-colons]</span>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-personal-information')"
                  field-class="form-group form-inline"
                  field-type="checkbox"
                  field-label="Include Customer information (if permission given) in messages"
                  default-field-name="include-personal-information"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
                <survey-field
                  :survey-id="this.survey.surveyId"
                  :survey-field-object="this.getSurveyField('include-comments')"
                  field-class="form-group form-inline"
                  field-type="checkbox"
                  field-label="Include Comments"
                  default-field-name="include-comments"
                  default-field-value="Y"
                  @save="this.saveSurveyField"
                ></survey-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col">
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.goToTheCompetitors">
              {{ buttonText }}
            </button>
          </div>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
      </div>
    </div>
    <div v-else class="include-sections text-center">You do not have permission to view this page</div>
  </div>
</template>
<script>
import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import SurveyField from "../../components/SurveyField.vue";
import NeedsSection from "../../components/NeedsSection.vue";
import SurveyStatus from "../../components/SurveyStatus.vue";

export default {
  name: "IncludeSections",
  mixins: [StudyDesignMixins],
  components: {
    SurveyField,
    SurveyStatus
  },
  props: {},
  computed: {},
  data() {
    return {
      title: "Sections",
      displayNPSQuestions: false,
      disableNpsNotificationField: false,
      disableNpsMinimumScoreField: false,
      disableNpsEmailaddresses: false,
      pageNumber: 1
    };
  },
  methods: {
    enableNPSNotification: function(value) {
      if (value === "Y") {
        this.disableNpsNotificationField = false;
        this.displayNPSQuestions = true;
      } else {
        this.disableNpsNotificationField = true;
        this.displayNPSQuestions = false;
      }
      this.enableNpsNotificationFields(value);
    },
    enableNPSNotificationEvent: function(result) {
      this.saveSurveyField(result);
      this.enableNPSNotification(result.surveyFieldValue);
    },
    enableNpsNotificationFields: function(value) {
      if (value === "Y") {
        this.disableNpsMinimumScoreField = false;
        this.disableNpsEmailaddresses = false;
      } else {
        this.disableNpsMinimumScoreField = true;
        this.disableNpsEmailaddresses = true;
      }
    },
    enableNpsNotificationFieldsEvent: function(result) {
      this.saveSurveyField(result);
      this.enableNpsNotificationFields(result.surveyFieldValue);
    },
    postLoadData: function() {
      this.setDisplayView(true);
      if (typeof this.getSurveyField("enable-nps") != "undefined") {
        let enableNPSValue = this.getSurveyField("enable-nps").surveyFieldValue;
        this.enableNPSNotification(enableNPSValue);
      } else {
        this.enableNPSNotification("Y");
      }
    }
  },
  created() {},
  mounted() {}
};
</script>
