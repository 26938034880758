<template>
  <div id="dashboard-moat-comps" :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/predictive" @click="$parent.logViewDetailsEvent(title)">            
            {{ title }}
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Moat Comps" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show(modalName)"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-contributions pr-0 pl-1" 
            style="flex-direction: column;justify-content: end;">
        <div class="text-center pb-2">Discover comps for</div>
        <div class="row no-gutters" style="margin-right: -20px;">
          <div class="col-6 pr-1">
            <dashboard-card-dropdown 
                    :display-label="false" :label="''" :options="competitor_options" 
                    :read-only="readOnly" :default-value="competitor"  :default-dropdown-option="defaultCompetitorDropdownName"
                    @change="saveCompetitor">
            </dashboard-card-dropdown>        
          </div>
          <div class="col no gutters text-center d-flex align-items-center">-OR-</div>
          <div class="col-5">
            <dashboard-card-dropdown 
                    :display-label="false" :label="''" :options="surveyGroupList" 
                    :read-only="readOnly" :default-value="surveyGroupId" :default-dropdown-option="'My Group'" 
                    @change="saveSurveyGroup">
            </dashboard-card-dropdown>
          </div>
        </div>          
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div id="moat-comps-snap-id" class="dashboard-card-body"  v-show="displayDashboard" style="height: 250px">
      <moat-comps-body v-show="displayDashboard" 
        :display-dashboard="displayDashboard"
        :rankings-by-company="rankingsByCompany" 
        :rankings-by-framework="rankingsByFramework" 
        :not-enough-data="notEnoughData"
        :no-data="noData"/>
    </div>
    <dashboard-modal 
      :modal-id="dashboardId" 
      :chart="this.chart" 
      :client-chart="this.clientChart" 
      :dashboard-card-report-page-type-name="reportTypeName" 
      :rankings-by-company="rankingsByCompany" 
      :rankings-by-framework="rankingsByFramework" 
      :no-data="noData"      
      :not-enough-data="notEnoughData"
      :show-moat-comps-display="displayDashboard"
      :modal-title="title" 
       />    

    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import SurveyService from "../../services/survey.service";
import MoatCompsBody from "../dashboard/body/MoatCompsBody.vue";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardDropdown from "./DashboardCardDropdown";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

export default {
  name: "DashboardMoatComps",
  mixins: [DashboardMixins],
  components: { SaveChart,DashboardCardSkeleton,DashboardCardDropdown,MoatCompsBody},
  data() {
    return {
      initialSurveyGroupsLoaded: false,
      initialCompetitorsLoaded: false,
      noData: true,
      notEnoughData: false,
      title: "Moat Comps",
      surveyGroupId: "",
      competitor: "",
      competitor_options: [],
      rankingsByCompany: [],
      rankingsByFramework: [],
      surveyGroupList: [],
      defaultCompetitorDropdownName: SurveyService.getSurveyName(),
      modalName: "moat-comps-save-chart-confirmation",     
    };
  },
  props: {
    inputGroupOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },       
    entryClientChart: {
      type: Object,
      default: function () {
        return {};
      },
    },
    inputCompetitorOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  computed: {
    showCompetitorsDropdown: function(){
      return this.competitor_options.length > 0;
    }    
  },
  mounted() {
    this.setChartDetails();
  },
  created() {},
  methods: {
    initialLoadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardMoatComps::initialLoadData");
      this.clearData();
      if (this.loadCompetitorData == true){
        await ClientCompetitorService.getCompetitors(this.$parent.$parent.clientId, this.timePeriodId, this.loadCompetitors, false);
        this.initialCompetitorsLoaded = true;
      }
      if (this.autoGenerateChartRun){
          this.processAutoGenerateChart();
      } else {
        this.setSurveyGroupId();
        this.setCompetitor();
      }
      
      this.setChartDetails();
      this.updateCard();
    },
    loadData: function() {
      // eslint-disable-next-line no-console
      // console.log("DashboardMoatComps::loadData");
      if (this.autoGenerateChartRun){
        this.initialSurveyGroupsLoaded = true;
        this.initialCompetitorsLoaded = true;
        this.initialLoadData();
      } else {
        this.displayDashboard = true; 
      }
      this.setChartDetails();
    },
    saveSurveyGroup(value){
//      console.log("saveSurveyGroup::" + value);
        this.surveyGroupId = value;
        this.saveSurveyGroupId();
        this.updateCard();
    },
    saveSurveyGroupId() {
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_MOAT_COMPS_GROUP_SETTING_TYPE, this.surveyGroupId);
    },
    setSurveyGroupId() {
//      console.log("DashboardMoatComps::setSurveyGroupId")
//      console.log("surveyGroupList length: " + this.surveyGroupList.length);
      this.surveyGroupId = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_MOAT_COMPS_GROUP_SETTING_TYPE,
          this.surveyGroupId, this.surveyGroupList);
//      console.log("surveyGroupId: " + this.surveyGroupId);
      let surveyGroupIdFound = this.surveyGroupList.find(x => x.value == Number(this.surveyGroupId));
      if (typeof surveyGroupIdFound == "undefined") {
        this.surveyGroupId = "";
      }           
    },    
    setCompetitor() {
      this.competitor = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_MOAT_COMPS_COMPANY_SETTING_TYPE, this.competitor, this.competitor_options);

      let competitorFound = this.competitor_options.find(x => x.value == Number(this.competitor));
      if (typeof competitorFound == "undefined") {
        this.competitor = "";
      } 
    },
    saveCompetitor: function (value) {
      this.competitor = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardCompetitorSettingType, this.competitor);
      this.updateCard();
    },    
    updateCard: function() {
      if (this.initialCompetitorsLoaded == true && this.initialSurveyGroupsLoaded == true){
        //console.log("DashboardMoatComps::updateCard")
        this.displayDashboard = false;
        let vm = this;
        setTimeout(function() {
          if (vm.timePeriodId != "") {
            vm.displayDashboard = false;
            DashboardService.getMoatComps(vm.timePeriodId, vm.surveyGroupId, vm.competitor, vm.getMoatCompsCallback, vm);
          } else {
            vm.displayDashboard = true;
          }
        }, 1000);
      }
    },  
    loadCompetitors(data) {
      this.competitor_options = [];
      for (var i = 0; i < data.length; i++) {
        this.competitor_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    setChartDetails(){
        this.chartTitle = "Moat Comps";
        this.chartTypeName = "dashboard-moat-comps";
        this.containerId = "moat-comps-snap-id";
    	  this.reportTypeName = "Dashboard - Moat Comps";
        this.modalName = "moat-comps-save-chart-confirmation";
        this.defaultCompetitorDropdownName = SurveyService.getSurveyName();
    },
    setSurveyGroupList(){
      this.surveyGroupList = JSON.parse(JSON.stringify(this.inputGroupOptions)); 
     // this.surveyGroupList = this.surveyGroupList.filter(x => x.name != "W-30 Index");
      this.initialSurveyGroupsLoaded = true;
    },
    clearData() {
      this.displayDashboard = false;
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isSurveyGroupId = criteriaParameters[0] == "surveyGroupId" ? true : false;
  		if (isSurveyGroupId){
        this.surveyGroupId = criteriaParameters[1];
  		}
      let isCompetitor = criteriaParameters[0] == "competitor" ? true : false;
  		if (isCompetitor){
        this.competitor = criteriaParameters[1];
  		}      
    },    
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("surveyGroupId",this.surveyGroupId);
      params.append("competitor",this.competitor);      
      return params;
    },  
    getMoatCompsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
        this.noData = response.data.data.noData;
        this.notEnoughData = response.data.data.notEnoughData;
        if (this.noData == false && this.notEnoughData == false){
          this.rankingsByCompany = response.data.data.rankingsByCompany;
          this.rankingsByFramework= response.data.data.rankingsByFramework;
          if (this.rankingsByCompany.length == 0 || this.rankingsByFramework.length == 0){
            this.noData = true;
          }
        }
        this.displayDashboard = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
  },
  watch: {
    timePeriodId: function() {
      if (this.timePeriodId != "") {
        this.initialLoadData();
      }
    },
    inputCompetitorOptions: function() {
      this.competitor_options = JSON.parse(JSON.stringify(this.inputCompetitorOptions)); 
      this.initialCompetitorsLoaded = true;
    },
    inputGroupOptions: function() {
      this.setSurveyGroupList();
    }
  }
};
</script>
