<template>
  <div class="client-document">
    <div class="divider"></div>
    <div class="title">
      Documents
      <font-awesome-icon :icon="['fa', 'caret-down']" style="float: right; font-size: 30px;" size="2x"
        @click="toggleSection" />
    </div>
    <div v-show="displaySection" class="client-document-section">
      <div class="button-container">
        <div class="button-left">
          <button type="button" class="btn btn-success" id="UploadDocumentButton" @click="displayAddDocument">
            Upload Document
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'plus-circle']" />
            </span>
          </button>
          <button type="button" class="btn btn-primary ml-2" id="DownloadDocumentButton" :disabled="!deleteDocumentButtonEnabled"
            @click="downloadDocument">
            Download Document
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'download']" />
            </span>
          </button>
          <button type="button" class="btn btn-primary ml-2" id="DownloadDocumentButton" :disabled="!deleteDocumentButtonEnabled"
            @click="initiateDeleteDocument">
            Delete Document
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'minus-circle']" />
            </span>
          </button>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          </table>
        </div>
      </div>
    </div>
    <import-document ref="importDocumentRef" :client-code="clientCode" @reload="getTableData" />
  </div>
</template>

<script>
import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import ImportDocument from "../modals/ImportDocument.vue";
import AdminService from "../services/admin.service";
import AlertService from "../services/alert.service";
import ClientService from "../services/client.service";
import UserService from "../services/user.service";
import ClientDocumentService from "../services/client.document.service";

export default {
  name: "ClientDocument",
  components: { ImportDocument },
  mixins: [DataTableMixins, ContentMixins, ViewMixins],
  data() {
    return {
      table: {
        id: "client-document-table",
        columns: [],
        configuration: {
          responsive: false,
        },
      },
      displaySection: true,
      clientDocumentId: 0,
      currentSurveyGroupName: ""
    };
  },
  methods: {
    getTableData: async function () {
      await ClientDocumentService.getClientDocuments(this.clientCode, this.getTableDataCallback, this);
      if (this.$refs['import-document'] != null) {
        this.$refs['import-document'].visible = false;
      }
    },
    displayAddDocument: function () {
      this.$refs.importDocumentRef.initializeNotes();
      this.$modal.show("import-document");
    },
    initiateDeleteDocument() {
      AlertService.showConfirmationMessage(this.deleteDocument,'Delete Confirmation Document','Are you sure you want delete the document ?');      
    },
    deleteDocument: function () {
      this.clientDocumentId = this.getClientNoteId();
      this.$root.processServerRequest("Deleting document");

      ClientDocumentService.deleteClientDocument(this.clientDocumentId, this.deleteDocumentCallback, this);
    },
    deleteDocumentCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
        this.getTableDataCallback(response);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    downloadDocument: function () {
      this.clientDocumentId = this.getClientNoteId();
      this.downloadDocumentName = this.getDocument();
      this.downloadItem();
    },
    getDownloadFilename(){
      return this.downloadDocumentName;
    },    
    getClientNoteId() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.clientDocumentId;
      }
      return 0;
    },
    getDocument() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.document;
      }
      return 0;
    },
    getUserId(){
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.userId;
      }
      return 0;
    },    
    toggleSection: function () {
      this.displaySection = !this.displaySection;
    },
    getDownloadDocumentUrl: function (clientDocumentId) {
      return AdminService.getBaseUrl() + "/api/v1/client/download-document?clientDocumentId=" + clientDocumentId;
    },
    async downloadItem() {
      this.setupDownloadItem();
      await ClientDocumentService.downloadItem(this.clientDocumentId, this.downloadItemCallback, this);
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data]);
      this.clickLink(blob);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },

  },
  computed: {
    deleteDocumentButtonEnabled: function(){
      return this.table.rowSelected && this.getUserId() == UserService.getUserId();
    }    
  },
  props: {
    clientCode: {
      type: String,
      default: ClientService.getClientCode()
    },
  },
  created() {
    this.table.columns = ClientDocumentService.getClientDocumentTableColumnData();
    if (this.$parent.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[5, "desc"]];
    }
  },

};
</script>
