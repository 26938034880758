<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" :resizable="true" :scrollable="true" width="620" :maxHeight="1050" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-show="getDisplayView()" class="modal-body manage-exclusion-list">
        <div class="row chart-criteria-border-bottom">
          <div class="manual-input-section chart-criteria-border">
            <div class="title">List</div>
            <div :class="[this.isError ? 'p-2 text-danger mx-auto' : 'p-2 text-success mx-auto']">
              {{ returnMessage }}
            </div>
            <div class="form-row chart-criteria-border-bottom">
              <div class="form-group form-inline w-100">
                <label for="name" class="col-2">Word</label>
                <input v-model="newWord" id="newWord" name="newWord" type="text" class="form-control col-6 required" style="width: 100%" />
                <button type="button" class="btn btn-primary ml-3 col-3" @click="addToList">Add to List</button>
              </div>
            </div>
            <table :id="table.id" :class="table.class" style="display: none" cellspacing="0" width="100%">
              <thead>
                <tr>
                  <th style="text-align: center; width: 1rem">Word</th>
                  <th style="text-align: center; width: 1rem"></th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div class="import-file-section">
            <div class="title">Upload List</div>
            <div class="p-2 text-success mx-auto">
              {{ importFileReturnMessage }}
            </div>
            <div class="form-row align-items-end flex-column chart-criteria-border-bottom">
              <div class="form-group form-inline">
                <a href="/api/v1/word-cloud/download-exclusive-list-import-template" @click.prevent="downloadItem()" id="DownloadExclusiveListImportTemplate">
                  <button type="button" class="btn btn-primary">
                    <span class="align-text-bottom">Download Exclusive List Template <font-awesome-icon :icon="['fa', 'download']"/></span>
                  </button>
                </a>
              </div>
            </div>
            <div class="pt-2">
              Select a file to upload
              <input ref="exclusiveListUpload" type="file" @change="onFileChange" />
              <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">Import File</button>
            </div>
          </div>
        </div>
        <div v-show="isClient" class="row"> 
          <div class="pb-3">System Exclusive List (these word will automatically be excluded)</div>
          <div class="w-100">
            <a href="/api/v1/word-cloud/download-master-exclusive-list" @click.prevent="downloadMasterExclusiveList()" id="DownloadMasterExclusiveList">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Download System Exclusive List <font-awesome-icon :icon="['fa', 'download']"/></span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="saveList" :disabled="disableSaveList">Save List</button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import WordCloudService from "../services/word.cloud.service";
import AdminService from "../services/admin.service";
import ErrorService  from "../services/error.service";

import ViewMixins from "../mixins/ViewMixins.vue";


export default {
  name: "ManageExclusionList",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      clientExclusionList: [],
      masterExclusionList: [],
      newWord: "",
      returnMessage: "",
      isError: false,
      importFileReturnMessage: "",
      exclusiveListFile: "",
      disableSaveList: true,
      downloadMode: "template",
      table: {
        id: "exclusion-list-table",
        class: "table display wrap row-border table-hover",
        configuration: {
          dom: '<"top"if>rtp<"bottom"><"clear">',
          searching: false,
          paging: true,
          pageLength: 15,
          select: true,
          processing: false,
          responsive: false,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          sort: false,
          bSortable: false,
          bSort: false,
          buttons: [],
        },
        columns: WordCloudService.getManageExclusionTableAttributes(),
      },
      refreshModal: true
    };
  },
  props: {
    modalChartData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modalChartOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    modalId: {
      type: String,
      default: "manage-exclusion-list",
    },
    type: {
      type: String,
      default: "Client",
    },
    modalTitle: {
      type: String,
      default: "Manage Exclusive List",
    },
  },
  computed: {
    isMaster: function(){
      return this.type == "Master";
    },   
    isClient: function(){
      return this.type == "Client";
    }   
  },
  mounted() {},
  created() {},
  methods: {
    onlyNumbersAndLettersAndSpaces(str) {
      return /^[A-Z0-9a-z\s]*$/.test(str);
    },
    addToList() {
      if (!this.onlyNumbersAndLettersAndSpaces(this.newWord) || this.newWord.trim().length == 0){
        this.returnMessage = "'" + this.newWord + "'" + " can't be added to the " + this.type + " exclusion list. Must be numbers and letters only.";
        this.isError = true;
      } else {
        if (this.type == "Client") {
          this.clientExclusionList.push(this.newWord);
        } else {
          this.masterExclusionList.push(this.newWord);
        }
        this.disableSaveList = false;
        this.destroyTable();
        this.drawTable();
        this.returnMessage = this.newWord + " successfully added to the " + this.type + " exclusion list.";
        this.newWord = "";
        this.isError = false;
      }
    },
    deleteFromList(word){
      let wordToDelete = "";
      if (this.type == "Client") {
        wordToDelete = this.clientExclusionList.find(x => x == word);
        if (typeof wordToDelete != "undefined") {
          this.clientExclusionList.splice($.inArray(wordToDelete, this.clientExclusionList), 1);
        }        
      } else {
        wordToDelete = this.masterExclusionList.find(x => x == word);
        if (typeof wordToDelete != "undefined") {
          this.masterExclusionList.splice($.inArray(wordToDelete, this.masterExclusionList), 1);
        }        
      }
      this.disableSaveList = false;
      this.returnMessage = "Successfully deleted " + "'" + word + "'" + " from the " + this.type + " exclusion list.";
    },
    drawTable() {
      this.table.data = this.type == "Client" ? this.clientExclusionList : this.masterExclusionList;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, {
        data: $.map(this.table.data, function (d) {
          return [d];
        }),
      });
      let vm = this;
      $("#exclusion-list-table tbody").on("click", ".exclusion-list-delete", function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
            vm.deleteFromList(data);
        }
        vm.table.dataTable
        .row($row)
        .remove()
        .draw();

      });      
      this.table.configuration.order = [[0, "desc"]];
      setTimeout(() => {
        this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
        $("#" + this.table.id).show();
        this.setDisplayView(true);
      }, 0);
    },
    destroyTable() {
      $("#" + this.table.id).hide();
      if (typeof this.table.dataTable != "undefined") {
        this.table.dataTable.destroy();
      }
    },
    closeModal() {
      this.saveList();
      this.setDisplayView(false);
      if (this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },
    saveList(){
      let list = this.type == "Client" ? this.clientExclusionList : this.masterExclusionList;
      WordCloudService.saveExclusionList(list, this.type, this.getExclusionListCallback, this);
    },
    getExclusionListCallback(response) {
      this.importFileReturnMessage = "";
      this.returnMessage = "";
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.clientExclusionList = response.data.data.clientExclusionList;
        this.masterExclusionList = response.data.data.masterExclusionList;
        let mode = response.data.data.mode;
        if (mode == "import-list"){
          this.importFileReturnMessage = response.data.message;
        } else if (mode == "save-list"){
          this.returnMessage = response.data.message;
          this.disableSaveList = true;
        }
        this.destroyTable();
        this.drawTable();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async setupDownloadItem(setModalLoading = true) {
      this.$root.processServerRequest();
      if (setModalLoading == true){
        this.setModalLoading(true);
      }
      AdminService.displayInfoMessage({
        text: "Please stand by as we generate your file….Once complete, the file will appear in your Downloads folder.",
        duration: -1
      })
    },    
    async downloadItem() {
      this.downloadMode = "template";
      this.setupDownloadItem();
      await WordCloudService.getDownloadExclusiveListTemplate(this.downloadItemCallback, this);
    },
    async downloadMasterExclusiveList() {
      this.downloadMode = "master-list";
      this.$root.processServerRequest();
      this.setModalLoading(true);
      AdminService.displayInfoMessage({
        text: "Please stand by as we generate your file….Once complete, the file will appear in your Downloads folder.",
        duration: -1
      })
      await WordCloudService.getDownloadMasterExclusiveList(this.downloadItemCallback, this);
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.downloadMode == "master-list" ? this.getDownloadMasterExclusiveListFilename() : this.getDownloadFilename(); 
      link.download += ".xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },    
    async importFile() {
      if (this.exclusiveListFile){  
        await WordCloudService.importFile(this.exclusiveListFile, this.type, this.getExclusionListCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
      }
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.getTableDataCallback(response);
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    onFileChange: function() {
      this.exclusiveListFile = this.$refs.exclusiveListUpload.files[0];
    },
    getDownloadMasterExclusiveListFilename() {
      return "System Exclusive List";
    },
    getDownloadFilename() {
      return "Exclusive List Import Template";
    },
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DataTableMixins.downloadItem", error, "survey-designer", this);
      this.$gtag.exception("Error Downloading the file");
    },
    loadData() {
      WordCloudService.getExclusionList(this.getExclusionListCallback, this);
    },
  },
  watch: {},
};
</script>
