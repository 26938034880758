<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :scrollable="true" :adaptive="true"  @opened="loadData" :resizable="true" :width="modalWidth" :minHeight="700" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div :class="isError ? 'form-group form-row text-danger' : 'form-group form-row text-success'">
                {{ modalReturnMessage }}
            </div>
            <div style="min-height: 100px;">
                <div class="form-group form-row">
                    <span class="pr-2">Select a file to upload</span>
                    <input ref="importRespondentDataUpload" type="file" @change="onFileChange" />
                </div>
            </div>                
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="importFile" :disabled="disableImport">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import AdminService from "../services/admin.service";
import LocaleService from "../services/locale.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";

export default {
  name: "ImportLocaleFile",
  mixins: [ViewMixins,DataTableMixins],
  components: {},
  data() {
    return {
      modalReturnMessage: "",
      isError: false,
      disableImport: false,
      importRespondentDataImportFile: "",
      refreshModal: true
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onFileChange: function() {
      this.importRespondentDataImportFile = this.$refs.importRespondentDataUpload.files[0];
    },
    async loadData(){
        this.modalReturnMessage = "";
    },
    closeModal(){
      this.modalReturnMessage = "";
      if (this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
      this.$router.go(0);
    },    
    async importFile() {
      this.$root.processServerRequest("Importing respondent data");
      this.setModalLoading(true);
      LocaleService.importSurveyLocaleFile(this.importRespondentDataImportFile, 
                                                this.inputSurveyLocaleId, this.importFileCallback, this);
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },

  },
  watch: {},
  props: {
    inputSurveyLocaleId: {
      type: Number,
      default: 0,
    },
    modalId: {
      type: String,
      default: "import-locale-file",
    },
    modalTitle: {
      type: String,
      default: "Import Locale File",
    },
    modalWidth: {
      type: Number,
      default: 368,
    },
  }
};
</script>
