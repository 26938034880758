<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
    </div>
    <div class="response-summary container">
      <div class="d-flex">
        <div class="form-group form-inline">
          <label for="startDate" class="mr-1">Start Date</label>
          <datepicker format="MMM dd yyyy" v-model="startDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="startDate"></datepicker>
        </div>
        <div class="form-group form-inline ml-2">
          <label for="endDate" class="mr-1">End Date</label>
          <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
        </div>
      </div>
      <div class="row">
          <div class="form-group form-inline col-3">
              <label for="client" class="mr-1">Client</label>
              <select id="client" name="client" class="custom-select required" v-model="client_id" @change="filterSurveyOptions">
              <option
                  v-for="(client_option, idxClientOptions) in this.client_options"
                  v-bind:value="client_option.value"
                  v-bind:key="idxClientOptions"
              >
                  {{ client_option.name }}
              </option>
              </select>
          </div>
          <div class="form-group form-inline ml-2 col-4">
              <label for="survey" class="mr-1">Survey</label>
              <select id="survey" name="survey" class="custom-select required" v-model="survey_id">
              <option
                  v-for="(survey_option, idxSurveyOptions) in this.survey_options"
                  v-bind:value="survey_option.value"
                  v-bind:key="idxSurveyOptions"
              >
                  {{ survey_option.name }}
              </option>
              </select>
          </div>
      </div>

      <div class="d-flex">
        <div class="form-group form-inline" style="width: 367px">
          <label for="status" class="mr-2 mt-2">Status</label>
          <div class="w-75">
            <v-select id="status" name="status" :options="statusOptions" :reduce="statusOption => statusOption.status" label="description" :clearable="false" v-model="status">
            </v-select>
          </div>
        </div>
        <div class="pr-2">
          <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Filter</button>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary ml-2" id="GradeResponsesButton" @click="openManageResponses('Grade Responses','grade')">Grade Responses</button>
        <button type="button" class="btn btn-primary ml-2" id="GradeResponsesButton" @click="openManageResponses('Delete Responses','delete')">Delete Responses</button>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="70%">
        <thead></thead>
        <tbody></tbody>
      </table>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
      <survey-responses ref="surveyResponsesModal" :surveyTakerId="currentSurveyTakerId" :surveyId="currentSurveyId" />
      <manage-responses :modal-title="manageResponsesTitle" :input-mode="manageResponsesMode"/>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import RespondentMixins from "../../mixins/RespondentMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import Datepicker from "vuejs-datepicker";
import SurveyResponses from "../../modals/SurveyResponses.vue";
import ManageResponses from "../../modals/ManageResponses.vue";

import ResponseService from "../../services/response.service";
import ClientChartService from "../../services/client.chart.service";
import AdminService from "../../services/admin.service";
import ReportService from "../../services/report.service";
import SurveySummaryService from "../../services/survey.summary.service";

export default {
  name: "Responses",
  mixins: [RespondentMixins, DataTableMixins],
  components: { Datepicker, SurveyResponses,ManageResponses },
  props: {},
  data() {
    return {
      table: {
        id: "response-summary-data",
        columns: ResponseService.getResponseSummaryTableColumnData(),
        configuration: {
          responsive: false,
        },
      },
      pageNumber: 12,
      startDate: new Date(2014, 1, 1),
      endDate: new Date(),
      status: "A",
      statusOptions: [
        { status: "A", description: "All" },
        { status: "C", description: "Complete" },
        { status: "I", description: "Partial" }
      ],
      title: "Responses",
      currentSurveyId: 0,
      currentSurveyTakerId: "",
      manageResponsesTitle: "Grade Responses",
      manageResponsesMode: "grade",
      client_id: 0,
      client_options: [],
      survey_id: 0,
      survey_options: [],
      originalList: [],
    };
  },
  methods: {
    filterSurveyOptions(){
      this.survey_options = SurveySummaryService.buildSurveyOptionList(this.originalList, this.client_id);
    },    
    openManageResponses(modalTitle, mode){
      this.manageResponsesTitle = modalTitle;
      this.manageResponsesMode = mode;
      this.$modal.show("manage-responses");
    },
    async getResponseData() {
      this.$root.processServerRequest();            
      ResponseService.getResponseSummary(this.startDate, this.endDate, this.status, this.getResponseDataCallback, this);
    },
    getResponseDataCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.originalList = response.data.data;
        this.client_options = ClientChartService.buildClientOptionList(this.originalList);
        this.survey_options = SurveySummaryService.buildSurveyOptionList(this.originalList);
        this.getTableDataCallback(response);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.getTableDataCallback(response);
    },
    loadData: function() {
      this.getResponseData();
      this.table.configuration.order = [[5, "desc"]];
      this.table.configuration.dom = "frtpB";
    },
    displayResponsesModal(surveyId, surveyTakerId) {
      this.currentSurveyTakerId = surveyTakerId;
      this.currentSurveyId = surveyId;
    }
  },
  created() {},
  mounted() {
    let vm = this;
    $("#response-summary-data tbody").on("click", 'button[type="button"]', function(e, dt, type, indexes) {
      var $row = $(this).closest("tr");
      var data = vm.table.dataTable.row($row).data();
      if (typeof data != "undefined") {
        vm.displayResponsesModal(data.surveyId, data.surveyTakerId);
      }
    });
  },
  watch: {},
  computed: {}
};
</script>
