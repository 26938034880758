<template>
  <div></div>
</template>
<script>
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";
import GlobalMixins from "./GlobalMixins.vue";
import AdminService from "../services/admin.service";
import ContentService from "../services/content.service";
import AlertService from "../services/alert.service";

export default {
  name: "AdminMixins",
  mixins: [ContentMixins, ViewMixins, GlobalMixins],
  components: {},
  data() {
    return {
      modalReturnMessage: "",
      setupGradeThresholdsButton: "Setup Grade Thresholds",
      gradeResponsesButton: "Grade Survey",
      importDataButton: "Import Data",
      downloadTemplateButton: "Download Template",
      tooltipConfigShow: ContentService.getWizardTooltip(),           
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {},
  methods: {
    displayDeleteConfirmation(callback,parameter,title,content) {
      AlertService.showConfirmationMessageWithParameter(callback,parameter,title,content);            
    }
  },
  watch: {},
  mounted() {
    this.$root.setShowMenuIcon(false);
  },
  computed: {}
};
</script>
