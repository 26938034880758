<template>
  <vue-modal :key="refreshModal" :ref="modalName" :name="modalName" :scrollable="true" :width="500" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Rendering {{ clientChart.name }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="render-client-chart">
          <div class="text-center m-1 info-message">{{ returnMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-2" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import ReportService from "../services/report.service";
import SurveyService from "../services/survey.service";
import AdminService from "../services/admin.service";
import ChartService from "../services/chart.service";

export default {
  name: "RenderClientChart",
  components: {},
  mixins: [],
  methods: {
    renderClientChart: function(clientChart) {},
    getChartType(clientChart) {
      return ChartService.findChartTypeById(clientChart.chartTypeId);
    },
    generateClientChartLink(clientChart) {},
    generateClientCharts() {},
    closeModal() { 
      if (this.$refs[this.modalName] != null) {
        this.$refs[this.modalName].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },
  },
  props: {
    modalName: {
      type: String,
      default: "render-client"
    },
    clientChartsIncludedInReport: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      returnMessage: "",
      clientChart: {
        clientChartId: 0,
        name: "No Chart Available"
      },
      refreshModal: true
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {}
};
</script>
