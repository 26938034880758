<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container user-list">
      <div class="button-container">
        <div class="button-left">
          <div class="pr-2">
            <router-link :to="{ name: 'add-user' }">
              <button type="button" class="btn btn-success" id="AddNewUserButton">
                Add User
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'edit-user', params: { initialMode: 'edit-user', initialUserId: this.table.rowSelectedId, initialUserCode: this.getUserCode() } }">
              <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
                Edit User
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'duplicate-user', params: { initialUserId: this.table.rowSelectedId, initialMode: 'duplicate-user', initialUserCode: this.getUserCode() } }">
              <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
                Copy User
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'copy']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <a class="pr-2" href="#" @click="displayDeleteConfirmation(deleteUser,table.rowSelectedId,'Delete Confirmation User','Are you sure you want delete this user ?')">
              <button type="button" class="btn btn-primary" id="DeleteSurveyButton" :disabled="!table.rowSelected">
                Delete User
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="$modal.show('import-user-file')">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Users <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
          <a href="/api/v1/user/download-user-import-template" @click.prevent="downloadItem()" id="DownloadUserImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download User Import Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>User Id</th>
                <th>Email</th>
                <th>User Type Id</th>
                <th>User Type</th>
                <th>Client Id</th>
                <th>Client</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th># of Logins</th>
                <th>Display Agreement</th>
                <th>Okta User Id</th>
                <th>User Name</th>
                <th>Active</th>
                <th>Subscription End Date</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <vue-modal ref="import-user-file" name="import-user-file" :width="368" :height="400">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import User Data</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importUserUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </vue-modal>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";


import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";

export default {
  name: "UserList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importUserImportFile: "",
      title: "User List",
      table: {
        id: "user-list-table",
        columns: UserService.getUserTableColumnData()
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Retrieving users");
      UserService.getUserList(this.getTableDataCallback, this);
    },
    getUserCode() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.userCode;
      }
      return "";
    },
    deleteUser() {
      this.$root.processServerRequest("Deleting user");
      UserService.deleteUser(this.getUserCode(), this.deleteUserCallback, this);
    },
    deleteUserCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableData();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getDownloadFilename() {
      return "User Import Template";
    },
    onFileChange: function() {
      this.importUserImportFile = this.$refs.importUserUpload.files[0];
    },
    async importFile() {
      this.$root.processServerRequest();
      this.setModalLoading(true);
      this.modalReturnMessage = "Importing user data please stand by.....";

      let formData = new FormData();
      formData.append("file", this.importUserImportFile);

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/user/import-users", formData, { headers: headers })
        .then(response => this.importFileCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("UserList.importFile", error, "admin", this);
        });
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    closeModal: function() {
      if (this.$refs['import-user-file'] != null) {
        this.$refs['import-user-file'].visible = false;
      }
      this.$router.go(0);
            
    }
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/user/download-user-import-template";
    }
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {}
};
</script>
