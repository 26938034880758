<template>
  <div v-show="displayImportLogo" class="company-logo">
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between py-1 px-3">
        <ul class="nav">
          <li class="nav-item">
            <a id="ImportWithBLOB-btn-id" class="nav-link" href="javascript:void(0);" @click="pickFile"> <i class="fa fa-upload fa-fw fa-lg"></i>Import Logo </a>
          </li>
          <li v-show="hasImage" class="nav-item">
            <a id="UpdateProfileImage" class="nav-link" href="javascript:void(0);" @click="saveLogo">
              <font-awesome-icon :icon="['fa', 'save']" size="lg" />
              Save Logo
            </a>
          </li>
          <li v-show="hasSavedLogo" class="nav-item">
            <a id="DeleteWithBLOB-btn-id" class="nav-link" href="javascript:void(0);" @click="initiateDeleteLogo"> <i class="fa fa-trash fa-fw fa-lg"></i>Delete Logo </a>
          </li>
        </ul>
        <div class="options float-right">
          <a href="#" class="collapse"><i class="fa fa-chevron-up"></i></a>
          <a href="#" class="remove"><i class="fa fa-times"></i></a>
        </div>
      </div>
      <div class="card-body">
        <form id="updateFormId" role="form" method="POST" enctype="multipart/form-data">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="img-container d-inline-block">
                <img :src="logoSourceUrl" alt="" />
              </div>
            </div>
            <!-- <span class="help-block"> Select or press above button to open image browsers. </span> -->
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="img-container d-inline-block">
                <image-uploader
                  :preview="false"
                  ref="pictureInput"
                  :className="['pt-3 fileinput d-none', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="verbose"
                  @input="setImage"
                >
                </image-uploader>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import Vue from "vue";
import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);
import $ from "jquery";

import ContentMixins from "../../mixins/ContentMixins.vue";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";
import AlertService from "../../services/alert.service";

export default {
  name: "CompanyLogo",
  mixins: [ ContentMixins],
  components: {
    ImageUploader
  },
  props: {
    initialLogoId: {
      type: Number,
      default: 0
    },
    initialMode: {
      type: String,
      default: ""
    }    
  },
  data() {
    return {
      pageNumber: 2,
      title: "Logo",
      hasImage: false,
      image: null,
      logoSourceUrl: null,
      documentObject: this.getNewDocumentObject()
    };
  },
  methods: {
    getNewDocumentObject(){
      return {
        documentId: 0,
        documentName: "",
        contentType: "",
        documentType: "1",
        documentSize: 0,
        randomHash: 0,
        content: ""
      };
    },
    initiateDeleteLogo() {
      let title="Delete Confirmation"
      let message = "Are you sure you want to delete the logo?";
      AlertService.showConfirmationMessage(this.deleteLogo, title, message);      
    },    
    pickFile() {
      $("#fileInput").click();
    },
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
      this.documentObject = this.getNewDocumentObject();
      this.logoSourceUrl = this.image.dataUrl;
      this.documentObject.contentType = this.image.info.type;
      this.documentObject.randomHash = this.image.info.modifiedTimestamp;
      this.documentObject.documentName = this.image.info.name;
      this.$parent.disableButton = true;
    },
    loadData: function() {},
    async saveLogo() {
      this.$root.processServerRequest("Saving logo");
      UserService.saveLogo(this.documentObject, this.$refs.pictureInput.currentFile, this.saveLogoCallback, this);
    },
    async deleteLogo() {
      this.$root.processServerRequest("Deleting logo");
      UserService.deleteLogo(this.deleteLogoCallback, this);
    },
    deleteLogoCallback(response) {
      this.$root.processServerResponse();    
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
        this.$parent.disableButton = false;
        this.documentObject = response.data.data;
        this.logoSourceUrl = AdminService.getBaseUrl() + "/api/v1/content/get-logo-by-user-id?viewType=inline&userId=" + UserService.getUserId() + "&r=" + Math.random();
        this.$parent.$parent.$parent.logoImage = this.logoSourceUrl;
        let saveUser = UserService.getUser();
        saveUser.logoId = this.documentObject.documentId;
        this.$parent.formUserObject.logoId = this.documentObject.documentId;
        UserService.saveUserToLocalStorage(saveUser);        
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.hasImage = false;
    },
    saveLogoCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$parent.disableButton = false;
        this.documentObject = response.data.data;
        this.$parent.$parent.$parent.logoImage = this.logoSourceUrl;
        let saveUser = UserService.getUser();
        saveUser.logoId = this.documentObject.documentId;
        this.$parent.formUserObject.logoId = this.documentObject.documentId;
        UserService.saveUserToLocalStorage(saveUser);        
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.hasImage = false;
    },
    getLogoDocumentCallback(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.documentObject = response.data.data;
        this.displayDeleteLogoButton = true;
      } 
    }
  },
  created() {},
  mounted() {
    this.logoSourceUrl = AdminService.getBaseUrl() + "/api/v1/content/get-logo-by-user-id?viewType=inline&userId=" + UserService.getUserId() + "&r=" + Math.random();
    if (this.initialLogoId != 0){
      UserService.getLogoDocument(this.initialLogoId, this.getLogoDocumentCallback, this);
    }
  },
  computed: {
    hasSavedLogo: function() {
      return this.documentObject.documentName != "default-logo-square.png" && this.documentObject.documentName != "";
    },
    displayImportLogo: function() {
      //return !this.$parent.isInsiderUser;
      return true;
    }
  }
};
</script>
