<template>
    <div class="col-10 no-gutters container mt-3">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import PredictiveAnalyticsService from "../../services/echarts/predictive.analytics.service";
import TableService from "../../services/table.service";

import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "PredictiveAnalyticsChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        chartTitle: "",
        metricDisplayTypeName: "Currency",
        metricDisplayTypeNameForSecondary: "Currency",
        useMetricGrowth: false,
        useMetricGrowthForSecondary: false,
        addWrapper: false,
        correlation: 0.62,
        correlationClass: "",
        correlationMessage: "",
        min: 0,
        max: 10,
        minSecondary: 0.01,
        maxSecondary: 10,
    };
  },
  props: {},
  computed: {
    correlationColor: function(){
        if (this.correlationClass == "very-strong-correlation"){
            return  "#548235";
        } else if (this.correlationClass == "strong-correlation"){
            return  "#70AD47";
        } else if (this.correlationClass == "mild-correlation"){
            return  "#A9D18E";
        } 
        return  "#000";
    },
    legendName1: function(){
        return this.legend.normal.length > 0 && typeof this.legend.normal[0].name != "undefined" ? this.legend.normal[0].name : "Primary";
    },
    legendName2: function(){
        let legendName2 = this.legend.normal.length > 1 && typeof this.legend.normal[1].name != "undefined" ? this.legend.normal[1].name : "Secondary";
        return legendName2;
    }
  },
  mounted() {},
  created() {},
  methods: {
    //https://codepen.io/plainheart/pen/bGgmGPj    
    setDataForPredictiveAnalytics: function(chartData,chart1,chart2 = {},diplayType){
        this.setDataForBaseChart(chartData,chart1,chart2,diplayType);
        let chart1Data = {};
        let chart2Data = {};        
        if (typeof chartData != "undefined" && !$.isEmptyObject(chartData)) {
            if (typeof chartData.chart1 != "undefined" && chartData.chart1.data.length > 0){
                chart1Data = JSON.parse(JSON.stringify(chartData.chart1));
                if (typeof chartData.chart1.containerId != "undefined"){
                    chart1.containerId = chartData.chart1.containerId;
                }
            }                
            if (typeof chartData.chart2 != "undefined" && chartData.chart2.data.length > 0){
                chart2Data = JSON.parse(JSON.stringify(chartData.chart2));
                if (typeof chartData.chart2.containerId != "undefined"){
                    chart2.containerId = chartData.chart2.containerId;
                }
            }           
        }        
        chart1.series.push(PredictiveAnalyticsService.getSeriesForPredictiveAnalyticsChart(this.legendName1,0,0,0,this.useMetricGrowth,this.metricDisplayTypeName,"rec",this.eChartCriteria));
        chart1.series.push(PredictiveAnalyticsService.getSeriesForPredictiveAnalyticsChart(this.legendName2,0,0,1,this.useMetricGrowthForSecondary,this.metricDisplayTypeNameForSecondary,null,this.eChartCriteria));
        chart1 = this.setChart(chart1Data, chart1);
        this.charts.push(chart1);
        if (!$.isEmptyObject(chart2Data)){
            chart2 = this.setChart(chart2Data, chart2);
            this.charts.push(chart2);
        }
    },
    setChart: function(chartData, chart){
        chart = this.clearChart(chart);
        let xAxisData = [];
        let metricData = chartData.data[0]; 
        for (let dataIndex = 0; dataIndex < metricData.length; dataIndex++) {
            let metricRow = metricData[dataIndex].data;
            let axisName = metricData[dataIndex].name;
            for (let rowIndex = 0; rowIndex < metricRow.length; rowIndex++) {
                let metricProperties = metricRow[rowIndex];
                if (typeof metricProperties.displayTypeName != 'undefined' && metricProperties.displayTypeName != null ) {
                    let wave = metricProperties.wave;
                    if (axisName == "primary"){
                        chart.series[0].data.push(PredictiveAnalyticsService.getData(metricProperties, this.legendName1, this.useMetricGrowth,this.metricDisplayTypeName));
                        xAxisData.push(wave);
                    } else if (axisName == "secondary"){
                        chart.series[1].data.push(PredictiveAnalyticsService.getData(metricProperties, this.legendName2, this.useMetricGrowthForSecondary,this.metricDisplayTypeNameForSecondary));
                    }
                }
            }
        }
        chart.xAxis.data = JSON.parse(JSON.stringify(xAxisData));
        let dataValues = chart.series[0].data.filter(entry=> !isNaN(entry.value)).map(entry => entry.value);
        let values = PredictiveAnalyticsService.findMinMax(dataValues,this.useMetricGrowth);
        this.min = values.min;
        this.max = values.max;
        dataValues = chart.series[1].data.filter(entry=> !isNaN(entry.value)).map(entry => entry.value);
        let valuesSecondary = PredictiveAnalyticsService.findMinMax(dataValues,this.useMetricGrowthForSecondary);
        this.minSecondary = valuesSecondary.min;
        this.maxSecondary = valuesSecondary.max;
        return chart;
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let showLegend = true;
        let showLabel = this.eChartCriteria.showLabels;
        let angleText = true;
        let isMultipleWaves = true;
/* Start of function that should go into helper service */
        let xAxis = [];
        let seriesData = [];
        for (let i = 0; i < this.charts.length; i++) {
            let chart = this.charts[i];
            for (let i = 0; i < chart.series.length; i++) {
                let series = chart.series[i];
                seriesData.push(series);
            }
            $.extend(true, chart.xAxis.axisLabel, PredictiveAnalyticsService.getDefaultAxisTextStyleForAnalyticChart());
            if (angleText == true){           
                $.extend(true, chart.xAxis.axisLabel, {rotate: 25,hideOverlap: true,interval: 0});
            } else {
                let defaultAxisLabel = {
                    overflow: "break",
                    interval: 0,
                    width: 40,
                    ellipsis: '...',        
                    rotate: 0,
                    hideOverlap: false,
                };
                $.extend(true, chart.xAxis.axisLabel, defaultAxisLabel);
            }
            let breakPointList = [];
            let numberOfWaves = chart.xAxis.data.length;
            if (numberOfWaves > 4){
                let numberOfBreakPoints = Math.floor(numberOfWaves / 4);
                for (let i = 1; i <= numberOfBreakPoints; i++) {
                    breakPointList.push(i * 4);
                }
                let splitLine = {
                    show: true,
                    interval: function(index, value) {
                        return breakPointList.includes(index);              
                    },             
                };
                $.extend(true, chart.xAxis.splitLine, splitLine);
            }
            xAxis.push(chart.xAxis);
            xAxis.push(chart.header);
        }
/* End of function that should go into helper service */
        ScatterService.setShowSnap(this.isSavedCard);
        ScatterService.setShowToolbox(showToolbox);
        ScatterService.setShowLabel(showLabel);
        ScatterService.setShowLegend(showLegend);
        ScatterService.setIsMultiWave(isMultipleWaves);
        ScatterService.setChartType(this.eChartCriteria.eChartType);
        let legendData = this.legend.normal.map(entry => entry.name);
        ScatterService.setLegendData(legendData);
        ScatterService.setSeriesData(seriesData);
        let yAxis = [];
        let primaryAxis = PredictiveAnalyticsService.getYAxisForNeed(this.min, this.max);
        let secondaryAxis = PredictiveAnalyticsService.getYAxisForNeed(this.minSecondary, this.maxSecondary);
        secondaryAxis.splitLine.show = false;
        primaryAxis = PredictiveAnalyticsService.setDataLabels(primaryAxis,this.useMetricGrowth,this.metricDisplayTypeName);
        secondaryAxis = PredictiveAnalyticsService.setDataLabels(secondaryAxis,this.useMetricGrowthForSecondary,this.metricDisplayTypeNameForSecondary);
        yAxis.push(primaryAxis);
        yAxis.push(secondaryAxis);
        
        ScatterService.setYAxis(yAxis);
        ScatterService.setXAxis(xAxis);
        options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName);
        if (!$.isEmptyObject(options)) { 
            options.tooltip.backgroundColor = "#ffffff";
            options.tooltip.formatter =  PredictiveAnalyticsService.toolTipForPredictiveAnalyticsChart;
            options.color = this.colors;
            options.legend.left = 100;
            options.grid[0].top = 70;
            $.extend(true, options.toolbox, PredictiveAnalyticsService.getToolboxForAnalyticsChart());
			let titleConfiguration = {
					text: "",
                    top: 10,
					left: 'center',
                    textStyle: {
                        fontFamily: "Franklin Gothic Demi Cond",
                        color: "#2F5EB3"
                    },
                    subtext: 'r = ' + this.correlation + ' ' + this.correlationMessage,
                    subtextStyle:{
                        color: this.correlationColor,
                        fontFamily: "Franklin Gothic Demi Cond",
                        fontSize: 15
                    }
			};             
            options.title = titleConfiguration;
        }
        return options;           
    },
    processChart: function(runChart = false,displayType,chartData,eChartCriteria){
        if (runChart == true) {
            this.displayType = displayType;
            if (typeof eChartCriteria != "undefined") {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            }
            if (typeof chartData != "undefined"){
                if (typeof chartData.metricDisplayTypeName != "undefined") {
                    this.metricDisplayTypeName = JSON.parse(JSON.stringify(chartData.metricDisplayTypeName));
                }            
                if (typeof chartData.useMetricGrowth != "undefined") {
                    this.useMetricGrowth = JSON.parse(JSON.stringify(chartData.useMetricGrowth));
                    if (this.useMetricGrowth == true) {
                        this.metricDisplayTypeName = "Percentage";
                    }
                }            
                if (typeof chartData.metricDisplayTypeNameForSecondary != "undefined") {
                    this.metricDisplayTypeNameForSecondary = JSON.parse(JSON.stringify(chartData.metricDisplayTypeNameForSecondary));
                }            
                if (typeof chartData.correlation != "undefined") {
                    this.correlation = Number(JSON.parse(JSON.stringify(chartData.correlation))).toFixed(2);
                }            
                if (typeof chartData.correlationClass != "undefined") {
                    this.correlationClass = JSON.parse(JSON.stringify(chartData.correlationClass));
                }
                if (typeof chartData.correlationMessage != "undefined") {
                    this.correlationMessage = JSON.parse(JSON.stringify(chartData.correlationMessage));
                }
                if (typeof chartData.useMetricGrowthForSecondary != "undefined") {
                    this.useMetricGrowthForSecondary = JSON.parse(JSON.stringify(chartData.useMetricGrowthForSecondary));
                    if (this.useMetricGrowthForSecondary == true) {
                        this.metricDisplayTypeNameForSecondary = "Percentage";
                    }
                }            
                if (typeof chartData.chartTitle != "undefined") {
                    this.chartTitle = JSON.parse(JSON.stringify(chartData.chartTitle));
                }            
            }
            // get the splits for charts
            let breakPointList = [2,8];
            if (displayType == "predictive-analytics") {
                let chart1 = {
                    containerId: "",
                    header: [],
                    series: [],
                    xAxis: PredictiveAnalyticsService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(PredictiveAnalyticsService.getGridForSingleChart());
                this.setDataForPredictiveAnalytics(chartData,chart1,{},displayType);
                this.initChart();
            } 
            if (typeof chartData != "undefined" && typeof eChartCriteria != "undefined" && this.charts.length > 0) {
                this.$emit('save-criteria',true);
                this.$emit('save-table');
            }
        }
    },
  },
  watch: {},
};
</script>
