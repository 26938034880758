<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="loadData" :resizable="true" :scrollable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        <dashboard-moat-comps v-if="saveClientChartObject.displayType == 'dashboard-moat-comps'"  
          ref="moatComps" dashboard-id="moatComps" 
          :load-competitor-data="true"
          :input-group-options="surveyGroupList"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject" 
          :read-only="true"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-comps',false)"
        />
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import DashboardMoatComps from "../components/dashboard/DashboardMoatComps.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"

import AdminService from "../services/admin.service";
import SurveyGroupService from "../services/survey.group.service";
import SurveyService from "../services/survey.service";

export default {
  name: "DashboardMoatCompsModal",
  mixins: [DashboardCardMixins],
  components: {DashboardMoatComps},
  props: {},
  data() {
    return {
      title: "Moat Comps",
      surveyGroupList: []
    };
  },
  methods: {
    loadData: async function(){
      if (this.triggerLoadData == true){
        await this.setupDashboardCard();
        let clientCode = this.getCriteriaParameterForDashboardCard(this.saveClientChartObject,'clientCode');
        this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
        await SurveyGroupService.getSurveyGroupsByClientId(clientCode,this.getSurveyGroupsByClientIdCallback, this);
      }
    },
    getSurveyGroupsByClientIdCallback(response) {
      this.surveyGroupList = [];
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let surveyGroups = response.data.data;
        this.surveyGroupList =  SurveyGroupService.getSurveyGroupOptions(surveyGroups,"Y",this.saveClientChartObject.clientId);
        this.surveyGroupList = this.surveyGroupList.filter(x => x.name != "W-30 Index");
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },      
  },
  watch: {},
  created() {},
  mounted() {},
  umnounted() {},
  computed: {
    triggerLoadData: function() {
      return this.saveClientChartObject != null && this.saveClientChartObject.displayType == "dashboard-moat-comps";
    }    
  }
};
</script>
