<template>
    <vue-modal ref="import-document" name="import-document" :width="600" :height="450">
        <form id="ImportDocumentFormId" name="form" @submit.prevent="importFile">
            <div class="container">
                <div class="form-group form-row p-2">
                    <h5 class="font-weight-bold">Upload Document</h5>
                </div>
                <div class="form-group form-row p-2 text-success">
                    <span :class="modalReturnMessageClass"> {{ modalReturnMessage }}</span>
                </div>
                <div class="form-group form-row p-1">
                    <span class="pr-2">Select a file to upload</span>
                    <input ref="importSurveyGroupUpload" type="file" @change="onFileChange" />
                </div>
                <div class="form-row p-1">
                    <div class="form-group form-inline w-100">
                        <label for="documentNote" class="mr-1">Note</label>
                        <textarea id="documentNote" name="documentNote" class="required form-control w-100"> </textarea>
                    </div>
                </div>
                <div class="form-group form-row p-1 d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary float-right ml-2">
                        <span>Upload Document</span>
                        <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
                    </button>
                    <button type="button" class="btn btn-primary ml-4" @click="closeModal">
                        Close
                    </button>
                </div>
            </div>
        </form>
    </vue-modal>
</template>

<script>
import $ from "jquery";

import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import AdminService from "../services/admin.service";
import FormService from "../services/form.service";
import ContentService from "../services/content.service";
import ClientDocumentService from "../services/client.document.service";
import "summernote/dist/summernote.css";
import "bootstrap";
import "summernote";

export default {
    name: "ImportDocumentButton",
    components: {},
    mixins: [ContentMixins, ViewMixins],

    methods: {
        getDownloadFilename() {
            return "Import Document Template";
        },
        onFileChange: function () {
            this.importDocumentImportFile = this.$refs.importSurveyGroupUpload.files[0];
        },
        async importFile() {
            this.setupImportDocumentForm();
            if (!this.importDocumentForm.valid()) {
                return false;
            }
            if (this.importDocumentImportFile) {
                let documentNote = $("#documentNote").summernote("code");
                this.setModalLoading(true);
                this.$root.processServerRequest("Importing document");
                ClientDocumentService.importFile(this.importDocumentImportFile, documentNote, this.clientCode, this.importFileCallback, this)
            } else {
                AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
            }
        },
        importFileCallback: function (response) {
            this.$root.processServerResponse();
            this.setModalLoading(false);
            let statusCode = response.data.statusCode;
            this.modalReturnMessage = response.data.message;
            if (statusCode == 200) {
                $("#documentNote").summernote("code", "");
                this.modalReturnMessageClass = "text-success";
                this.$emit("reload");
                AdminService.displaySuccessMessage({ text: response.data.message });
            } else {
                this.modalReturnMessageClass = "text-danger";
                AdminService.displayErrorMessage({ text: response.data.message });
            }
        },
        setupImportDocumentForm() {
            this.modalReturnMessage = "";
            this.importDocumentForm = $("#ImportDocumentFormId");
            FormService.setupForm(this.importDocumentForm);
            this.validator = this.importDocumentForm.validate(FormService.getBaseValidator());
        },
        initializeNotes: function () {
            let vm = this;
            $(document).ready(function () {
                let configuration = ContentService.getContentConfiguration();
                let altConfiguration = { height: 150 };
                $.extend(true, configuration, altConfiguration);
                $("#documentNote").summernote(configuration);
                $("#documentNote").summernote("code", "");
            });
        },
        closeModal() {
            if (this.$refs['import-document'] != null) {
                this.$refs['import-document'].visible = false;
            }
        }
    },
    props: {
        rowSelected: {
            type: Boolean,
            default: false,
        },
        clientCode: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            modalReturnMessageClass: "text-success",
            modalReturnMessage: "",
            mode: "new",
            validator: null,
            importDocumentForm: null,
        };
    },
    computed: {},
    created() { },
    mounted() {
    },
    watch: {
    },
};
</script>
