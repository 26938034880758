<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :scrollable="true" :adaptive="true"  @opened="loadData" :resizable="true" :width="modalWidth" :minHeight="700" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div :class="isError ? 'form-group form-row text-danger' : 'form-group form-row text-success'">
                {{ modalReturnMessage }}
            </div>
            <div class="form-group form-row text-danger">
                {{ errorMessage }}
            </div>
            <div style="min-height: 100px;">
                <div class="form-group form-row">
                    <span class="pr-2">Select a file to upload</span>
                    <input ref="importWaveDataUpload" type="file" @change="onFileChange" />
                </div>
                <div class="form-group form-inline">
                  <label class="custom-checkout mr-2" :for="'include-cover-page'">Include all Descendent </label>
                  <input id="includeHierarchy" name="includeHierarchy" type="checkbox" v-model="includeHierarchy"/>
                </div>
            </div>                
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="importFile" :disabled="disableImport">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import AdminService from "../services/admin.service";
import ClientTimePeriodService from "../services/client.time.period.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";

export default {
  name: "ImportWaveFile",
  mixins: [ViewMixins,DataTableMixins],
  components: {},
  data() {
    return {
      modalReturnMessage: "",
      errorMessage: "",
      isError: false,
      disableImport: false,
      includeHierarchy: true,
      importRespondentDataImportFile: "",
      refreshModal: true 
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onFileChange: function() {
      this.importRespondentDataImportFile = this.$refs.importWaveDataUpload.files[0];
    },
    async loadData(){
        this.modalReturnMessage = "";
    },
    closeModal(){
      this.modalReturnMessage = "";
      if (this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
      this.$router.go(0);
    },    
    async importFile() {
      this.$root.processServerRequest("Importing wave data");
      this.setModalLoading(true);
      ClientTimePeriodService.importWaveFile(this.importRespondentDataImportFile, 
                                                this.includeHierarchy, this.importFileCallback, this);
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      let returnData = response.data.data;
      this.isError = !returnData.success;
      this.modalReturnMessage = response.data.message;
      this.errorMessage = returnData.errorMessage;
      if (statusCode == 200 ) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },

  },
  watch: {},
  props: {
    modalId: {
      type: String,
      default: "import-wave-file",
    },
    modalTitle: {
      type: String,
      default: "Import Wave File",
    },
    modalWidth: {
      type: Number,
      default: 368,
    },
  }
};
</script>
