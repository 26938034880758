<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>
      {{ chart.title }}
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">
          Click the Profile Type and Time 
          Period for the analytics you’d like 
          to generate. Click the box to 
          include partial responses, which  
          are respondents that did not 
          complete all the survey fields.
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
          Click up to 5 competitors to include in your Profiles. Click 
          the Benchmark Companies to compare your data to any of our 
          well-recognized companies. Click Competitor Expectations 
          to show how the ideal of competitor customers compares to 
          your customer’s ideal. Click Pricing Power to show how 
          much more each customer would pay if your performance 
          could improve to meet/exceed their ideal.
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Segments are created through your survey 
            questions. Click any segment criteria to select 
            which respondents to include in your Profile. 
            Selecting no criteria includes all respondents for 
            that question. Segments for NPS, Location and 
            Word are included as defaults.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>  
          <div class="wizard-text">
            Click Generate Chart to build your Profile. Click 
            Reset to start the build process from scratch.
          </div>      
        </div>
      </div>
    </div>
    <div class="chart-app">
      <chart-criteria
        ref="chartCriteria"
        :include-show-competitor-expectations="true"
        :include-show-pricing-power="true"
        :use-chart-type-card="true"
        display-type-label="Profile Types"
        :show-display-type="true"
        :is-comparative="false"
        :content-list="pageContent"
        @reset-client-chart="resetClientChart"
      ></chart-criteria>
      <div class="row no-gutters">
        <div class="col">
          <div id="chartDislayCard">
            <div id="performance_dashboard">
              <div id="chart-filters" class="row">
                <chart-criteria-results
                  v-show="!generatingChart"
                  ref="chartCriteriaResults"
                  v-bind:criteria-results-list="criteriaResultsList"
                  v-bind:criteria-results-company-list="criteriaResultsCompanyList"
                ></chart-criteria-results>
              </div>
              <div v-show="getDisplayView() && !generatingChart" class="row mt-3 mb-3">
                <div id="buttonContainer" class="col-lg-12">
                  <button id="save_chart" class="btn btn-primary ml-2" type="button" @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as Card</button>
                  <div v-show="isByValue || isByMoat || isByNeed" class="chart-button-divider">
                    <button id="view_value_map_button" class="btn btn-primary" type="button" v-on:click="showMap">{{ mapButtonName }}</button>
                  </div>
                </div>
              </div>
              <chart-display-skeleton v-show="generatingChart"/>
              <ByNeed v-show="isByNeed && !generatingChart" :chart-data="chartData" :content-list="pageContent"></ByNeed>
              <ByMoat v-show="isByMoat && !generatingChart" :chart-data="chartData" :content-list="pageContent"></ByMoat>
              <ByValue v-show="isByValue && !generatingChart" :chart-data="chartData" :content-list="pageContent"></ByValue>
              <ByWinners v-show="isByWinners && !generatingChart" :chart-data="chartData" :content-list="pageContent"></ByWinners>
              <ByWordCloud v-show="isByWordCloud && !generatingChart" :chart-data="chartData" :content-list="pageContent" 
              :input-e-chart-criteria="eChartCriteria" 
              >
              </ByWordCloud>

            
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-modal ref="value-map" name="value-map" :clickToClose="false" :draggable="true" :adaptive="true" :resizable="true" width="80%" :maxHeight="1550" :height="valueMapHeight">
      <value-map :map-type="mapType" :value-map-data="valueMapData" @closeModal="closeModal('value-map')"></value-map>
    </vue-modal>
    <vue-modal ref="save-chart-confirmation" name="save-chart-confirmation" :width="350" :height="200">
      <save-chart button-label="Save as Card" :chart-title="this.chart.title" :initial-client-chart="this.clientChart" @save="updateClientCharts" @closeModal="closeModal('save-chart-confirmation')"></save-chart>
    </vue-modal>
    <vue-modal ref="print-chart" name="print-chart" :width="350" :height="200">
      <chart-criteria-results
        ref="chartCriteriaResults"
        v-bind:criteria-results-list="criteriaResultsList"
        v-bind:criteria-results-company-list="criteriaResultsCompanyList"
      ></chart-criteria-results>
    </vue-modal>
  </div>
</template>
<script>
import ChartDisplaySkeleton from "../../components/skeleton/ChartDisplaySkeleton.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import SaveChart from "../../components/chart/SaveChart.vue";
import ValueMap from "../../components/chart/ValueMap.vue";
import ByNeed from "./type/ByNeed.vue";
import ByMoat from "./type/ByMoat.vue";
import ByWinners from "./type/ByWinners.vue";
import ByWordCloud  from "./type/ByWordCloud.vue";
import ByValue from "./type/ByValue.vue";
import ChartService from "../../services/chart.service";
import ErrorService from "../../services/error.service";
import GoogleService from "../../services/google.service";

export default {
  name: "CompetitiveProfile",
  mixins: [ChartCompanyMixins],
  components: {
    SaveChart,
    ByNeed,
    ByMoat,
    ByWinners,
    ByWordCloud,
    ByValue,
    ValueMap,
    ChartDisplaySkeleton
  },
  data() {
    return {
      title: "Profiles",
      pageNumber: 2,
      chartData: [],
      isByMoat: false,
      isByWinners: false,
      isByWordCloud: false,
      isByNeed: true,
      isByValue: false,
      valueMapData: {
        title: "",
        data: []
      },
      valueMapHeight: 450,
      chartWidth: "",
      
      
      
    };
  },
  methods: {
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    showMap(){
      this.$modal.show('value-map');
      GoogleService.logClickEvent(this.mapButtonName, "", 0, this);
    },
    displayChartCallback(response) {
      try {
        this.$root.processServerResponse();        
        this.generatingChart = false;
        this.chartData = response.data;
        this.isByMoat = this.$refs.chartCriteria.$data.displayType == "moat";
        this.isByNeed = this.$refs.chartCriteria.$data.displayType == "need";
        this.isByValue = this.$refs.chartCriteria.$data.displayType == "value";
        this.isByWinners = this.$refs.chartCriteria.$data.displayType == "winners";
        this.isByWordCloud = this.$refs.chartCriteria.$data.displayType == "nps";
        if (this.isByMoat) {
          this.valueMapHeight = 420;
        }
      } catch (err) {
        ErrorService.processException("CompetitiveProfile.displayChartCallback", err, "chart", this);
      }
    }
  },
  umnounted() {},
  computed: {
    showPricingPower: function() {
      return this.$refs.chartCriteria.$data.showPricingPower == "Y";
    },
    mapButtonName: function() {
      if (this.isByValue) {
        return "View Value Map";
      } else if (this.isByMoat) {
        return "View Moat Map";
      } else if (this.isByNeed) {
        return "View Needs Map";
      }
      return "";
    },
    mapType: function() {
      if (this.isByValue) {
        return "value";
      } else if (this.isByMoat) {
        return "moat";
      } else if (this.isByNeed) {
        return "need";
      }
      return "";
    }
  },
  props: {},
  mounted() {},
  created() {
    this.chart = ChartService.getChartAttributes("Profile");
  },
  watch: {}
};
</script>
