<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="loadData" :resizable="true" :scrollable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        <dashboard-rank ref="wScoreRank" 
          dashboard-id="wScoreRank"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'w-score-rank',false)"
          :load-content="false"
          rank-type="w-score-rank"
          :entry-client-chart="saveClientChartObject" :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-w-score-rank'"  
        />  

        <dashboard-rank ref="npsRank" 
          dashboard-id="npsRank"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-rank',false)"
          :load-content="false"
          rank-type="nps-rank"
          :entry-client-chart="saveClientChartObject" :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-nps-rank'"  
        />  

        <dashboard-rank ref="moatRank" 
          dashboard-id="moatRank"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-score-rank',false)"
          :load-content="false"
          rank-type="moat-score-rank"
          :entry-client-chart="saveClientChartObject" :read-only="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :input-group-options="surveyGroupList"
          :entry-survey-code="entrySurveyCode" 
          v-if="saveClientChartObject.displayType == 'dashboard-moat-score-rank'"  
        />  
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import AdminService from "../services/admin.service";
import SurveyGroupService from "../services/survey.group.service";
import SurveyService from "../services/survey.service";

import DashboardRank from "../components/dashboard/DashboardRank.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"
export default {
  name: "DashboardRankModal",
  mixins: [DashboardCardMixins],
  components: {DashboardRank},
  props: {},
  data() {
    return {
      title: "Ranks",
      entrySurveyCode: "",
      surveyGroupList: []
    };
  },
  methods: {
    loadData: async function(){
      if (this.triggerLoadData == true){
        await this.setupDashboardCard();
        let clientCode = this.getCriteriaParameterForDashboardCard(this.saveClientChartObject,'clientCode');
        this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
        await SurveyGroupService.getSurveyGroupsByClientId(clientCode,this.getSurveyGroupsByClientIdCallback, this);
      }
    },    
    getSurveyGroupsByClientIdCallback(response) {
      this.surveyGroupList = [];
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let surveyGroups = response.data.data;
        this.surveyGroupList =  SurveyGroupService.getSurveyGroupOptions(surveyGroups,"Y",this.saveClientChartObject.clientId);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
  },
  watch: {},
  created() {},
  mounted() {},
  umnounted() {},
  computed: {
    triggerLoadData: function() {
      return this.saveClientChartObject != null && (this.saveClientChartObject.displayType == "dashboard-nps-rank" ||
            this.saveClientChartObject.displayType == "dashboard-w-score-rank" || 
            this.saveClientChartObject.displayType == "dashboard-moat-score-rank");
    }
  }
};
</script>
