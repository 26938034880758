<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <respondent-menu></respondent-menu>
    <div v-if="!permissionToView" class="no-permission text-center">You do not have permission to view this page</div>   
    <div v-else class="container survey-group-list">
      <div class="button-container">
        <div class="button-left">
          <div class="pr-2">
            <router-link :to="{ name: 'add-survey-group', params: { initialMode: 'add-survey-group' } }">
              <button type="button" class="btn btn-success" id="AddNewSurveyGroupButton">
                Add Survey Group
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'edit-survey-group', params: { initialMode: 'edit-survey-group', initialSurveyGroupId: this.table.rowSelectedId } }">
              <button type="button" class="btn btn-primary" id="EditClientButton" :disabled="!table.rowSelected">
                Edit Survey Group
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'copy-survey-group', params: { initialMode: 'copy-survey-group', initialSurveyGroupId: this.table.rowSelectedId } }">
              <button type="button" class="btn btn-primary" id="DuplicateSurveyGroupButton" :disabled="!table.rowSelected">
                Copy Survey Group
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'copy']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">  
            <a class="pr-2" href="#" @click="displayDeleteModal">
              <button type="button" class="btn btn-primary" id="DeleteSurveyGroupButton" :disabled="!table.rowSelected">
                Delete Survey Group
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
        <div v-show="isAdmin" class="button-right">
          <a v-show="isAdmin" class="pr-2" href="#" @click="displayImportSurveyGroupModal">
            <button v-show="isAdmin" type="button" class="btn btn-primary">
              <span v-show="isAdmin" class="align-text-bottom">Import Surveys To Group <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a v-show="isAdmin" href="/api/v1/survey-admin/download-survey-group-import-template" @click.prevent="logButtonClickEvent('Download Import Surveys To Survey GroupTemplate'); downloadItem()" id="DownloadSurveyGroupImportTemplate">
            <button v-show="isAdmin" type="button" class="btn btn-primary">
              <span v-show="isAdmin" class="align-text-bottom">Download Import Surveys To Survey Group Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Survey Group Id</th>
                <th>Name</th>
                <th>Is Public</th>
                <th>Survey List</th>
                <th>Active</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
    <import-survey-group :initial-survey-group-name="currentSurveyGroupName" 
    :initial-survey-group="currentSurveyGroup" 
    :initial-mode="mode" 
    :row-selected="table.rowSelected" reload="reloadTable"/>    
  </div>
</template>
<script>
import SurveyGroupService from "../../services/survey.group.service";
import AdminService from "../../services/admin.service";
import GoogleService from "../../services/google.service";
import AlertService from "../../services/alert.service";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import RespondentMixins from "../../mixins/RespondentMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";

import ImportSurveyGroup from "../../modals/ImportSurveyGroup.vue";

export default {
  name: "SurveyGroupList",
  mixins: [RespondentMixins, ContentMixins, DataTableMixins],
  components: {ImportSurveyGroup},
  props: {},
  data() {
    return {
      pageNumber: 4,
      title: "Groups",
      table: {
        id: "survey-group-list-table",
        columns: []
      },
      importSurveyGroupFile: "",
      mode: "new",

    };
  },
  methods: {
    loadData: async function() {
      if (!this.isW5OUser){
        this.table.columns = SurveyGroupService.getTableColumnData();
        await SurveyGroupService.getSurveyGroupList(this.getTableDataCallback, this);
        const vm = this;
        this.table.dataTable.on('dblclick', 'tr', function () {
            vm.$router.push({ name: 'add-survey-group', params: { initialMode: 'edit-survey-group', initialSurveyGroupId: vm.table.rowSelectedId } });
        });
      }
    },
    displayDeleteModal: function() { 
      let title = 'Delete Confirmation Survey Group';
      let content = 'Are you sure you want delete the survey group ?';
      let parameter = this.table.rowSelectedId;
      let callback = this.deleteSurveyGroup;
      AlertService.showConfirmationMessageWithParameter(callback,parameter,title,content);                  
    },
    deleteSurveyGroup: function() {
      this.$root.processServerRequest("Deleting survey group");
      SurveyGroupService.deleteSurveyGroup(this.table.rowSelectedId, this.deleteSurveyGroupCallback, this);
    },
    deleteSurveyGroupCallback: function(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$router.go();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    displayImportSurveyGroupModal: function() {
      if (this.table.rowSelected) {
        this.mode = "existing";
      }
      this.logButtonClickEvent('Import Surveys To Group');
      this.$modal.show("import-survey-group");
    },
    logButtonClickEvent(action){
      GoogleService.logClickEvent(this.clientName, "Survey Group - " + action,  0, this);
    },
    getDownloadFilename() {
      return "Survey Group Import Template";
    },
  },
  created() {
    this.table.configuration.pageLength = 50;
  },
  mounted() {},
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/survey-admin/download-survey-group-import-template";
    },
    currentSurveyGroup: function() {
      if (!this.table.rowSelected) {
        return SurveyGroupService.getDefaultSurveyGroup();
      } else {
        let selectedSurveyGroup = this.table.data.find(x => x.surveyGroupId == this.table.rowSelectedId);
        if (typeof selectedSurveyGroup != "undefined") {
          return selectedSurveyGroup;
        } else {
          return SurveyGroupService.getDefaultSurveyGroup();
        }
      }
    },    
    currentSurveyGroupName: function() {
      if (!this.table.rowSelected) {
        return "";
      } else {
        let selectedSurveyGroup = this.table.data.find(x => x.surveyGroupId == this.table.rowSelectedId);
        if (typeof selectedSurveyGroup != "undefined") {
          return selectedSurveyGroup.name;
        } else {
          return "";
        }
      }
    }
  }
};
</script>
