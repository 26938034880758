<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="locale-list container">
      <div class="button-container">
        <div class="button-left">
          <div class="pr-2">
            <a class="pr-2" href="#" @click="mode='add';$modal.show('locale-config')">
              <button type="button" class="btn btn-success" >
                  Add Locale
                  <span class="ml-auto">
                    <font-awesome-icon :icon="['fa', 'plus-circle']" />
                  </span>
              </button>
            </a>
          </div>
          <div class="pr-2">
            <a 
              :class="[this.table.rowSelected ? 'pr-2 ' : 'pr-2 disable-link']"
              href="#" @click="mode='edit';$modal.show('locale-config')">
              <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
                  Edit Locale
                  <span class="ml-auto">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
              </button>
            </a>
          </div>
          <div class="pr-2">
            <a 
              :class="[!this.table.rowSelected ? 'disable-link' : '']"
              href="#" @click="displayDeleteConfirmation(deleteLocale,table.rowSelectedId,'Delete Confirmation Locale','Are you sure you want delete the locale ?')">
              <button type="button" class="btn btn-primary" id="DeleteLocaleButton" :disabled="!table.rowSelected">
                Delete Locale
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
        <div class="button-right"></div>
      </div>
        <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          <thead></thead>
          <tbody></tbody>
        </table>
    </div>
    <locale-config :input-locale="getLocaleObject()"
      :language-options="languageOptions"
      :country-options="countryOptions"
      :mode="mode"  
      @reload="reloadTable"/>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import LocaleConfig from "../../modals/LocaleConfig.vue";

import LocaleService from "../../services/locale.service";
import AdminService from "../../services/admin.service";

export default {
  name: "LocaleList",
  mixins: [AdminMixins, DataTableMixins],
  components: {LocaleConfig},
  data() {
    return {
      returnMessage: "",
      mode: "add",
      languageOptions: [],
      countryOptions: [],
      title: "Locale List",
      table: {
        id: "locale-list-table",
        columns: [],
        configuration: {
          searching: true,
          responsive: false,
          dom: '<"top"fi>rtp<"bottom"B><"clear">',
        }
      }
    };
  },
  methods: {
    deleteLocale() {
      this.$root.processServerRequest("Deleting locale");
      LocaleService.deleteLocale(this.table.rowSelectedId, this.deleteLocaleCallback, this);
    },
    deleteLocaleCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableData();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },

    async getTableData() {
      this.table.rowSelected = false;
      this.table.columns = LocaleService.getLocaleColumns();
      this.$root.processServerRequest("Loading table data");
      await LocaleService.getLocaleOptions(this.getLocaleOptionsCallback, this);
      await LocaleService.getLocaleList(this.getTableDataCallback, this);
    },
    getLocaleOptionsCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.languageOptions = response.data.data.languageOptions;
        this.countryOptions = response.data.data.countryOptions;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }    
    },
    getLocaleObject(){
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject;
      }
      return LocaleService.getDefaultLocale();
    },
    reloadTable(){
      this.getTableData();
    },    
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {}
};
</script>
