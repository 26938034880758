<template>
    <div v-if="isAdmin" class="panel-admin-body">
        <div class="title">{{ title }}</div>
        <div class="container waves-list">
            <div class="filter-container border-bottom pt-3 pb-3">      
                <div class="row">
                    <div class="form-group form-inline col-3">
                        <label for="client" class="mr-1">Client</label>
                        <select id="client" name="client" class="custom-select required" v-model="client_id">
                        <option
                            v-for="(client_option, idxClientOptions) in this.client_options"
                            v-bind:value="client_option.value"
                            v-bind:key="idxClientOptions"
                        >
                            {{ client_option.name }}
                        </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="pr-2">
                        <button type="button" class="btn btn-primary ml-2" id="FilterButton" @click="filterData()">Filter</button>
                    </div>
                    <div class="pr-2">
                        <button type="button" class="btn btn-primary ml-2" id="ResetButton" @click="reset()">Reset</button>
                    </div>
                </div>
            </div>
            <div class="button-container pt-1 pb-1">
                <div class="button-left">
                    <div class="pr-2">
                        <a href="#" @click="displayDeleteConfirmation(deleteWaves,table.rowSelectedId,'Delete Confirmation Wave','Are you sure you want delete the selected waves ?')">
                        <button type="button" class="btn btn-primary delete-card" id="DeleteWaveButton" :disabled="!deleteWaveButtonEnabled">
                            Delete Wave 
                            <span class="ml-auto">
                            <font-awesome-icon :icon="['fa', 'minus-circle']" />
                            </span>
                        </button>
                        </a>
                    </div>
                </div>
                <div class="button-right">
                    <a class="pr-2" href="#" @click="showImportModal">
                        <button type="button" class="btn btn-primary">
                        <span class="align-text-bottom">Import Waves Data <font-awesome-icon :icon="['fas', 'upload']"/></span>
                        </button>
                    </a>
                    <a href="/api/v1/client-time-period/download-all-wave-import-template" @click.prevent="downloadItem()" id="DownloadMetricImportTemplate">
                        <button type="button" class="btn btn-primary">
                        <span class="align-text-bottom">Download Template <font-awesome-icon :icon="['fa', 'download']"/></span>
                        </button>
                    </a>
                </div>
            </div>
            <div class="data-container pt-1 pb-1">
                <div class="col-12">
                    <table :id="table.id" :class="table.class" cellspacing="0" width="100%" style="width: 100%;">
                        <thead></thead>
                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </div>
        <import-wave-file  @reload="getTableData" />              
    </div>
</template>

<script>
import $ from "jquery";

import FilterMixins from "../../mixins/FilterMixins.vue";
import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import ClientChartService from "../../services/client.chart.service";
import AdminService from "../../services/admin.service";
import ClientTimePeriodService from  "../../services/client.time.period.service";
import ImportWaveFile from "../../modals/ImportWaveFile.vue";

export default {
    name: "WavesList",
    mixins: [AdminMixins, DataTableMixins,FilterMixins],
    components: {ImportWaveFile},
    data() {
        return {
            title: "Waves List",
            table: {
                id: "saved-card-list-table",
                class: "table display wrap row-border table-hover",
                configuration: {
                dom: '<"top"if>rtp<"bottom"B><"clear">',
                searching: true,
                paging: true,
                select: true,
                destroy: true,
                info: false,
                ordering: true,
                bSort: true,
                bSortable: true,
                keys: true,
                autoWidth: false,
                processing: true,
                responsive: false,
                order: [[2, "asc"], [4, "asc"], [5, "asc"]],
                pageLength: 50
                },
                columns: ClientTimePeriodService.getAdminWavesTableColumnData()
            },
            tableSortBy: [[2, "asc"], [4, "asc"], [5, "asc"]],
            returnMessage: "",
            clientTimePeriodIdsToDelete: [],
            numberOfCopies: 1,
            client_id: 0,
            client_options: [],
            survey_group_options: [],
        }
    },    
    props: {},
    methods: {
        getDownloadFilename() {
          return "Wave Data Import Template";
        },    
        showImportModal(){
            this.returnMessage = "";
            this.$modal.show('import-wave-file');
        },        
        async deleteWaves(result) {
            ClientTimePeriodService.deleteWaves(this.clientTimePeriodIdsToDelete,this.deleteClientChartsCallback,this);
        },     
        deleteClientChartsCallback(response) {
            this.$root.processServerResponse();
            let statusCode = response.data.statusCode;
            if (statusCode == 200) {
                AdminService.displaySuccessMessage({ text: response.data.message });
                this.clientTimePeriodIdsToDelete = [];
                this.getTableData();
            } else {
                AdminService.displayErrorMessage({ text: response.data.message });
            }
        },
        async getTableData() {
            this.originalList = [];
            this.$root.processServerRequest("Loading table data");
            ClientTimePeriodService.getWavesList(this.getWavesTableDataCallback, this);
        },
        async getWavesTableDataCallback(response)  {
            this.originalList = response.data.data.wavesList;
            this.client_options = ClientChartService.buildClientOptionList(this.originalList);
            await this.filterData();            
            this.table.configuration.order = this.tableSortBy;
            this.setupRowClick();
            this.$root.processServerResponse();
        },
        filterData(){
            let goAheadAndFilterData = false;
            if (this.client_id > 0){
                goAheadAndFilterData = true;
                this.filteredList =  ClientChartService.getClientChartsByClient(this.originalList, this.client_id, true);
            }
            if (goAheadAndFilterData == true){
                this.client_options = ClientChartService.buildClientOptionList(this.filteredList);
                this.table.data = this.filteredList;
            } else {
                this.table.data = this.originalList;
            }
            this.setupTable();
        },
        setupRowClick(){
            const vm = this;
            if( this.table.dataTable.click == undefined)  {

                this.table.dataTable.on("click", 'input[type="checkbox"]', function(e, dt, type, indexes) {
                    var $row = $(this).closest("tr");
                    var data = vm.table.dataTable.row($row).data();
                    if (!$.isEmptyObject(data)) {
                        if (e.target.checked) {
                            if (!vm.clientTimePeriodIdsToDelete.includes(data.clientTimePeriodId)){
                                vm.clientTimePeriodIdsToDelete.push(data.clientTimePeriodId);
                            }
                        } else {
                            var match = vm.clientTimePeriodIdsToDelete.find(x => x === data.clientTimePeriodId);
                            if (typeof match !== "undefined") {
                                vm.clientTimePeriodIdsToDelete.splice($.inArray(match, vm.clientTimePeriodIdsToDelete), 1);
                            }
                        }
                    }
                });       
        }

        },
        async reload(message){
            await this.getTableData();
            AdminService.displaySuccessMessage({ text: message });
        },
        reset(){
            this.table.data = this.originalList;
            this.client_options = ClientChartService.buildClientOptionList(this.originalList);
            this.client_id = 0;
            this.table.configuration.order = this.tableSortBy;
            this.setupTable();
        },
    },   
    computed: {
        downloadItemUrl: function() {
             return this.baseUrl + "/api/v1/client-time-period/download-all-wave-import-template";
        },        
        deleteWaveButtonEnabled: function(){
            return this.clientTimePeriodIdsToDelete.length > 0;
        },
    },
    created() {
        this.getTableData();
    },    
    mounted() {}, 
    watch : {}   
};
</script>