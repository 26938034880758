<template>
  <div v-if="isViewModeQuestion" class="form-inline w-100 survey-question question-body">
    <div v-if="selectedWidgetType.name == 'Slider'" class="col-8">{{ questionIndex + 1 }}. {{ questionLabel.toString() }}</div>
    <div v-else class="col-8">{{ questionIndex + 1 }}. {{ questionLabel }}</div>
    <div v-if="!disableEditMode" class="question-actions col-4">
      <button
        v-show="!notEditable && !isCompetitorCompanyCodeQuestion"
        type="submit"
        class="btn btn-primary float-right ml-2"
        @click="displayConfirmation()"
      >
        <span>Delete</span>
      </button>
      <button v-if="!notEditable" class="btn btn-primary float-right" type="submit" @click="mode = 'edit'">Edit</button>
      <button v-if="notEditable" class="btn btn-primary float-right" type="button" @click="showRedirectModal">Edit</button>
    </div>
    <div v-if="disableEditMode" class="col-4 text-right">
      <button class="btn btn-primary ml-4" type="button" @click="showRedirectModal">Edit</button>
    </div>
    <div v-show="!notEditable" class="w-100 pl-2">
      <input v-if="isTextBox || isEmail || isPhoneNumber" type="text" disabled />
      <select v-if="isDropdown" class="w-25" disabled />
      <input v-if="isCheckbox" type="checkbox" disabled />
      <input v-if="isRadio" type="radio" disabled />
      <textarea v-if="isTextArea || isContent" />
      <img v-if="isSlider" src="../assets/img/slider.png" />
    </div>
  </div>
  <div v-else class="edit-question-container">
    <div class="row m-1">
      <div class="w-100 form-row align-items-center p-1">
        <div class="mr-1">Q{{ questionIndex }}</div>
        <div class="form-group col-8">
          <div v-if="selectedWidgetType.name == 'Slider'" class="form-inline">
            <div class="form-inline w-30">
              <label for="leftLabel" class="mr-1">Left Label</label>
              <input v-model="questionLabel[0]" :id="questionId" :name="questionId" type="text" class="form-control w-75" />
            </div>
            <div class="form-inline w-30">
              <label for="rightLabel" class="mr-1">Right Label</label>
              <input v-model="questionLabel[1]" :id="questionId" :name="questionId" type="text" class="form-control w-75" />
            </div>
          </div>
          <input v-else v-model="questionLabel" :id="questionId" :name="questionId" type="text" class="form-control w-100" />
        </div>
        <div class="form-group col-3">
          <select v-if="!notEditable" id="widgetType" v-model="questionWidgetTypeId" name="widgetType" class="custom-select" @change="setSelectedWidgetType">
            <option value="">Please Select Widget Type</option>
            <option
              v-for="(option, idxWidgetTypeOptions) in this.widgetTypeOptionsToDisplay"
              v-bind:value="option.value"
              v-bind:key="idxWidgetTypeOptions"
              v-bind:selected="widgetTypeId == option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <div v-if="notEditable">You are not able to update the field type for this section</div>
        </div>
      </div>
      <div v-if="displayDefaultValue" class="form-row w-100">
          <question-default-value :initial-default-value="widgetConfiguration.defaultValue" :display-feature="displayDefaultValue" @save="setWidgetConfigurationDefaultValue"> </question-default-value>
      </div>
      <div v-if="displayPlaceholder" class="form-row w-100">
        <div class="form-group col-12">
          <question-placeholder :initial-placeholder="widgetConfiguration.placeholder" :display-feature="displayPlaceholder" @save="setWidgetConfigurationPlaceholder"> </question-placeholder>
        </div>
      </div>
      <div class="segment-question-section">
        <div class="segment-question-sub-section">
          <div v-show="displayRequired" class="">
            <input type="checkbox" id="required" name="required" v-model="questionRequired" @click="checkQuestionRequired($event)" true-value="Y" false-value="N" />
            <label for="required" class="control-label pl-1">Answer Required</label>
          </div>
        </div>
        <div class="segment-question-sub-section segment-section-border display-section">
            <div v-show="showDisplayField" class="">
                <input type="checkbox" id="display" name="display" v-model="displayQuestion" @click="checkQuestionDisplayOnSurvey($event)" true-value="Y" false-value="N" />
                <label for="display" class="control-label pl-1">Display in Survey</label>
            </div>
            <div v-show="displayNumberOfColumns" class="">
              <label for="number_of_columns" class="control-label pr-1"># of display columns for options</label>
              <select id="number_of_columns" v-model="widgetConfiguration.number_of_columns" name="number_of_columns" class="custom-select">
                <option
                  v-for="(option, idxNumberOfColumnsOptions) in this.number_of_columns_options"
                  v-bind:value="option.value"
                  v-bind:key="idxNumberOfColumnsOptions"
                  v-bind:selected="widgetTypeId == option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div v-show="showDisplayWideLabel" class="" >
              <input type="checkbox" id="displayWideLabel" name="displayWideLabel" v-model="widgetConfiguration.displayWideLabel" />
              <label for="displayWideLabel" class="control-label pl-1">Display Wide Label</label>
            </div>
        </div>
        <div class="segment-question-sub-section segment-section-border segment-section">
            <div v-show="displayIncludeAsSegment" class="">
              <input type="checkbox" id="segment" name="segment" v-model="includeAsSegment" true-value="Y" false-value="N" @click="checkSegmentLimit($event)" />
              <label for="segment" class="control-label pl-1">Display in Segments</label>
            </div>
            <div v-show="includeAsSegment == 'Y'" class="" >
              <label for="segmentLabel" class="control-label text-danger pr-1">Segment Label</label>
              <input v-model="segmentLabel" id="segmentLabel" name="segmentLabel" type="text" class="form-control" />
            </div>
        </div>
      </div>
      <div v-if="!notEditable" class="w-100 form-row" style="border-top: 1px solid #d0d2d3">
        <question-content
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Content Section'"
          :survey-name="$parent.$parent.surveyName"
          :initial-content-id="Number(widgetConfiguration.contentId)"
          :content-sections="$parent.$parent.surveyContentSections"
          @save="setWidgetConfigurationContentId"
          @reload="reloadContentSections"
        >
        </question-content>
        <question-drag-drop
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Drag and Drop'"
          :initial-choices="widgetConfiguration.choices"
          :initial-choice-order="widgetConfiguration.choiceOrder"
          :initial-number-of-choices-to-rank="Number(widgetConfiguration.numberOfChoicesToRank)"
          @save="setWidgetConfigurationDragDrop"
        ></question-drag-drop>
        <div v-if="selectedWidgetType.name == 'Slider'" class="form-inline p-1 mt-2">
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Minimum Value</label>
            <input v-model="widgetConfiguration.min" id="minimumValue" name="minimumValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Maximum Value</label>
            <input v-model="widgetConfiguration.max" id="maximumValue" name="maximumValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Increment Value</label>
            <input v-model="widgetConfiguration.step" id="incrementValue" name="incrementValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Default Value</label>
            <input v-model="widgetConfiguration.value" :id="'defaultValue_' + questionId" name="defaultValue" type="text" class="form-control w-30" />
          </div>
        </div>
        <div v-show="isCompetitorCompanyCodeQuestion" class="w-100 form-row">
          <div class="mr-1 text-danger text-center col-12">You will be need to go to the Constants screen to add/remove competitors</div>
        </div>
        <div class="w-100 row p-2 no-gutters">
          <div class="col-4">
            <question-checkbox
              v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Checkbox Group'"
              :initial-max-enabled="widgetConfiguration.maximumNumberOfValuesEnabled"
              :initial-max-number="Number(widgetConfiguration.maximumNumberOfValues)"
              :initial-choices="widgetConfiguration.options"
              :initial-choice-order="widgetConfiguration.choiceOrder"
              @saveLimitResponses="setWidgetConfigurationCheckboxLimitResponsesOnly"          
              @saveOrder="setWidgetConfigurationCheckboxOrderOnly"          
              @save="setWidgetConfigurationCheckbox"          
            ></question-checkbox>
          </div>
          <div class="col-8">
            <question-option
              v-for="(widgetConfigurationOption, idxWidgetConfiguration) in this.widgetConfiguration.options"
              class="w-100 form-row"
              :key="widgetConfigurationOption.value"
              :new-question-option-index="idxWidgetConfiguration"
              :option-object="widgetConfigurationOption"
              :is-editable="!isCompetitorCompanyCodeQuestion"
              @save="saveOption"
              @remove="removeOption"
            >
            </question-option>
          </div>
        </div>
      </div>
      <div class="w-100 form-row">
        <div class="col p-1">
          <button class="btn btn-primary float-right ml-2" :disabled="disableCancelButton" @click="showConfirmationMessage(questionObject.questionId)">Cancel</button>
          <button type="submit" class="btn btn-primary float-right ml-2" @click="saveQuestion()" :disabled="disableSaveButton">
            <span>Save Question</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";

import QuestionMixins from "../mixins/QuestionMixins.vue";
import QuestionContent from "../components/QuestionContent.vue";
import QuestionPlaceholder from "../components/QuestionPlaceholder.vue";
import QuestionDefaultValue from "../components/QuestionDefaultValue.vue";
import QuestionDragDrop from "../components/QuestionDragDrop.vue";
import QuestionCheckbox from "../components/QuestionCheckbox.vue";

import "jquery-ui";
import QuestionOption from "../components/QuestionOption.vue";
import ContentMixins from "../mixins/ContentMixins.vue";
import AdminService from "../services/admin.service";
import QuestionService from "../services/question.service";
import AlertService from "../services/alert.service";

export default {
  name: "QuestionDetails",
  mixins: [QuestionMixins, ContentMixins],
  props: {},
  components: {
    QuestionOption,
    QuestionContent,
    QuestionPlaceholder,
    QuestionDragDrop,
    QuestionCheckbox,
    QuestionDefaultValue
  },
  data() {
    return {
      mode: "view",
      questionWidgetTypeId: "",
      disableSaveButton: false,
      disableCancelButton: false,
      question: {},
      selectedWidgetType: {},
      widgetConfiguration: {},
      number_of_columns_options: QuestionService.getNumberOfColumnOptions(),
    };
  },
  methods: {
    showConfirmationMessage(id) {
      AlertService.showConfirmationMessage(this.resetQuestion);      
    },    
    disableButtons: function() {
      this.disableSaveButton = true;
      this.disableCancelButton = true;
    },
    enableButtons: function() {
      this.disableSaveButton = false;
      this.disableCancelButton = false;
    },
    reloadContentSections: function() {
      this.$parent.$parent.$emit("reload");
    },
    setWidgetConfigurationContentId: function(result) {
      this.widgetConfiguration.contentId = result.contentId;
      this.questionLabel = "Content - " + result.name;
    },
    checkQuestionDisplayOnSurvey: function(e) {
      if (e.target.checked) {
        this.displayQuestion == "Y";
      } else {
        this.displayQuestion == "N";
      }
    },
    checkQuestionRequired: function(e) {
      if (e.target.checked) {
        this.questionRequired == "Y";
      } else {
        this.questionRequired == "N";
      }
    },
    setWidgetConfigurationPlaceholder: function(result) {
      this.widgetConfiguration.placeholder = result;
    },    
    setWidgetConfigurationDefaultValue:function(result) {
      this.widgetConfiguration.defaultValue = result;
    },
    setWidgetConfigurationCheckboxLimitResponsesOnly(enable){
      this.widgetConfiguration.maximumNumberOfValuesEnabled = enable;
    },
    setWidgetConfigurationCheckboxOrderOnly(order){
      this.widgetConfiguration.choiceOrder = order;
    },
    setWidgetConfigurationCheckbox(enable, maxNumberOfChoices,options,order){
      this.widgetConfiguration.options = options;
      this.widgetConfiguration.choiceOrder = order;
      this.widgetConfiguration.maximumNumberOfValuesEnabled = enable;
      this.widgetConfiguration.maximumNumberOfValues = maxNumberOfChoices;
    },
    setWidgetConfigurationDragDrop:function(choices, order, numberOfChoicesToRank) {
      this.widgetConfiguration.choices = choices;
      this.widgetConfiguration.choiceOrder = order;
      this.widgetConfiguration.numberOfChoicesToRank = numberOfChoicesToRank;
    },    
    resetQuestion() {
      this.mode = "view";
      this.setSelectedWidgetType();
      this.initializeQuestion();
    },
    displayConfirmation() {
      AlertService.showConfirmationMessageWithParameter(this.deleteQuestion,this.questionObject.questionId,'Delete Confirmation Question Details','Are you sure you want delete ' + this.questionObject.label + ' ?');            
    },
    showRedirectModal() {
      if (this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs") {
        this.$modal.show("navigate-to-needs");
      } else if (this.sectionTypeName == "Pricing Power") {
        this.$modal.show("navigate-to-ideal");
      }
    },
    saveOption: function(result) {
      var option = this.widgetConfiguration.options.find(x => x.value == result.value);
      if (option !== undefined) {
        option.name = result.name;
      }
    },
    removeOption: function(result) {
      var option = this.widgetConfiguration.options.find(x => x.value == result.value);
      if (option !== undefined) {
        this.widgetConfiguration.options.splice($.inArray(option, this.widgetConfiguration.options), 1);
      }
    },
    async deleteQuestion(resultId) {
      if (!this.initialLoad) {
        this.$root.processServerRequest("Deleting Question");
        QuestionService.deleteQuestion(resultId, this.deleteQuestionCallback, this);
      }
    },
    deleteQuestionCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$emit("remove", this.question);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    checkSegmentLimit(event) {
      if (event.target.checked) {
        if (1 + this.$parent.$parent.totalNumberOfChartSegments > this.$parent.$parent.maximumNumberOfChartSegments) {
            AdminService.displayErrorMessage({
              text: "The maximum number of chart segments have been selected. If you want to include any additional selections you must deselect an existing question.",
              duration: -1
            })
          event.preventDefault();
          this.includeAsSegment = "N";
          event.target.checked = "off";
        } else {
          this.includeAsSegment == "Y";
        }
      } else {
        this.includeAsSegment == "N";
      }
    },
    async saveQuestion() {
      if (
        this.includeAsSegment == "Y" &&
        this.selectedWidgetType !== undefined &&
        this.selectedWidgetType.name != "Hidden" &&
        this.selectedWidgetType.name != "Dropdown" &&
        this.selectedWidgetType.name != "Drag and Drop" &&
        this.selectedWidgetType.name != "Radio Button Group" &&
        this.selectedWidgetType.name != "Checkbox Group"
      ) {
        AdminService.displayErrorMessage({ text: "You must have a field type of dropdown, radio button, or checkbox if the question is marked as a segment.", duration: -1 })
        return;
      }
      if (this.includeAsSegment == "Y" && this.segmentLabel == "") {
        AdminService.displayErrorMessage({ text: "Please populate the Segment Label field.", duration: -1 });
        return;
      } else {
        this.$root.processServerResponse();  
        var result = {
          label: this.questionLabel,
          required: this.questionRequired,
          display: this.displayQuestion,
          questionId: this.question.questionId,
          widgetTypeId: this.questionWidgetTypeId,
          widgetConfiguration: JSON.stringify(this.widgetConfiguration),
          isCompetitorCompanyCodeQuestion: this.isCompetitorCompanyCodeQuestion,
          includeAsSegment: this.includeAsSegment,
          segmentLabel: this.segmentLabel
        };
        if (!this.initialLoad) {
          this.$root.processServerRequest("Saving Question");
          QuestionService.saveQuestion(result, this.saveQuestionCallback, this);
        }
      }
    },
    saveQuestionCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        $.extend(this.question, response.data.data.question);
        $.extend(this.$parent.question, response.data.data.question);
        this.$parent.$parent.setTotalNumberOfChartSegments();
        this.mode = "view";
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    setSelectedWidgetType() {
      this.selectedWidgetType = this.getWidgetType(this.questionWidgetTypeId);
      // Dont do anything if it is the competitor code
      if (this.isCompetitorCompanyCodeQuestion) {
        return;
      }
      if (!jQuery.isEmptyObject(this.selectedWidgetType)) {
        // Set it to the original
        if (this.selectedWidgetType.name == this.questionObject.widgetType.description) {
          if (this.questionObject.widgetConfiguration != "") {
            this.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
          } else {
            this.widgetConfiguration = {};
          }
        } else if (this.selectedWidgetType.name == "Dropdown") {
          this.widgetConfiguration.options = [
            { name: "", value: "-1" },
            { name: "PLEASE SELECT", value: "0", selected: "selected" },
            { name: " ", value: "1" }
          ];
        } else if (this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Hidden") {
          this.widgetConfiguration.options = [{ name: " ", value: "1" }];
        } else {
          this.widgetConfiguration.options = [];
        }
        if (this.selectedWidgetType.name == "Slider") {
          if (jQuery.isEmptyObject(this.Configuration)) {
            this.widgetConfiguration = { min: 6, max: 605, step: 100, value: 308 };
          }
        }
        if (this.selectedWidgetType.name == "Checkbox Group") {
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "maximumNumberOfValuesEnabled")) {    
            this.widgetConfiguration.maximumNumberOfValuesEnabled = "N";
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "maximumNumberOfValues")) {    
            this.widgetConfiguration.maximumNumberOfValues = 1;
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choiceOrder")) {    
            this.widgetConfiguration.choiceOrder = "random";
          }
        }
        if (this.selectedWidgetType.name == "Drag and Drop") {
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choices")) {    
            this.widgetConfiguration.choices = [{name: "",sequenceNumber: 1},{name: "",sequenceNumber: 2}];
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choices")) {    
            this.widgetConfiguration.choices = [{name: "",sequenceNumber: 1},{name: "",sequenceNumber: 2}];
          }          
        }        
      }
      if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "number_of_columns")) {    
        this.widgetConfiguration.number_of_columns = "3";
      }
    },
    getWidgetType(widgetId) {
      if (this.widgetTypeOptionsToDisplay) {
        return this.widgetTypeOptionsToDisplay.find(x => x.value == widgetId);
      } else {
        return {};
      }
    }
  },
  created() {
    $.extend(this.question, this.questionObject);
    if (this.isSlider) {
      this.questionLabel = this.questionObject.label.split(",");
    }
  },
  mounted() {
    this.initialLoad = false;
    this.questionWidgetTypeId = this.questionObject.widgetType.widgetTypeId;
    if (this.questionObject.widgetConfiguration !== "") {
      this.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
    } else {
      this.widgetConfiguration = {};
    }
    this.setSelectedWidgetType();
  },
  watch: {},
  computed: {
    deleteConfirmationTitle: function() {
      return "Confirmation " + this.questionObject.label;
    },
    showDisplayWideLabel: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Dropdown" ||
               this.selectedWidgetType.name == "Textbox" || this.selectedWidgetType.name == "Phone Number" || this.selectedWidgetType.name == "Email Address";
      }
      return false;
    },
    displayNumberOfColumns: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Checkbox Group";
      }
      return false;
    },
    displayPlaceholder: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Textbox" || this.selectedWidgetType.name == "Phone Number" || this.selectedWidgetType.name == "Email Address";
      }
      return false;
    },
    displayDefaultValue: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Hidden";
      }
      return false;
    },
    isEditMode: function() {
      return this.mode == "edit";
    },
    isViewModeQuestion: function() {
      return this.mode == "view";
    },
    notEditable: function() {
      return this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs" || this.sectionTypeName == "Pricing Power";
    },
    isPermissionQuestion: function() {
      let isPermissionQuestion = false;
      if (typeof this.questionObject.questionType != "undefined" && this.questionObject.questionType != null) {
        isPermissionQuestion = this.questionObject.questionType.questionType == "Net Promoters Score" && this.questionObject.questionType.name == "NPS Permission";
      }
      return isPermissionQuestion;
    },
    isCompetitorCompanyCodeQuestion: function() {
      let isCompetitorCompanyCodeQuestion = false;
      if (typeof this.questionObject.questionType != "undefined" && this.questionObject.questionType != null) {
        isCompetitorCompanyCodeQuestion =
          this.questionObject.questionType.questionType == "Net Promoters Score" && this.questionObject.questionType.name == "Competitor Company Code";
      }
      return isCompetitorCompanyCodeQuestion;
    },
    widgetTypeId: function() {
      return this.question.widgetType.widgetTypeId;
    },
    widgetTypeOptionsToDisplay() {
      if (this.isCompetitorCompanyCodeQuestion) {
        let returnArray = [];
        let dropdownWidgetType = this.widgetTypeOptions.find(x => x.name == "Dropdown");
        let radioButtonWidgetType = this.widgetTypeOptions.find(x => x.name == "Radio Button Group");
        returnArray.push(dropdownWidgetType);
        returnArray.push(radioButtonWidgetType);
        return returnArray;
      } else if (this.includeAsSegment == "Y") {
        let returnArray = [];
        let dropdownWidgetType = this.widgetTypeOptions.find(x => x.name == "Dropdown");
        let radioButtonWidgetType = this.widgetTypeOptions.find(x => x.name == "Radio Button Group");
        let checkboxWidgetType = this.widgetTypeOptions.find(x => x.name == "Checkbox Group");
        let hiddenWidgetType = this.widgetTypeOptions.find(x => x.name == "Hidden");
        let dragWidgetType = this.widgetTypeOptions.find(x => x.name == "Drag and Drop");
        returnArray.push(dropdownWidgetType);
        returnArray.push(radioButtonWidgetType);
        returnArray.push(checkboxWidgetType);
        returnArray.push(hiddenWidgetType);
        returnArray.push(dragWidgetType);
        return returnArray;
      }
      return this.widgetTypeOptions;
    }
  }
};
</script>
