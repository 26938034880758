<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" @opened="loadData" :resizable="true" width="820" :maxHeight="1050" :height="'auto'" :scrollable="true">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-show="getDisplayView()" class="modal-body setup-grade-thresholds">
        <div>
            Reject respondents in data with Grades of 
            <div class="d-inline">
                <select id="rejectGrade" name="rejectGrade" class="shadow" v-model="surveyQualityScoreReject.grade" style="width: 35px;" >
                    <option value="">Select</option>
                    <option v-for="(option, selectOptions) in grades_options" v-bind:value="option.value" v-bind:key="selectOptions">
                      {{ option.name }}
                    </option>
                </select>
            </div>
            or lower and Points of <input v-model="surveyQualityScoreReject.points" id="rejectGradeScore" name="rejectGradeScore" type="text" style="width: 25px;" /> or higher
        </div>
        <div class="pb-2 pt-2">
            Set the thresholds for each respondent to meet for those points and grades. <br />
            Lower Grades or Higher Points indicates a lower quality of respondent data
        </div>
        <div class="header">
            <div class="col-1">No.</div>
            <div class="col-1">Points</div>
            <div class="col-1">Grade</div>
            <div class="col-1">Threshold</div>
            <div class="col-5">Description</div>
        </div>
        <div class="threshold" v-for="(threshold, idxThreshold) in surveyGradeThresholdList" v-bind:key="idxThreshold">
            <div class="col-1 align-self-center">{{ idxThreshold + 1 }}</div>
            <div class="col-1 align-self-center">{{ threshold.points }}</div>
            <div class="col-1 align-self-center">{{ threshold.grade }}</div>
            <div class="col-1 align-self-center">
                <input v-if="!isThresholdRequireACheckbox(threshold.qualityScoreTypeId)" v-model="threshold.threshold" :id="'thresholdValue_' + idxThreshold" name="thresholdValue" type="text" class="threshold-textbox" />
                <input v-if="isThresholdRequireACheckbox(threshold.qualityScoreTypeId)"  v-model="threshold.threshold" :id="'thresholdValue_' + idxThreshold" name="thresholdValue" type="checkbox"  class="threshold-checkbox" 
                      :checked="threshold.threshold == 0" true-value="0" false-value="1"/>
            </div>
            <div class="col-5 text-left align-self-center">{{ threshold.qualityScoreTypeDescription }}</div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
            <button type="button" class="btn btn-primary ml-4" @click="resetDefaultThresholds">Reset to Default Thresholds</button>
        </div>
        <div>
            <button type="button" class="btn btn-primary ml-4" @click="saveGradeThresholds" :disabled="disableSave">Save</button>
            <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import SurveyScoreService  from "../services/survey.score.service";

import AdminService from "../services/admin.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import { type } from 'jquery';

export default {
  name: "SetupGradeThresholds",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      surveyGradeThresholdList: [],
      defaultGradeThresholdList: [],      
      returnMessage: "",
      disableSave: false,
      surveyQualityScoreReject: {},      
      defaultSurveyQualityScoreReject: {
          grade: "-",
          points: 30
      },
      grades_options: [
        {"name": "--","value": "-"},
        {"name": "F","value": "F"},
        {"name": "D","value": "D"},
        {"name": "C","value": "C"},
        {"name": "B","value": "B"}
      ],
      refreshModal: true
    };
  },
  props: {
    inputSurveyCode: {
        type: String,
        default: ""
    },     
    modalId: {
      type: String,
      default: "setup-grade-thresholds",
    },
    modalTitle: {
      type: String,
      default: "Survey Grade Thresholds",
    },
  },
  computed: {
    useDefaultList: function(){
        return this.surveyGradeThresholdList.length == 0;
    }
  },
  mounted() {},
  created() {},
  methods: {
    isThresholdRequireACheckbox(qualityScoreTypeId){
        let result = false;
        if (qualityScoreTypeId == 7 || qualityScoreTypeId == 10 || qualityScoreTypeId == 11 || qualityScoreTypeId == 12 || qualityScoreTypeId == 13){
            result = true;
        }
        return result;
    },
    saveGradeThresholds(){
        this.disableSave = true;
        this.$root.processServerRequest("Saving Grade Thresholds");
        SurveyScoreService.saveSurveyGradeThresholds(this.inputSurveyCode, this.surveyGradeThresholdList, this.surveyQualityScoreReject.grade, this.surveyQualityScoreReject.points, this.getSurveyGradeThresholdsCallback, this);
    },
    getSurveyGradeThresholdsCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyGradeThresholdList = response.data.data.surveyGradeThresholdList;
        this.defaultGradeThresholdList = response.data.data.defaultGradeThresholdList;
        if (this.surveyGradeThresholdList.length == 0){
            this.resetDefaultThresholds();
        }
        this.surveyQualityScoreReject = response.data.data.surveyQualityScoreReject;
        this.returnMessage = response.data.message;
        this.setDisplayView(true);
        this.disableSave = false;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.disableSave = false;
    },
    loadData() {
      this.$root.processServerRequest("Loading Grade Thresholds");
      SurveyScoreService.getSurveyGradeThresholds(this.inputSurveyCode,this.getSurveyGradeThresholdsCallback, this);
    },
    resetDefaultThresholds(){
      this.surveyGradeThresholdList = JSON.parse(JSON.stringify(this.defaultGradeThresholdList));
      this.surveyQualityScoreReject = JSON.parse(JSON.stringify(this.defaultSurveyQualityScoreReject));
    },
    closeModal() {
      this.setDisplayView(false);
      if (this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },
  },
  watch: {},
};
</script>
