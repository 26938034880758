<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <design-menu></design-menu>
    <div v-if="permissionToView">
      <div v-show="!isSectionOrderMode" class="row no-gutters justify-content-end mt-2">      
        <button type="submit" class="btn btn-primary float-right ml-2 mr-2" v-on:click="openSectionOrder">Edit Section Order</button>
      </div>
      <div v-if="getDisplayView()" class="survey-design">
        <nav class="sectionActions"></nav>
        <div v-show="isSectionOrderMode">
          <section-order :input-sections="this.sections" @save="sectionOrderCallback" @enable-save-order-button="activateSaveSectionOrderButton"></section-order>
          <div class="row no-gutters mt-2 mb-2 justify-content-end">
            <button type="submit" class="btn btn-primary float-right ml-2" :disabled="!this.enableSaveOrderButton" v-on:click="this.saveSectionOrder">
                <span>Save Order</span>
                <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="mode = 'view'">Close</button>
          </div>
        </div>
        <div v-show="!isSectionOrderMode" v-for="(section, idxSection) in this.sections" :key="'section' + idxSection">
          <survey-section
            v-if="section.active == 'Y'"
            :ref="idxSection"
            :section-object="section"
            :survey-id="survey.surveyId"
            :survey-name="survey.name"
            :survey-content-sections="surveyContentSections"
            :survey-template-object="survey.surveyTemplate"
            @reload="reloadSurveyContentSections"
          ></survey-section>
        </div>
        <survey-section
          v-if="isAddSectionMode && !isSectionOrderMode"
          initial-mode="edit"
          :section-object="newSection"
          :survey-id="survey.surveyId"
          :survey-name="survey.name"
          :survey-content-sections="surveyContentSections"
          :survey-template-object="survey.surveyTemplate"
          @reload="reloadSurveyContentSections"
        ></survey-section>
        <div v-show="!isSectionOrderMode" class="row no-gutters">
          <div class="col" role="group">
            <button type="submit" class="finish-button btn btn-primary float-right ml-2" v-on:click="goToSurveyPreview">Next</button>
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="goToIncludeSection">Previous</button>
          </div>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
        <vue-modal ref="navigate-to-needs" name="navigate-to-needs" :width="350" :height="150">
          <div class="container">
            <div class="form-group form-row p-2">
              <h5 class="font-weight-bold">Redirect to Needs</h5>
            </div>
            <div class="form-group form-row align-items-center">You will be redirect to default fields to make modifications to this field</div>
            <div role="group" class="chart-buttons btn-group-horizontal">
              <button class="btn btn-primary" type="button" @click="goToNeeds">Go To Needs</button>
              <button type="button" class="btn btn-primary ml-4" @click="closeModal('navigate-to-needs')">Close</button>
            </div>
          </div>
        </vue-modal>
        <vue-modal ref="navigate-to-ideal" name="navigate-to-ideal" :width="350" :height="150">
          <div class="container">
            <div class="form-group form-row p-2">
              <h5 class="font-weight-bold">Redirect to Pricing Power</h5>
            </div>
            <div class="form-group form-row align-items-center">You will be redirect to pricing power fields to make modifications to this field</div>
            <div role="group" class="chart-buttons btn-group-horizontal">
              <button class="btn btn-primary" type="button" @click="goToTheCompetitors">Go To Pricing Power</button>
              <button type="button" class="btn btn-primary ml-4" @click="closeModal('navigate-to-ideal')">Close</button>
            </div>
          </div>
        </vue-modal>
      </div>
    </div>
    <div v-else class="include-sections text-center">You do not have permission to view this page</div>
  </div>
</template>
<script>
import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";
import SurveySection from "../../components/SurveySection.vue";
import SurveyStatus from "../../components/SurveyStatus.vue";
import SectionOrder from "../../components/SectionOrder.vue";

import ContentService from "../../services/content.service";
import SectionService from "../../services/section.service";
import AdminService from "../../services/admin.service";
import GoogleService from "../../services/google.service";
import SurveyService from "../../services/survey.service";
import ErrorService from "../../services/error.service";

import $ from "jquery";

export default {
  name: "SurveyDesign",
  mixins: [StudyDesignMixins, ContentMixins],
  components: {
    SurveySection,
    SurveyStatus,
    SectionOrder
  },
  props: {},
  data() {
    return {
      pageNumber: 3,
      surveyContentSections: [],
      title: "Segments",
      mode: "view",
      enableSaveOrderButton: false,      
      newSection: {}
    };
  },
  methods: {
    openSectionOrder : function(){
      if (this.mode == 'view'){
        this.mode = 'edit-section-order';
      } else {
        this.mode = 'view';
      }
    },
    updateSections: function(data){
      this.survey.sections =  JSON.parse(JSON.stringify(data));
    },
    activateSaveSectionOrderButton(){
      this.enableSaveOrderButton = true;
    },
    sectionOrderCallback(result){
      this.survey.sections = JSON.parse(JSON.stringify(result));
    },
    saveSectionOrder(){
      let sectionsInOrder = SectionService.convertSectiontoSectionDto(this.sections);
      this.$root.processServerRequest("Saving section");
      GoogleService.logClickEvent(SurveyService.getSurveyName(), "Save Order - Section", 0, this);
      SectionService.saveSectionOrder(sectionsInOrder, this.survey.surveyId, this.saveSectionOrderCallback, this);
    },    
    saveSectionOrderCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.updateSections(response.data.data);
        this.enableSaveOrderButton = false;
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        ErrorService.processError("SurveySection.saveSectionOrderCallback", response.data.message, "survery-designer", this);
      }
    },        
    initializeSection() {
      $("html, body").animate({ scrollTop: $(".finish-button").offset().top }, 500);
      SectionService.initializeSection(this.survey.surveyId, this.initializeSectionCallback, this);
    },
    initializeSectionCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.newSection = response.data.data;
        this.mode = "add-section";
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    closeModal(name) {
      if (this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    getSurveyPageContentCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyContentSections = response.data.data;
      }
    },
    reloadSurveyContentSections: function() {
      ContentService.getPageContent(this.survey.name, this.getSurveyPageContentCallBack, this);
    }
  },
  watch: {
    survey: function() {
      this.reloadSurveyContentSections();
      this.newSection.surveyId = this.survey.surveyId;
    }
  },
  created() {},
  mounted() {},
  computed: {
    isAddSectionMode: function() {
      return this.mode == "add-section";
    },
    isSectionOrderMode: function() {
      return this.mode == 'edit-section-order';
    },

  }
};
</script>
