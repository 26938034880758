import $ from "jquery";
import jQuery from "jquery";
import UserService from "./user.service";
import ErrorService from "./error.service";

class GoogleService {
    buildCategory(chartTitle, displayType){
        let category = ""
        if (typeof chartTitle != "undefined"){
            category += chartTitle;
        }
        if (typeof displayType != "undefined" && displayType != ""){
            category += "(" 
            category += displayType 
            category += ")"; 
        }
        return category;
    }
    buildValue(widget, value){
        let returnValue = (typeof widget != "undefined") ? widget+": " : "";
        returnValue += (typeof value != "undefined") ? value : "";
        return returnValue;
    }    
    logBaseEvent(action, category, label, value=0, chartApp){
        let user = UserService.getUser();
        let userId = typeof user != "undefined" ? user.id : 0;
        let userName = typeof user != "undefined" ? user.userName : "";
        try {
            chartApp.$gtag.event(action, {
            event_category: category,
            event_label: label,
            value: value,
            ga_session_id: userId,
            ga_session_number: userName,
            user_id: userId,
            user_name: userName
          });        
        } catch (err) {
            ErrorService.logException("GoogleService.logBaseEvent", err, "google.service", chartApp);
        }
    }
    logClickEvent(category, label, value=0, chartApp){
        let user = UserService.getUser();
        let userId = typeof user != "undefined" ? user.id : 0;
        let userName = typeof user != "undefined" ? user.userName : "";

        try {
            chartApp.$gtag.event("click", {
            event_category: category,
            event_label: label,
            value: value,
            ga_session_id: userId,
            ga_session_number: userName,
            user_id: userId,
            user_name: userName
          });        
        } catch (err) {
            ErrorService.logException("GoogleService.logClickEvent", err, "google.service", chartApp);
        }
    }
    logChartClickEvent(chartTitle, displayType, surveyName, widget, chartApp){
        let category = this.buildCategory(chartTitle, displayType);
        category += " - " + surveyName;
        try {
            this.logClickEvent(category,widget, 0,chartApp);
        } catch (err) {
            ErrorService.logException("GoogleService.logChartClickEvent", err, "google.service", chartApp);
        }
    }
    logActionEvent(action, chartTitle, displayType, surveyName, widget, chartApp){
        let category = this.buildCategory(chartTitle, displayType);
        category += " - " + surveyName;
        try {
            this.logBaseEvent(action, category,widget,0,chartApp);
        } catch (err) {
            ErrorService.logException("GoogleService.logActionEvent", err, "google.service", chartApp);
        }
    }
    logSaveCardEventCriteria(chartTitle, cardName, chartApp){
        let user = UserService.getUser();
        let userId = typeof user != "undefined" ? user.id : 0;
        let userName = typeof user != "undefined" ? user.userName : "";
        try {
            chartApp.$gtag.event("save card", {
                event_category: chartTitle,
                event_label: cardName,
                value: 0,
                ga_session_id: userId,
                ga_session_number: userName,
                user_id: userId,
                user_name: userName
            });
        } catch (err) {
            ErrorService.logException("GoogleService.logSaveCardEventCriteria", err, "google.service", chartApp);
        }
    }
    logAutoCreateGroupsEventCriteria(baseGroupName, chartApp){
        try {
            let user = UserService.getUser();
            let userId = typeof user != "undefined" ? user.id : 0;
            let userName = typeof user != "undefined" ? user.userName : "";
            chartApp.$gtag.event("auto create groups", {
                event_category: baseGroupName,
                event_label: "Auto Create Group",
                value: 0,
                ga_session_id: userId,
                ga_session_number: userName,
                user_id: userId,
                user_name: userName
            });
        } catch (err) {
            ErrorService.logException("GoogleService.logAutoCreateGroupsEventCriteria", err, "google.service", chartApp);
        }
    }
    logResetCriteria(chartTitle, displayType, surveyName, chartApp){
        let user = UserService.getUser();
        let userId = typeof user != "undefined" ? user.id : 0;
        let userName = typeof user != "undefined" ? user.userName : "";

        let category = this.buildCategory(chartTitle, displayType);
        category += " - " + surveyName;
        try {
            chartApp.$gtag.event("click", {
                event_category: category,
                event_label: "Reset",
                value: 0,
                ga_session_id: userId,
                ga_session_number: userName,
                user_id: userId,
                user_name: userName
            });
        } catch (err) {
            ErrorService.logException("GoogleService.logResetCriteria", err, "google.service", chartApp);
        }
    }
    logManageExclusionList(chartTitle, displayType, surveyName, chartApp){
        let category = this.buildCategory(chartTitle, displayType);
        category += " - " + surveyName;
        try {
            let user = UserService.getUser();
            let userId = typeof user != "undefined" ? user.id : 0;
            let userName = typeof user != "undefined" ? user.userName : "";
            chartApp.$gtag.event("click", {
                event_category: category,
                event_label: "Manage Exclusive List",
                value: 0,
                ga_session_id: userId,
                ga_session_number: userName,
                user_id: userId,
                user_name: userName
            });
        } catch (err) {
            ErrorService.logException("GoogleService.logManageExclusionList", err, "google.service", chartApp);
        }
    }
    logGenerateChart(chartTitle, displayType, surveyName, autoGenerateChartRun, chartApp){
        let category = this.buildCategory(chartTitle, displayType);
        category += " - " + surveyName;
        let label = autoGenerateChartRun == true ? "Generate Chart By Saved Card" : "Generate Chart";
        try {
            this.logClickEvent(category, label, 0,chartApp);
        } catch (err) {
            ErrorService.logException("GoogleService.logGenerateChart", err, "google.service", chartApp);
        }
    }
}

export default new GoogleService();
