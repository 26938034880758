<template>
  <div>
    <div id="chart-container" class="by-value">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-emotional-value-e-chart" class="chart-row">
        <profile-chart-header
          :title="'Value Drivers'"
          :display="this.$parent.getDisplayView() && getDisplayView()"
          :input-display-number-of-responses="displayNumberOfResponsesForSecondChart"
          @set-display-number-of-responses="setDisplayNumberOfResponsesForValueDriver"
        />   
        <profile-chart ref="eChartValueDriver"
              :e-chart-mini-id="'profile-value-driver-chart-mini'"
              :e-chart-id="'profile-value-driver-chart'"
              :input-report-type-name="'Chart - Value Drivers'"
              :modal-id="'value-drivers-chart'"
              :is-saved-card="$parent.clientChart.clientChartId != 0" 
              :input-client-chart="this.$parent.clientChart"
              @save-table="saveProfileDataTable"
              @save-criteria="buildClientChart"/>        
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="valueEmotionalTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
            <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 490px"/>
        </div>
        <chart-data-table
          ref="emotionalDataTable"
          :is-saved-card="$parent.clientChart.clientChartId != 0" 
          :chart-data="this.valueEmotionalTable.data"
          :data-container-id="valueEmotionalTable.tableContainerId"
          :container-id="'value_emotional_needs_chart'"
          :column-defs="this.chart.columnDefsEmotional"
          :table-class="'table display responsive row-border table-hover'"
          :table-width="'auto'"
          :table-title="this.valueEmotionalTable.title"
          :content-list="this.contentList"          
          :report-page-type-name="'Table - Value Drivers'"
          :insight-modal-id="'value-drivers-table'"
        ></chart-data-table>
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-functional-value-e-chart" class="chart-row">
        <profile-chart-header
              :title="'Value Components'"
              :display="this.$parent.getDisplayView() && getDisplayView()"
              :input-display-number-of-responses="displayNumberOfResponses"
              @set-display-number-of-responses="setDisplayNumberOfResponsesForValueComponents"
            />           
        <profile-chart ref="eChartValueComponents"
            :e-chart-mini-id="'profile-value-chart-mini'"
            :e-chart-id="'profile-value-chart'"
            :input-report-type-name="'Chart - Value Components'"
            :modal-id="'value-components-chart'"
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>        
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="valueFunctionalTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
            <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 490px"/>
        </div>
        <chart-data-table
          ref="functionalDataTable"
          v-show="this.$parent.getDisplayView() && getDisplayView()"
          :chart-data="this.valueFunctionalTable.data"
          :display-direction="'vertical'"
          :is-saved-card="$parent.clientChart.clientChartId != 0" 
          :data-container-id="valueFunctionalTable.tableContainerId"
          :container-id="'value_functional_needs_chart'"
          :column-defs="this.chart.columnDefsFunctional"
          :table-class="'table display responsive row-border table-hover'"
          :table-width="'auto'"
          :content-list="this.contentList"
          :table-title="this.valueFunctionalTable.title"
          :report-page-type-name="'Table - Value Components'"
          :insight-modal-id="'value-components-table'"
        ></chart-data-table>
      </div>
    </div>
    
    <insights
      ref="insightsFunctionalChart"
      :modal-name="'value-components-chart'"
      :report-page-type-name="'Chart - Value Components'"
      :entry-page-content="this.pageContentForReport"
      :page-content="this.pageContent"
      @saveAllReportPages="saveAllReportPages"
      @saveReportPage="$parent.updateReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsFunctionalTable"
      :modal-name="'value-components-table'"
      :report-page-type-name="'Table - Value Components'"
      :entry-page-content="this.pageContentForReport"
      @saveAllReportPages="saveAllReportPages"
      @saveReportPage="$parent.updateReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsEmotionalChart"
      :modal-name="'value-drivers-chart'"
      :report-page-type-name="'Chart - Value Drivers'"
      :entry-page-content="this.pageContentForReport"
      @saveAllReportPages="saveAllReportPages"
      @saveReportPage="$parent.updateReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsEmotionalTable"
      :modal-name="'value-drivers-table'"
      :report-page-type-name="'Table - Value Drivers'"
      :entry-page-content="this.pageContentForReport"
      @saveAllReportPages="saveAllReportPages"
      @saveReportPage="$parent.updateReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ProfileChart from "../../../views/echarts/ProfileChart.vue";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";
import ProfileService from "../../../services/echarts/profile.service";

import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import NeedsService from "../../../services/needs.service";
import TableService from "../../../services/table.service";
import ReportService from "../../../services/report.service";

export default {
  name: "ByValue",
  mixins: [ChartCompanyMixins],
  components: {  ProfileChart, ProfileChartHeader},
  data() {
    return {
      displayType: "value",
      displayNumberOfResponses: false,
      displayNumberOfResponsesForSecondChart: false,
      eChartDataForValueDriver: {},
      valueFunctionalTable: {
        id: "",
        data: {},
        tableContainerId: "value_functional_needs_data_container",
        title:
          "<div class='title'><h4 class='text-primary'>Value Components</h4><div class='chart-title functional'><div class='chart-sub-title'><span>Importance/Satisfaction Gap</span> vs. <span>Pricing Power</span></div></div></div>"
      },
      valueEmotionalTable: {
        id: "",
        tableContainerId: "value_emotional_needs_data_container",
        data: {},
        title:
          "<div class='title'><h4 class='text-primary'>Value Drivers</h4><div class='chart-title emotional'><div class='chart-sub-title'><span>Ideal/Company Gap</span> vs. <span>Pricing Power</span></div></div></div>"
      },
      drivers: [
        {
          name: "",
          weight: "",
          emotionalNeedName1: "",
          emotionalNeedName2: ""
        },
        {
          name: "",
          weight: "",
          emotionalNeedName1: "",
          emotionalNeedName2: ""
        },
        {
          name: "",
          weight: "",
          emotionalNeedName1: "",
          emotionalNeedName2: ""
        },
        {
          name: "",
          weight: "",
          emotionalNeedName1: "",
          emotionalNeedName2: ""
        },
        {
          name: "",
          weight: "",
          emotionalNeedName1: "",
          emotionalNeedName2: ""
        }
      ],
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    surveyCode: function() {
      return this.$parent.$data.survey.surveyCode;
    },    
  },
  mounted() {},
  created() {},
  methods: {
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Value Components") {
        this.$refs.insightsFunctionalChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsFunctionalChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Value Components") {
        this.$refs.insightsFunctionalTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsFunctionalTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Chart - Value Drivers") {
        this.$refs.insightsEmotionalChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsEmotionalChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Value Drivers") {
        this.$refs.insightsEmotionalTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsEmotionalTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } 
    },
    async processChartData() {
      try {
        if (typeof this.eChartCriteria != "undefined" && typeof this.eChartCriteria.displayNumberOfResponsesForSecondChart != "undefined") {
          this.displayNumberOfResponsesForSecondChart = this.eChartCriteria.displayNumberOfResponsesForSecondChart;
        }
        if (typeof this.eChartCriteria != "undefined" && typeof this.eChartCriteria.displayNumberOfResponses != "undefined") {
          this.displayNumberOfResponses = this.eChartCriteria.displayNumberOfResponses;
        }
        this.chart = ChartService.getChartAttributes("Profile By Value");
        for (let i = 0; i < this.chartData.length; i++) {
          let needsData = JSON.parse(JSON.stringify(this.chartData[i]));
          let otherData = needsData["otherData"];
          let needsList = otherData["needsList"];
          let driverList = otherData["driverList"];
          let companyColorList = otherData["companyColorList"];
          TableService.setCompanyColorList(companyColorList);
          let containerId = needsData["containerId"];
          this.table.id = containerId;
          
          if (containerId === "value_functional_needs_chart") {
            let breakPointList = otherData["breakPointList"];
            ChartService.setVerticalLineBreakPoints(breakPointList);
            NeedsService.setFunctionalNeedsList(needsList);

            for (let index = 0; index < driverList.length; index++) {
              this.drivers[index].name = driverList[index].name;
              this.drivers[index].importanceRank = driverList[index].importanceRank;
              this.drivers[index].intensityRank = driverList[index].intensityRank;
              this.drivers[index].dissonanceRank = driverList[index].dissonanceRank;
              let weight = driverList[index].weight;
              if (weight != null && !isNaN(weight)) {
                let finalWeight = weight * 100;
                this.drivers[index].weight = Number(finalWeight).toFixed(1) + "%";
              } else {
                this.drivers[index].weight = "--";
              }
              this.drivers[index].performanceAssessment = JSON.parse(JSON.stringify(driverList[index].performanceAssessment));
            }
            this.valueFunctionalTable.id = containerId;
            this.valueFunctionalTable.data = JSON.parse(JSON.stringify(needsData));
            this.$parent.setChartResults(needsData.companyList);
            this.$parent.$data.criteriaResultsList = AdminService.removeByName(needsData.criteriaList, "Competitor");
          } else if (containerId === "value_emotional_needs_chart") {
            let experienceComponentsList = otherData["experienceComponentsList"];
            let labelIndex = 1;
            NeedsService.setEmotionalNeedsList(needsList);
            this.valueEmotionalTable.id = containerId;
            let copyOfChartData = JSON.parse(JSON.stringify(needsData));

            for (let indexRows = 0; indexRows < needsData.rows.length; indexRows++) {
              let copyOfChartDataRow = copyOfChartData.rows[indexRows].c;
              let originalName = copyOfChartDataRow[labelIndex].v;
              let copyValue = copyOfChartDataRow[labelIndex].v.split(",");
              if (typeof copyValue != "undefined") {
                copyOfChartDataRow[labelIndex].v = copyValue[0] + " (vs. " + copyValue[1] + ")";
                NeedsService.replaceNeedName(originalName, copyOfChartDataRow[labelIndex].v, "emotional");
                this.drivers[indexRows].emotionalNeedName1 = copyValue[1];
                this.drivers[indexRows].emotionalNeedName2 = copyValue[0];
                this.drivers[indexRows].experienceComponents = JSON.parse(JSON.stringify(experienceComponentsList[indexRows]));
              }
            }
            this.valueEmotionalTable.data = copyOfChartData;
          }
        }
        this.$parent.valueMapData.data = this.drivers;
        this.$parent.valueMapData.title = this.$parent.survey.name + " Value Map";
        this.$root.$emit("check-number-of-responses");          
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria);
        this.parseProfileDataForValue(JSON.parse(JSON.stringify(this.chartData)));
        this.eChartCriteria.eChartType = "stacked-bar-vertical";
        this.$refs.eChartValueComponents.processChart(true,"value",this.eChartData,this.eChartCriteria,this.$parent.showPricingPower);
        $("#cp-by-emotional-value-e-chart").addClass("chart-wrapper");
        $("#cp-by-functional-value-e-chart").addClass("chart-wrapper");

        this.parseProfileDataForValueDriver(JSON.parse(JSON.stringify(this.chartData)));
        let eChartCriteria = JSON.parse(JSON.stringify(this.eChartCriteria));
        eChartCriteria.eChartType = "bar-horizontal";
        this.$refs.eChartValueDriver.processChart(true,"value-driver",this.eChartDataForValueDriver,eChartCriteria,false);
      } catch (err) {
        ErrorService.processException("ByValue.displayChartCallback", err, "chart", this);
      }
    },
    setDisplayNumberOfResponsesForValueDriver(inputDisplayNumberOfResponses){
      this.displayNumberOfResponsesForSecondChart = inputDisplayNumberOfResponses;
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$parent.$refs.chartCriteria.eChartCriteria.displayNumberOfResponsesForSecondChart = inputDisplayNumberOfResponses;
      let eChartCriteria = JSON.parse(JSON.stringify(this.eChartCriteria));
      eChartCriteria.eChartType = "bar-horizontal";
      this.$refs.eChartValueDriver.processChart(this.getDisplayView(),"value-driver",this.eChartDataForValueDriver,eChartCriteria,false);
    },
    setDisplayNumberOfResponsesForValueComponents(inputDisplayNumberOfResponses){
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$parent.$refs.chartCriteria.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$refs.eChartValueComponents.processChart(this.getDisplayView(),"value",this.eChartData,this.eChartCriteria,this.displayPricingPower);
    },
    parseProfileDataForValue: function(inputData){
      let itemsToRemove = {
          lastColumns: 2,
          firstColumns: 2,
          gapColumn: 1,
        };
        let data = {};
        let chart1Data = inputData[0];
        data = ProfileService.parseData(1,chart1Data,data,itemsToRemove);
        data = ProfileService.parseLegendAndColorData(chart1Data,data);
        this.eChartData = JSON.parse(JSON.stringify(data));
    },    
    parseProfileDataForValueDriver: function(inputData){
      let itemsToRemove = {
          lastColumns: 1,
          firstColumns: 1,
          gapColumn: 1,
        };
        let data = {};
        let chart1Data = inputData[1];
        data = ProfileService.parseData(1,chart1Data,data,itemsToRemove);
        data = ProfileService.parseLegendAndColorData(chart1Data,data);
        data = ProfileService.parseDriverListData(inputData[0],data);
        this.eChartDataForValueDriver = JSON.parse(JSON.stringify(data));
    },    
    async saveProfileDataTable() {
      let vm = this;
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        setTimeout(function() {
            ReportService.saveReportPage(vm.getReportTableContent("value_functional_needs_chart"), "Table - Value Components", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
            ReportService.saveReportPage(vm.getReportTableContent("value_emotional_needs_chart"), "Table - Value Drivers", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
            ReportService.saveReportTableFile(vm.$refs.functionalDataTable.getTableData(), "Table - Value Components", vm.$parent.clientChart, vm.saveReportTableFileCallback, vm);
            ReportService.saveReportTableFile(vm.$refs.emotionalDataTable.getTableData(), "Table - Value Drivers", vm.$parent.clientChart, vm.saveReportTableFileCallback, vm);
        }, 1500);    
      }
    },    
    saveAllReportPages() {
      this.saveProfileDataTable();
    },
  },
  watch: {
    surveyCode: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChartValueComponents.clear();} catch(e){}
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChartValueDriver.clear();} catch(e){}
    },

    chartData: function() {
      if (this.$parent.$refs.chartCriteria.$data.displayType == "value") {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
