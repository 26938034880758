import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import UserService from "./user.service";
import AdminService from "./admin.service";

const BASE_URL = AdminService.getBaseUrl();
const API_CONTENT_URL = BASE_URL + "/api/v1/content";
class ContentService {
  getShareButtonTooltip(showOrHide){
    return {
      placement: "right",
      content: "Click to copy the invitation link for respondents to take this survey",
      trigger: "hover",
      delay: { show: 300, hide: 300 },
      classes: "share-button-tooltip-inner"
    }
  }
  getWizardTooltip(showOrHide){
      return {
        placement: "left",
        content: "Click the wizard to show/hide your task steps",
        trigger: "hover",
        delay: { show: 500, hide: 500 },
        classes: "wizard-tooltip-inner"
      }
  }
  async getPageContentByPage(pageName, callback, chartApp) {
    if (typeof pageName != "undefined") {
      let contentList = this.getContentFromStorage();
      let content = contentList.filter(contentObject => contentObject.pageName === pageName); 
      if (typeof content != "undefined" && content.length > 0) {
        let responseObject = {data : {data: content[0].content,statusCode: 200}};
        callback(responseObject);
      } else {      
        const params = new URLSearchParams();
        params.append("pageName", pageName);
        params.append("userId", 0);
        await axios
          .post(API_CONTENT_URL + "/get-content-by-page", params, { headers: await authHeader() })
          .then(response => {
            this.addContentToStorage(pageName,response.data.data);
            callback(response);
          }).catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ContentService.getPageContent", error, "admin", chartApp);
          });
      }
    }
  }
  async deleteContent(contentId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("contentId", contentId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CONTENT_URL + "/delete-content", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ContentService.deleteContent", error, "admin", chartApp);
      });
  }
  async saveContent(formContentObject, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("contentObject", JSON.stringify(formContentObject));
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CONTENT_URL + "/save-content", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("EditContent.saveContent", error, "admin", chartApp);
      });
  }  
  removeContentFromStorage(pageName) {
    let contentList = this.getContentFromStorage();
    let match = contentList.find(contentObject => contentObject.pageName === pageName);
    if (typeof match != "undefined") {
      let index = contentList.indexOf(match);
      contentList.splice(index, 1);
      localStorage.setItem("content", JSON.stringify(contentList));
    }   
  }
  getContentFromStorage() {
    if (localStorage.getItem("content") != null) {
      return JSON.parse(localStorage.getItem("content"));
    } else {
      return [];
    }
  }
  addContentToStorage(pageName, content) {
    let contentList = this.getContentFromStorage();
    let contentIndex = contentList.findIndex(contentObject => contentObject.pageName === pageName);
    if (contentIndex > -1) {
      contentList[contentIndex] = content;
    } else {
      contentList.push({ pageName: pageName, content: content });
    }
    localStorage.setItem("content", JSON.stringify(contentList));
  }
  async getPageContent(pageName, callback, chartApp) {
    let contentList = this.getContentFromStorage();
    let content = contentList.filter(content => content.pageName === pageName); 
    if (typeof content != "undefined" && content.length > 0) {
      let responseObject = {data : {data: content[0].content,statusCode: 200}};
      callback(responseObject);
    } else {
      let userId = UserService.getUserId();
      if (typeof pageName != "undefined" && typeof userId != "undefined") {
        const params = new URLSearchParams();
        params.append("pageName", pageName);
        params.append("userId", userId);
        await axios
          .post(API_CONTENT_URL + "/get-content-by-page", params, { headers: await authHeader() })
          .then(response => {
            this.addContentToStorage(pageName,response.data.data);
            callback(response)
          })
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ContentService.getPageContent", error, "admin", chartApp);
          });
      }
    }
  }
  async getContentById(title, contentId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("contentId", contentId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CONTENT_URL + "/get-content-by-id", params, { headers: await authHeader() })
      .then(response => callback(response, title))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ContentService.getContentById", error, "admin", chartApp);
      });
  }
  getContentConfiguration() {
    return {
      direction: "ltr",
      height: 350,
      tabsize: 2,
      dialogsInBody: true,
      toolbar: [
        ["style", ["fontname", "fontsize", "color", "bold", "italic", "underline", "strikethrough", "clear"]],
        ["Paragraph style", ["style", "ol", "ul", "paragraph", "height"]],
        ["Insert", ["picture", "link", "table", "hr"]],
        ["Misc", ["fullscreen", "codeview", "undo", "redo", "help"]],
        ["mybutton", ["hello"]]
      ]
    };
  }
}
export default new ContentService();
