<template>
  <div id="map">
    <div class="value-map">
      <div class="header">
        <div id="value-map-header" class="labels"><label class="pl-2 pr-2">Alt Colors</label><toggle-button v-model="useAltColors" color="#1c408e" /></div>
        <div class="title align-self-center">
          <span>{{ valueMapData.title }}</span>
          <button type="button" class="close" @click="closeModal">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div v-if="isMoat" class="header">
        <div class="labels"></div>
        <div class="moat-header">
          <div class="breakway-moats moat-header-border">
            <div class="moat-header-section-header">Core Moats</div>
          </div>
          <div class="first-mover-moats moat-header-border">
            <div class="moat-header-section-header">Early-Mover Moats</div>
          </div>
          <div class="promise-moats moat-header-border">
            <div class="moat-header-section-header">Promise Moats</div>
          </div>
        </div>
      </div>
      <div :class="getMapTypeClass()">
        <value-map-labels v-if="isValue || isNeed" :use-alt-colors="useAltColors"></value-map-labels>
        <moat-map-labels v-if="isMoat" :use-alt-colors="useAltColors"></moat-map-labels>
        <div class="drivers">
          <div
            :class="addDriverRight(idxValueMapEntry) ? ' driver driver-right' : 'driver'"
            v-for="(valueMapEntry, idxValueMapEntry) in valueMapData.data"
            v-bind:key="idxValueMapEntry"
          >
            <div class="value-drivers">
              <div v-if="isMoat" :class="getNameClass(idxValueMapEntry, false) + ' moat-logo'">
                <div v-if="idxValueMapEntry == 0">
                  <img src="../../assets/img/company-logos/m1-Dell.png" alt="Dell" style="margin-top: 0px; !important" />
                </div>
                <img v-if="idxValueMapEntry == 1" src="../../assets/img/company-logos/m2-Benz.png" alt="Benx" />
                <img v-if="idxValueMapEntry == 2" src="../../assets/img/company-logos/m3-Disney.png" alt="Disney" />
                <img v-if="idxValueMapEntry == 3" src="../../assets/img/company-logos/m4-Apple.png" alt="Apple" />

                <img v-if="idxValueMapEntry == 4" src="../../assets/img/company-logos/m5-Starbucks.png" alt="Starbucks" />
                <img v-if="idxValueMapEntry == 5" src="../../assets/img/company-logos/m6-Walgreens.png" alt="Walgreens" />
                <img v-if="idxValueMapEntry == 6" src="../../assets/img/company-logos/m7-Microsoft.png" alt="Microsoft" />
                <img v-if="idxValueMapEntry == 7" src="../../assets/img/company-logos/m8-ebay.png" alt="eBay" />
                <img v-if="idxValueMapEntry == 8" src="../../assets/img/company-logos/m9-Lululemon.png" alt="Lulu" />
                <img v-if="idxValueMapEntry == 9" src="../../assets/img/company-logos/m10-Amazon.png" alt="Amazon" />
                <img v-if="idxValueMapEntry == 10" src="../../assets/img/company-logos/m11-Coca-Cola.png" alt="Coke" />
              </div>
              <div :class="getNameClass(idxValueMapEntry, true)">
                <span>{{ valueMapEntry.name }}</span>
              </div>
              <div v-if="!isMoat && !isNeed" class="emotional-name">
                <span>{{ valueMapEntry.experienceComponents.emotionalName }}</span>
              </div>
              <div v-if="!isMoat && !isNeed" class="filler"></div>
            </div>
            <div class="experience-components">
              <div class="importance-rank">
                <span>{{ getImportanceGapRank(valueMapEntry, idxValueMapEntry) }}</span>
              </div>
              <div v-if="!isNeed" class="intensity-rank">
                <span>{{ getIntensityGapRank(valueMapEntry) }}</span>
              </div>
              <div class="dissonance-rank">
                <span>{{ getDissonanceGapRank(valueMapEntry) }}</span>
              </div>
              <div class="pricing-power-rank" v-if="isNeed">
                <span>{{ getPricingPowerRank(valueMapEntry) }}</span>
              </div>
            </div>
            <div class="performance-assessment">
              <div
                v-for="(performanceAssessmentEntry, idxPerformanceAssessmentEntry) in valueMapEntry.performanceAssessment"
                v-bind:key="idxPerformanceAssessmentEntry"
                :class="getClass(performanceAssessmentEntry)"
              >
                <span>{{ performanceAssessmentEntry.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div role="group" class="chart-buttons btn-group-horizontal">
          <button type="button" class="btn btn-primary ml-4" @click="printMap">Print Map</button>
          <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintAndCopyService from "../../services/print.and.copy.service";
import GoogleService from  "../../services/google.service";

import ValueMapLabels from "./ValueMapLabels.vue";
import MoatMapLabels from "./MoatMapLabels.vue";

import Vue from "vue";
import { ToggleButton } from "vue-js-toggle-button";

Vue.component("ToggleButton", ToggleButton);

export default {
  name: "ValueMap",
  mixins: [],
  components: { MoatMapLabels, ValueMapLabels, ToggleButton },
  data() {
    return {
      useAltColors: false,
      gapSizeGroup: 1
    };
  },
  props: {
    mapType: {
      type: String,
      default: ""
    },
    valueMapData: {
      type: Object,
      default: function() {
        return {
          title: "",
          data: []
        };
      }
    }
  },
  computed: {
    isMoat: function() {
      return this.mapType == "moat";
    },
    isValue: function() {
      return this.mapType == "value";
    },
    isNeed: function() {
      return this.mapType == "need";
    }
  },
  mounted() {},
  created() {},
  methods: {
    printMap() {
      GoogleService.logClickEvent("Print Map", this.mapType, 0, this);
      PrintAndCopyService.printChartMap();
    },
    addDriverRight(idxValueMapEntry) {
      return idxValueMapEntry == 3 || idxValueMapEntry == 7 || idxValueMapEntry == 10;
    },
    closeModal: function() {
      this.$emit('closeModal');
    },
    getPricingPowerRank: function(valueMapEntry) {
      if (this.isNeed && valueMapEntry.averagePerformanceAssessment.pricingPowerRankGroup == 0) {
        return "--";
      } else if (this.isNeed) {
        return valueMapEntry.averagePerformanceAssessment.pricingPowerRankGroup;
      }
      return valueMapEntry.pricingPowerRankGroup;
    },
    getImportanceGapRank: function(valueMapEntry, idxValueMapEntry) {
      if (this.isNeed && valueMapEntry.name == "Experience") {
        return "--";
      } else if (this.isNeed && valueMapEntry.averageExperienceComponent.importanceRank == 0) {
        return "--";
      } else if (this.isNeed) {
        return valueMapEntry.averageExperienceComponent.importanceRank;
      } else if (this.isMoat && valueMapEntry.name.includes("Level")) {
        return "--";
      } else if (this.isMoat) {
        return valueMapEntry.importanceRank;
      }
      return idxValueMapEntry + 1;
    },
    getIntensityGapRank: function(valueMapEntry) {
      if (this.isValue) {
        return valueMapEntry.experienceComponents.intensityGapRank;
      } else if (this.isMoat && typeof valueMapEntry.experienceComponents != "undefined" && valueMapEntry.experienceComponents.length > 0) {
        return valueMapEntry.experienceComponents[0].intensityGapRank;
      } else if (this.isNeed && valueMapEntry.averageExperienceComponent.intensityGapRank != 0) {
        return valueMapEntry.averageExperienceComponent.intensityGapRank;
      }
      return "--";
    },
    getDissonanceGapRank: function(valueMapEntry) {
      if (this.isValue) {
        return valueMapEntry.experienceComponents.dissonanceGapRank;
      } else if (this.isMoat) {
        return valueMapEntry.dissonanceGapRank;
      } else if (this.isNeed && valueMapEntry.averageExperienceComponent.dissonanceGapRank != 0) {
        return valueMapEntry.averageExperienceComponent.dissonanceGapRank;
      }
      return "--";
    },
    getMapTypeClass: function() {
      return "map-body " + this.mapType;
    },
    getNameClass: function(idxValueMapEntry, excludeMoatType) {
      let className = "";
      let moatType1 = [8, 12];
      let moatType2 = [7, 11, 13];
      let moatType3 = [6, 9, 10, 14];
      let moatType4 = [2, 4, 5, 15];
      let moatType5 = [1, 3, 16];
      if (this.isMoat) {
        let headerNumber = idxValueMapEntry + 1;
        className += "header-";
        className += idxValueMapEntry;
        className += " moat-type";
        if (excludeMoatType == true && moatType5.includes(headerNumber)) {
          className += " moat-type-5 ";
        } else if (excludeMoatType == true && moatType4.includes(headerNumber)) {
          className += " moat-type-4 ";
        } else if (excludeMoatType == true && moatType3.includes(headerNumber)) {
          className += " moat-type-3 ";
        } else if (excludeMoatType == true && moatType2.includes(headerNumber)) {
          className += " moat-type-2 ";
        } else if (excludeMoatType == true && moatType1.includes(headerNumber)) {
          className += " moat-type-1 ";
        }
      } else {
        className = "driver-name";
      }
      return className;
    },
    getClass: function(performanceAssessmentEntry) {
      let pricingPowerRankGroup = this.isNeed ? performanceAssessmentEntry.pricingPowerRankGroup : performanceAssessmentEntry.pricingPowerRankGroup;

      let pricingPowerClass = " pricing-power-tier-" + pricingPowerRankGroup;
      let gapGroupClass = this.useAltColors ? "alt-" : "";
      gapGroupClass += "gap-group-tier-" + performanceAssessmentEntry.gapGroup;
      return "d-flex " + gapGroupClass + pricingPowerClass;
    }
  },
  watch: {}
};
</script>
