import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import UserService from "./user.service";
import AdminService from "./admin.service";
import $, { isEmptyObject } from "jquery";
import jQuery from "jquery";

const BASE_URL = AdminService.getBaseUrl();
const API_CONTENT_URL = BASE_URL + "/api/v1/content";
const SMART_CENTER_API_CONTENT_URL = BASE_URL + "/api/v1/smartcenter";

class SmartCenterService {
  SMART_CENTER_URL = "https://www.wratings.com/sgu-intro";
  SMART_CENTER_LOGIN_PARAMETERS = "?option=oauthredirect&app_name=Log In";
  getSmartCenterMapping() {
    let smartCenterMapping = new Array();
    if (localStorage.getItem("sc-external-mapping") != null) {
      smartCenterMapping = JSON.parse(localStorage.getItem("sc-external-mapping"));
    }
    return smartCenterMapping;
  }
  saveSmartCenterMapping(mapping) {
    let currentSCExternalMapping = this.getSmartCenterMapping();
    mapping.forEach(newMapping => {
      let existingMapping = currentSCExternalMapping.find(x => x.location == newMapping.location && x.externalPageTitle == newMapping.externalPageTitle);
      if (typeof existingMapping != "undefined") {
        $.extend(true, existingMapping, newMapping);
      } else {
        currentSCExternalMapping.push(newMapping);
      }
    });
    localStorage.setItem("sc-external-mapping", JSON.stringify(currentSCExternalMapping));
  }
  getSmartCenterLink(location, page) {
    let scCurrentMapping = this.getSmartCenterMapping();
    if (!$.isEmptyObject(scCurrentMapping)) {
      let searchableSmartCenterPage = scCurrentMapping.find(x => x.location == location && x.externalPageTitle == page);
      if (typeof searchableSmartCenterPage != "undefined") {
        return searchableSmartCenterPage;
      }
    }
    return {};
  }
  async getAllSmartCenterPages(callback, isSmartCenterEnabled, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }

    if (localStorage.getItem("sc-external-mapping") == null) {
      const params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      params.append("isSmartCenterEnabled", isSmartCenterEnabled);
      await axios
        .post(SMART_CENTER_API_CONTENT_URL + "/get-all-smart-center-pages", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentService.getAllSmartCenterPages", error, "admin", chartApp);
        });
    }
  }
  async getSmartCenterPagesByPage(externalPageTitle, callback, isSmartCenterEnabled, chartApp) {
    if (typeof externalPageTitle != "undefined") {
      const params = new URLSearchParams();
      params.append("externalPageTitle", externalPageTitle);
      params.append("userId", UserService.getUserId());
      params.append("isSmartCenterEnabled", isSmartCenterEnabled);
      await axios
        .post(SMART_CENTER_API_CONTENT_URL + "/get-smart-center-pages-by-page", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentService.getSmartCenterPagesByPage", error, "admin", chartApp);
        });
    }
  }
  buildSmartCenterLink(location, page) {
    let link = "";
    let smartCenterMappingObject = this.getSmartCenterLink(location, page);
    if (!jQuery.isEmptyObject(smartCenterMappingObject)) {
      link += this.SMART_CENTER_URL;
      link += smartCenterMappingObject.pageUrl;
      link += this.SMART_CENTER_LOGIN_PARAMETERS;
      if (smartCenterMappingObject.pageAnchor != "") {
        link += "#";
        link += smartCenterMappingObject.pageAnchor;
      }
    }
    return link;
  }
}
export default new SmartCenterService();
