<template>
  <div class="saved-cards" style="max-width: 100%; min-height: 210px;">
    <saved-cards-set :view-type="viewType" :set-name="clientName" set-type="survey" :original-saved-cards="this.clientChartsBySurvey" :entry-client-object="entryClientObject"/>
    <saved-cards-set :view-type="viewType" v-if="enableSecondRowOfSavedCharts && displaySecondRowofSavedCharts" set-type="organization" :set-name="clientName" :original-saved-cards="this.clientChartsByClient" :entry-client-object="entryClientObject"/>
  </div>
</template>

<script>
import $ from "jquery";
import ReportService from "../services/report.service";
import ClientService from "../services/client.service";
import SavedCardsSet from "../components/SavedCardsSet.vue";
import ClientChartService from "../services/client.chart.service";
import DataTableMixins from "../mixins/DataTableMixins.vue";

export default {
  name: "SavedCards",
  mixins: [DataTableMixins],
  components: {
    SavedCardsSet
  },
  data() {
    return {
      clientChartIds: []
    };
  },
  props: {
    entrySurveyId: {
      type: Number,
      default: 0
    },
    entrySurveyName: {
      type: String,
      default: ""
    },
    viewType: {
      type: String,
      default: "cards"
    },
    enableSecondRowOfSavedCharts: {
      type: Boolean,
      default: true
    },
    displayDashboardCards: {
      type: Boolean,
      default: true
    },
    entryClientObject: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    clientName: function() {
      return this.entryClientObject.name;
    },
    clientChartsBySurvey: function() {
      let originalClientCharts = this.entryClientObject.clientCharts;
      let surveyIdToCompare = this.entrySurveyId;
      return ClientChartService.getClientChartsBySurvey(originalClientCharts, surveyIdToCompare, this.displayDashboardCards);
    },
    clientChartsByClient: function() {
      let originalClientCharts = this.entryClientObject.clientCharts;
      if (typeof originalClientCharts != "undefined") {
        if (this.displayDashboardCards){
          return originalClientCharts;
        }
        return originalClientCharts.filter(clientChartInput => ReportService.isSavedDashboardCard(clientChartInput.chartTypeId) == false);
      }
      return {};
    },
    displaySecondRowofSavedCharts: function() {
      let surveyCodes = this.entryClientObject.surveyCodes;
      if (typeof surveyCodes != "undefined") {
        return surveyCodes.length > 1;
      }
      return false;
    }
  },
  mounted() {},
  created() {},
  methods: {
    resetClientCharts(result) {
      let match = this.entryClientObject.clientCharts.find(x => x.clientChartId == result);
      if (typeof match !== "undefined") {
        let localEntryClientObject = this.entryClientObject;
        localEntryClientObject.clientCharts.splice($.inArray(match, this.entryClientObject.clientCharts), 1);
        ClientService.saveClient(localEntryClientObject);
        this.$root.setClientObject(localEntryClientObject);
      }
    }
  },
  watch: {}
};
</script>
