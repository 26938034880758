<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <respondent-menu></respondent-menu>
    <div v-if="!permissionToView" class="no-permission text-center">You do not have permission to view this page</div>   
    <div v-else>
      <div class="pl-3">
        To modify collection dates, click
        <router-link :to="{ name: 'respondent-waves' }">here</router-link>
      </div>
    </div>   
    <download-survey-data :input-download-sets="downloadSets" :input-disable-download-button="disableDownloadButton"/>
    <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Download</th>
            <th>Client Name</th>
            <th>Survey Name</th>
            <th>Total Opens</th>
            <th>Total Complete</th>
            <th>Total Partial</th>
            <th>Participation Rate</th>
            <th>Average Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
    </table>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";

import RespondentMixins from "../../mixins/RespondentMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import DownloadSurveyData from "../../components/DownloadSurveyData.vue";

import SurveySummaryService from "../../services/survey.summary.service";

export default {
  name: "RespondentDownload",
  mixins: [RespondentMixins, DataTableMixins],
  components: {DownloadSurveyData},
  props: {},
  data() {
    return {
      table: {
        id: "respondent-download-data",
        configuration: {
          dom: '<"top"if>rt<"bottom"lp><"clear">',
          searching: false,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          responsive: false,
          ordering: true,
          keys: true,
          bSort: false
        },
        columns: SurveySummaryService.getRespondentDownloadTableColumnData()
      },
      pageNumber: 5,
      clientTimePeriods: [],
      downloadSets: [],
      disableDownloadButton: false,
      title: "Download"
    };
  },
  methods: {
    async getTableData() {
      if (!this.isW5OUser){
        this.$root.processServerRequest();
        SurveySummaryService.getSummaryDataBySurveyId(this.getTableDataCallback, this);
      }
    },
    loadData: function() {
      this.downloadSets = [];
      this.clientTimePeriods = [];
      this.getTableData();
      this.table.configuration.order = [
        [2, "desc"],
        [8, "desc"]
      ];
    },
  },
  created() {},
  mounted() {
    let vm = this;
    $("#respondent-download-data tbody").on("click", 'input[type="checkbox"]', function(e, dt, type, indexes) {
      var $row = $(this).closest("tr");
      var data = vm.table.dataTable.row($row).data();
      if (!jQuery.isEmptyObject(data)) {
        if (e.target.checked) {
          let downloadSet = new Object();
          downloadSet.id = data.id;
          downloadSet.surveyId = data.surveyId;
          vm.downloadSets.push(downloadSet);
          vm.clientTimePeriods.push(data.id);
          vm.disableDownloadButton = true;
        } else {
          let removedItem = vm.clientTimePeriods.find(x => x == data.id);
          if (typeof removedItem !== "undefined") {
            vm.clientTimePeriods.splice($.inArray(removedItem, vm.clientTimePeriods), 1);
            vm.downloadSets.splice($.inArray(removedItem, vm.downloadSets), 1);
            if (vm.downloadSets.length == 0) {
              vm.disableDownloadButton = false;
            }
          }
        }
      }
    });
  },
  watch: {},
  computed: {}
};
</script>
