<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>
      {{ chart.title }}
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
            <font-awesome-icon v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">
          Click the Profile Type and Time Period for 
          the analytics you’d like to generate. Click 
          the By drop-down menu to compare your 
          company’s results to other surveys, time 
          periods or saved Cards (segments). Note: 
          To modify Waves, contact your wRatings 
          Advisor. Click the box to include partial 
          responses, which  are respondents that 
          did not complete all the survey fields.
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
          Based on the By drop-down menu selected in Step 1, click 
          the profiles to compare. With Survey Groups/Surveys and 
          Saved Cards, the current profile will be included when 
          generating the Match Profile. With Time Periods, click two or 
          more periods to compare.

          Note: To create Survey Groups, you must have Advanced 
          User access. Contact your wRatings Advisor to modify your 
          Groups.
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Segments are created through your survey 
            questions. Click any segment criteria to select 
            which respondents to include in your Profile. 
            Selecting no criteria includes all respondents for 
            that question. Segments for NPS, Location and 
            Word are included as defaults.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>  
          <div class="wizard-text">
            Click Generate Chart to build your Profile. Click 
            Reset to start the build process from scratch.
          </div>      
        </div>
      </div>
    </div>
    <div class="chart-app">
      <chart-criteria ref="chartCriteria" display-type-label="Profile Types"  :show-match-type="true" :use-chart-type-card="true" :show-display-type="true" :is-comparative="true" time-period-label="Period" :content-list="pageContent" @reset-client-chart="resetClientChart">
      </chart-criteria>
      <div class="row no-gutters">
        <div class="col">
          <div id="chartDislayCard">
            <div id="performance_dashboard">
              <div id="chart-filters" class="row">
                <chart-criteria-results
                  v-show="!generatingChart"
                  ref="chartCriteriaResults"
                  v-bind:criteria-results-list="criteriaResultsList"
                  v-bind:criteria-results-company-list="criteriaResultsCompanyList"
                ></chart-criteria-results>
              </div>
              <div v-show="getDisplayView() && !generatingChart" class="row mt-3 mb-3">
                <div class="col-lg-12">
                  <button id="save_chart" class="btn btn-primary ml-2" type="button" @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as Card</button>
                </div>
              </div>
              <chart-display-skeleton v-show="generatingChart"/>
              <MatchByNeed v-show="isMatchByNeed && !generatingChart" :chart-data="chartData" :content-list="pageContent"></MatchByNeed>
              <MatchByValue v-show="isMatchByValue && !generatingChart" :chart-data="chartData" :content-list="pageContent"></MatchByValue>
              <MatchByWinners v-show="isMatchByWinners && !generatingChart" :chart-data="chartData" :content-list="pageContent"></MatchByWinners>
              <MatchByMoat v-show="isMatchByMoat && !generatingChart" :chart-data="chartData" :content-list="pageContent"></MatchByMoat>
              <ByWordCloud v-show="isMatchByWordCloud && !generatingChart" :chart-data="chartData" :include-word-cloud="false"></ByWordCloud>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
    <vue-modal ref="save-chart-confirmation" name="save-chart-confirmation" :width="350" :height="200">
      <save-chart :chart-title="this.chart.title" :initial-client-chart="this.clientChart" @save="updateClientCharts" @closeModal="closeModal('save-chart-confirmation')"></save-chart>
    </vue-modal>
    <vue-modal ref="print-chart" name="print-chart" :width="350" :height="200">
      <chart-criteria-results
        ref="chartCriteriaResults"
        v-bind:criteria-results-list="criteriaResultsList"
        v-bind:criteria-results-company-list="criteriaResultsCompanyList"
      ></chart-criteria-results>
    </vue-modal>
  </div>
</template>
<script>
import ChartDisplaySkeleton from "../../components/skeleton/ChartDisplaySkeleton.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import SaveChart from "../../components/chart/SaveChart.vue";
import MatchByNeed from "./type/MatchByNeed.vue";
import MatchByValue from "./type/MatchByValue.vue";
import MatchByMoat from "./type/MatchByMoat.vue";
import MatchByWinners from "./type/MatchByWinners.vue";
import ByWordCloud  from "./type/ByWordCloud.vue";

import ChartService from "../../services/chart.service";
import ErrorService from "../../services/error.service";
import ContentService from "../../services/content.service";

export default {
  name: "ProfileMatch",
  mixins: [ChartCompanyMixins],
  components: {
    SaveChart,
    MatchByNeed,
    MatchByValue,
    MatchByWinners,
    MatchByMoat,
    ByWordCloud,
    ChartDisplaySkeleton
  },
  data() {
    return {
      title: "Match",
      pageNumber: 8,
      isMatchByNeed: true,
      isMatchByValue: false,
      isMatchByMoat: false,
      isMatchByWinners: false,
      isMatchByWordCloud: false,
      chartData: [],
      
    };
  },
  methods: {
    displayChartCallback(response) {
      try {
        this.$root.processServerResponse();
        this.generatingChart = false;

        this.chartData = response.data;
        this.isMatchByNeed = this.$refs.chartCriteria.$data.displayType == "need";
        this.isMatchByValue = this.$refs.chartCriteria.$data.displayType == "value";
        this.isMatchByMoat = this.$refs.chartCriteria.$data.displayType == "moat";
        this.isMatchByWinners = this.$refs.chartCriteria.$data.displayType == "winners";
        this.isMatchByWordCloud  = this.$refs.chartCriteria.$data.displayType == "nps";
      } catch (err) {
        ErrorService.processException("ProfileMatch.displayChartCallback", err, "chart", this);
      }
    },
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
  },
  umnounted() {},
  computed: {},
  props: {},
  mounted() {},
  created() {
    this.chart = ChartService.getChartAttributes("Match");
  },
  watch: {}
};
</script>
