<template>
    <div class="e-chart-buttons mt-1 mb-2">
      <div class="mt-4">
        <show-label :input-show-labels="showLabels" @set-show-labels="changeShowLabels" :content-list="pageContentForNPS"></show-label>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContentForNPS" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>      
    </div>
</template>

<script>
import EChartsButtonsMixins from '../../mixins/EChartsButtonsMixins.vue';
import ShowLabel from './ShowLabel.vue';
import Modal from "../Modal.vue";
import ContentService from '../../services/content.service';
export default {
  name: "PredictiveAnalyticsChartButtons",
  mixins: [EChartsButtonsMixins],
  components: {Modal,ShowLabel},
  data() {
    return {
      pageContentForNPS: [],
    };
  },
  props: {},
  computed: {},
  mounted() {
         
  },
  created() {
    ContentService.getPageContent("NPS", this.getPageContentByNPSCallBack, this);    
  },
  methods: {
    getPageContentByNPSCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForNPS = response.data.data;
      }
    },
  },
  watch: {},
};
</script>
