<template>
  <vue-modal ref="import-survey-group" name="import-survey-group" :width="398" :height="400">
    <form id="ImportSurveyGroupsFormId" name="form" @submit.prevent="importFile">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Surveys to Group Data</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          <span :class="modalReturnMessageClass"> {{ modalReturnMessage }}</span>
        </div>
        <div v-show="rowSelected" class="form-group form-row p-1">
          <input type="radio" v-model="mode" name="save" value="existing" />Import into selected survey group: {{ initialSurveyGroupName }}
        </div>
        <div class="form-group form-row p-1"><input type="radio" v-model="mode" name="save" value="new" class="pr-1" />Import As New Survey Group</div>
        <div v-show="isNewImport">
          <div class="form-row p-1">
            <div class="form-group form-inline w-100">
              <label for="surveyGroupName" class="mr-1">Survey Group Name</label>
              <input v-model="surveyGroup.name" id="surveyGroupName" name="surveyGroupName" type="text" class="form-control required w-75 required" />
            </div>
          </div>
        </div>
        <div class="form-group form-row p-1">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importSurveyGroupUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row p-1 d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </form>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import AdminService from "../services/admin.service";
import FormService from "../services/form.service";
import SurveyGroupService from "../services/survey.group.service";

export default {
  name: "ImportSurveyGroup",
  components: {},
  mixins: [ContentMixins, ViewMixins],

  methods: {
    getDownloadFilename() {
      return "Survey Group Import Template";
    },
    onFileChange: function () {
      this.importSurveyGroupImportFile = this.$refs.importSurveyGroupUpload.files[0];
    },
    async importFile() {
      if (this.isNewImport) {
        this.setupImportSurveyGroupsForm();
        if (!this.importSurveyGroupsForm.valid()) {
          return false;
        }
      }
      if (this.importSurveyGroupImportFile) {
        this.setModalLoading(true);
        this.modalReturnMessage = "Importing surveys to survey group data please stand by.....";
        SurveyGroupService.importFile(this.importSurveyGroupImportFile, this.surveyGroup, this.mode, this.importFileCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
      }
    },
    importFileCallback: function (response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      this.modalReturnMessage = response.data.message;
      if (statusCode == 200) {
        this.modalReturnMessageClass = "text-success";
        this.$emit("reload");
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.mode = "existing";
      } else {
        this.modalReturnMessageClass = "text-danger";
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    setupImportSurveyGroupsForm() {
      this.importSurveyGroupsForm = $("#ImportSurveyGroupsFormId");
      FormService.setupForm(this.importSurveyGroupsForm);
      this.validator = this.importSurveyGroupsForm.validate(FormService.getBaseValidator());
    },
    closeModal() {
      if (this.$refs['import-survey-group'] != null) {
        this.$refs['import-survey-group'].visible = false;
      }
      this.$router.go(0);
    }
  },
  props: {
    initialSurveyGroupName: {
      type: String,
      default: "",
    },
    initialSurveyGroup: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initialMode: {
      type: String,
      default: "",
    },
    rowSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalReturnMessageClass: "text-success",
      modalReturnMessage: "",
      mode: "new",
      validator: null,
      importSurveyGroupsForm: null,
      surveyGroup: SurveyGroupService.getDefaultSurveyGroup(),
    };
  },
  computed: {
    isNewImport: function () {
      return this.mode == "new";
    },
  },
  created() {},
  mounted() {},
  watch: {
    initialMode: function () {
      this.mode = this.initialMode;
      if (this.mode == "existing") {
        this.surveyGroup = JSON.parse(JSON.stringify(this.initialSurveyGroup));
      }
    },

    initialSurveyGroup: function () {
      if (this.initialMode == "existing") {
        this.surveyGroup = JSON.parse(JSON.stringify(this.initialSurveyGroup));
      }
    },
  },
};
</script>
