<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div class="response-rates"  v-show="!isInsiderUser">
      <div class="show-more-chart-criteria">
        <a href="#" v-show="!displayChartCriteria" @click="toggleChartCriteria">Show Chart Options<i class="fa fa-chevron-down ml-1"></i></a>
        <a href="#" v-show="displayChartCriteria" @click="toggleChartCriteria">Hide Chart Options<i class="fa fa-chevron-up ml-1"></i></a>
      </div>
      <!-- Chart Criteria -- Start -->      
      <div v-show="displayChartCriteria" class="form-row">
        <div class="col-4 mt-2 chart-criteria-border">
          <div class="d-flex"><h4 class="response-rates-title text-primary mr-1">Chart Waves</h4><modal-link modal-id="Chart Waves" :content-list="pageContent"></modal-link></div>
          <div class="form-group">
            <div v-show="true" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Periods</label>
                  <multiselect
                    ref="periodsSelect"
                    v-model="periods"
                    :options="period_options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :max-height="250"
                    select-label=""
                    deselect-label=""
                    selected-label=""
                    placeholder="Select"
                    label="chartDisplayName"
                    name="multi-period"
                    track-by="clientTimePeriodId"
                    @select="selectPeriod"
                    @remove="removePeriod"                    
                    :preselect-first="false"
                  >
                    <template #option="props">
                      <span class="checkbox-label" @click.self="select(props.option)">
                        <input name="multi-period" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                        {{ props.option.chartDisplayName }}
                      </span>
                    </template>
                  </multiselect>
                </div>
          </div>
          <div class="form-group">
            <label for="surveyGroup" class="control-label pt-1 my-auto">Group</label>
            <div class="">
              <select
                id="surveyGroup"
                name="surveyGroup"
                class="custom-select shadow"
                :disabled="disableChartCriteriaWidgets"
                v-on:change="saveValue($event, 'Survey Group', 'survey_group', true);setUseEqualWeight();"
                v-model="surveyGroup"
              >
                <option value="">None</option>
                <option v-for="(option, idxSurveyGroupOptions) in survey_group_options" v-bind:value="option.value" v-bind:key="idxSurveyGroupOptions">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="form-group pt-2">
              <input 
                id="showPublicGroups" 
                name="showPublicGroups" 
                type="checkbox" 
                true-value="Y" 
                false-value="N" 
                v-model="showPublicGroups" 
                @click="saveCheckboxValue($event, 'Show Public Groups', 'show_public_groups', true)"
                />
              <span class="ml-1 mr-1">Show Public Groups</span>
              <modal-link modal-id="Show Public Groups" :content-list="pageContent"></modal-link>
            </div>    
          </div>
        </div>
        <div class="col-4 mt-2">
          <div class="d-flex"><h4 class="response-rates-title text-primary mr-1">Chart Data</h4><modal-link modal-id="Chart Data" :content-list="pageContent"></modal-link></div>
          <response-rate-segment-question ref="segmentQuestion" 
                :input-segment-questions="segmentQuestions"
                :input-question-label="eChartCriteria.questionLabel" 
                :input-options-list="saveSelectedOptionsList" 
                @set-segment-question="setSegmentQuestion"/>
        </div>
      </div>  
      <!-- Chart Criteria -- End -->      
      <div v-show="displayChartCriteria" class="row no-gutters" style="border-bottom: 1px solid #2f5eb3;">
        <div class="btn-group-horizontal pb-2 pt-2" role="group">
          <button type="submit" class="btn btn-primary mr-3" @click="getResponseRates" :disabled="disableGenerate">
            <span>Generate</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button type="submit" class="btn btn-primary mr-3" @click="resetChartCriteria">Reset</button>
          <button id="save_chart" class="btn btn-primary" type="button" @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as Card</button>
        </div>
      </div>
      <div class="row mt-3 ml-5">
        <chart-display-skeleton v-show="generatingChart"/>
      </div>
      <div v-show="getDisplayView() &&  displayChartCriteria" class="row mt-3 ml-5">
        <div class="col-3">
            <div class="d-flex"><h4 class="response-rates-title text-primary mr-1">Chart Types</h4><modal-link modal-id="Chart Types" :content-list="pageContent"></modal-link></div>
            <response-rate-chart-buttons ref="chartButtons" :input-number-of-waves="periods.length" :input-e-chart-type="eChartCriteria.eChartType" 
                :input-recalculate="eChartCriteria.recalculate"
                :input-show-labels="eChartCriteria.showLabels"
                :input-show-legend="eChartCriteria.showLegend"
                :input-color-palette-id="eChartCriteria.colorPaletteId"
                :input-display-order="eChartCriteria.displayOrder"
                :content-list="pageContent"
                @set-recalculate="setRecalculate"
                @set-display-order="setDisplayOrder"
                @set-chart="setChartType"
                @set-show-legend="setShowLegend"
                @set-color-palette="setColorPaletteId"
                @set-show-labels="setShowLabels"
            />
        </div>
        <response-rate-chart ref="responseRateChart"
                              v-show="getDisplayView() && hasChartRun && displayChartCriteria"
                            :raw-data="responseRatesData" 
                            :is-saved-card="clientChart.clientChartId != 0"
                            :input-question-list="questionLabelList" 
                            :input-client-chart="clientChart"
                            :input-time-periods="timePeriods"
                            :input-time-period-name="singleWaveTimePeriodName"
                            :most-recent-time-period="mostRecentTimePeriod"
                            :e-chart-criteria="eChartCriteria"
                            @save-criteria="rebuildClientChart"/>
      </div>                           
      <div v-show="getDisplayView()" id="response-rates-title-and-chart" class="ml-5 title-and-chart d-flex pt-3">
        <div v-show="getDisplayView()" id="response-rates-data-container">
          <table v-if="getDisplayView()" :id="responseRatesTable.id" :class="responseRatesTable.class" data-turbolinks="false" width="auto">          
            <thead>
              <tr>
                <th
                  v-for="(tableHeader, idxTableHeader) in this.responseRatesData.tableHeader"
                  v-bind:key="idxTableHeader"
                  v-bind:class="[tableHeader.hasOwnProperty('headerClass') ? tableHeader.headerClass : 'base-column']"
                >
                  <span>
                    {{ tableHeader.data }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idxRows) in this.responseRatesData.rowsMap.rows" v-bind:key="idxRows">
                <td v-for="(needRow, idxNeedsRow) in row" v-html="needRow.data" v-bind:key="idxNeedsRow" v-bind:class="[needRow.hasOwnProperty('dataClass') ? needRow.dataClass : 'base-column']">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="isInsiderUser" class="overlay" >
      <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png"  style="width: 50%"/>
    </div>
    <insights ref="insightsChart" :modal-name="'response-rates-chart'"
        :report-page-type-name="'Chart - Response Rates'" :entry-page-content="this.pageContentForReport"
        @saveReportPage="updateReportPages" @saveAllReportPages="saveAllReportPages"
        @saveClientChart="saveClientChart" />    
    <counts-by-location ref="countsByLocation" :period="currentPeriod"/>
    <vue-modal ref="save-chart-confirmation" name="save-chart-confirmation" :width="350" :height="200">
        <save-chart button-label="Save as Card" :chart-title="this.chart.title" :initial-client-chart="this.clientChart" @save="updateClientCharts" @closeModal="closeModal('save-chart-confirmation')"></save-chart>
    </vue-modal>
  </div>
</template>
<script>
import $ from "jquery";

import EChartsService from "../../services/echarts.service";
import PrintAndCopyService from "../../services/print.and.copy.service";
import TableService from "../../services/table.service";
import ChartDisplaySkeleton from "../../components/skeleton/ChartDisplaySkeleton.vue";

import UserService from "../../services/user.service";
import GoogleService from "../../services/google.service";
import AdminService from "../../services/admin.service";
import SurveyService from "../../services/survey.service";
import ReportService from "../../services/report.service";
import ClientService from "../../services/client.service";
import ContentMixins from "../../mixins/ContentMixins.vue";
import RespondentMixins from "../../mixins/RespondentMixins.vue";
import ChartCriteriaMixins from "../../mixins/ChartCriteriaMixins.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import ChartMenu from "../../views/menu/ChartMenu.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import CountsByLocation from "../../modals/CountsByLocation.vue";
import ResponseRateChart from "../echarts/ResponseRateChart.vue";
import SaveChart from "../../components/chart/SaveChart.vue";
import ChartCriteriaResults from "../../components/chart/ChartCriteriaResults.vue";
import ResponseRateChartButtons from "../../components/echarts/ResponseRateChartButtons.vue";
import ResponseRateSegmentQuestion from "../../components/echarts/ResponseRateSegmentQuestion.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "ResponseRates",
  mixins: [ContentMixins, RespondentMixins, DataTableMixins, ChartCriteriaMixins, ChartCompanyMixins],
  components: {
    ChartMenu,CountsByLocation,ResponseRateChart,SaveChart,ResponseRateChartButtons,ResponseRateSegmentQuestion,Multiselect,ChartDisplaySkeleton
  },
  props: {},
  data() {
    return {
      initialPageLoad: true,
      displayChartCriteria: false,
      disableGenerate: false,
      pageNumber: 9,
      title: "Rates",
      chart: {
        title: "Response Rates",
        typeName: "response-rates"
      },
      responseRatesData: {
        rowsMap: {}
      },
      hasChartRun: false,
      questionLabelList: [],
      segmentQuestions: [],
      positiveClass: "text-success",
      negativeClass: "text-danger",
      baseClass: "base-column",
      notActiveClass: "",
      currentPeriod: "0",
      responseRatesTable: {
        id: "response-rate-table",
        class: "table display wrap row-border table-hover",
        columns: [],
        configuration: {
          dom: '<"title-and-table-left"<"table-header"><B>>frtip',
          searching: false,
          paging: false,
          select: true,
          processing: false,
          responsive: true,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          sort: false,
          bSortable: false,
          bSort: false,
          buttons: [],
          columnDefs: [
            { orderSequence: ["desc", "asc"], targets: ["_all"] },
            { targets: [0], visible: true },
            {
              targets: "_all",
              width: "50px"
            }
          ]
        }
      },
      snapModalContent: PrintAndCopyService.getSnapMessage(),
      period_options: [],
      periods: [],
      timePeriods: [],
      eChartCriteria: this.getDefaultEChartCriteria(),
      saveSelectedOptionsList: [],
      mostRecentTimePeriod: "",
    };
  },
  computed: {
    disableSaveAsCardButton: function(){
      if (this.isW5OUser || this.isInsiderUser) {
        return true;
      }
      if (this.clientChart.clientChartId != 0 ){
        return false;
      }
      if (!this.disableGenerate) {
        return true;
      }
      return false;
    },
    singleWaveTimePeriodName: function(){
      if (this.period_options.length > 0 && this.periods.length > 0) {
        let singleWaveTimePeriodId = this.periods[0].clientTimePeriodId;
        let period = this.period_options.find(x => x.clientTimePeriodId == singleWaveTimePeriodId);
        if (typeof period != "undefined") {
          return period.chartDisplayName;
        }
      }
      return "";
    },
  },
  methods: {
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    toggleChartCriteria() {
      this.displayChartCriteria = !this.displayChartCriteria;
    },
    selectPeriod(period) {
      var match = this.periods.find(x => Number(x.clientTimePeriodId) === Number(period.clientTimePeriodId));
      if (typeof match != "undefined") {
        period.checked = true;
      }   
    },
    removePeriod(period) {
      if (typeof period !== "undefined") {
          period.checked = false;
      }      
    },
    resetPeriods() {
      for (let i = 0; i < this.period_options.length; i++) {
        this.period_options[i].checked = false;
      }
    },
    setDisplayOrder(inputDisplayOrder){
      this.eChartCriteria.displayOrder = inputDisplayOrder;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },      
    setRecalculate(inputRecalculate){
      this.eChartCriteria.recalculate = inputRecalculate;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    setSegmentQuestion(selectedSegmentQuestion) {
      this.eChartCriteria.questionLabel = selectedSegmentQuestion.label;
      this.eChartCriteria.selectedOptionsList = JSON.parse(JSON.stringify(selectedSegmentQuestion.optionsList));
        this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    setChartType(inputEChartType, inputRecalculate){
      this.eChartCriteria.eChartType = inputEChartType;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    setColorPaletteId(inputColorPalette){
      this.eChartCriteria.colorPaletteId = inputColorPalette;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    setShowLegend(inputShowLegend){
      this.eChartCriteria.showLegend = inputShowLegend;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    setShowLabels(inputShowLabels){
      this.eChartCriteria.showLabels = inputShowLabels;
      this.$refs.responseRateChart.processChart(this.hasChartRun,this.eChartCriteria);
    },
    async snapChart(displayLegend, snapContainerId) {
      PrintAndCopyService.initializeSnapChart(displayLegend, snapContainerId, this); 
    },
    showCountsByLocation(period){
      this.currentPeriod = period;
      this.$refs.countsByLocation.loadData();
      this.$modal.show("counts-by-location");

    },
    clearTable() {
      if (typeof this.responseRatesTable.dataTable != "undefined") {
        this.responseRatesTable.dataTable.destroy();
        this.responseRatesData = {};
        this.responseRatesData.rowsMap = {};
      }      
    },
    isNumber(value) {
      return value != "--";
    },
    async getResponseRates(checkNumberOfWaves = true) {
      if (checkNumberOfWaves && this.periods.length <= 0) {
        let errorMessage = "At least one wave is required. Please select wave(s) to display this chart.";
        AdminService.displayErrorMessage({ text: errorMessage });
        this.disableGenerate = false;
        return;
      }
      if (checkNumberOfWaves && this.eChartCriteria.selectedOptionsList.length <= 0) {
        let errorMessage = "At least one segment option is required. Please select one or more options to display this chart.";
        AdminService.displayErrorMessage({ text: errorMessage });
        this.disableGenerate = false;
        return;
      }      
      if (this.$refs.chartButtons.verifyChartCriteria(this.eChartCriteria.eChartType)) {
        this.generatingChart = true;
        this.setDisplayView(false);
        this.$root.processServerRequest("Retrieving data");
        let params = this.buildClientChart(this.buildBaseCriteriaUrl);
        await SurveyService.getResponseRates(params, this.getResponseRatesCallback, this);
        if (this.initialPageLoad == true) {
          this.initialPageLoad = false;
        } else {
          this.hasChartRun = true;
          this.disableGenerate = true;
        }
        this.rebuildClientChart(true);
        this.autoGenerateChartRun = false;
        this.saveSelectedOptionsList = [];
      } 
    },
    rebuildClientChart(rebuildClientChart) {
      let params = this.buildClientChart(this.buildBaseCriteriaUrl);
    },
    buildBaseCriteriaUrl() {
      let periodIds = this.periods.map(period => period.clientTimePeriodId);   
      let params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      params.append("periodIds", periodIds);
      params.append("surveyGroup", this.surveyGroup);
      params.append("questionLabel",this.eChartCriteria.questionLabel);
      params.append("hasChartRun",this.hasChartRun);
      params.append("displayOrder",this.eChartCriteria.displayOrder);
      params.append("eChartType",this.eChartCriteria.eChartType);
      params.append("recalculate",this.eChartCriteria.recalculate);
      params.append("colorPaletteId",this.eChartCriteria.colorPaletteId);
      
      let savedSelectedOptionsList = "";
      for (let i = 0; i < this.eChartCriteria.selectedOptionsList.length; i++) {
        savedSelectedOptionsList += this.eChartCriteria.selectedOptionsList[i] + ";";
      }
      savedSelectedOptionsList = savedSelectedOptionsList.substring(0, savedSelectedOptionsList.length - 1);
      params.append("selectedOptionsList",savedSelectedOptionsList);
      
//      params.append("selectedOptionsList",this.eChartCriteria.selectedOptionsList);
      params.append("showLegend",this.eChartCriteria.showLegend);
      params.append("showLabels",this.eChartCriteria.showLabels);
      return this.buildCriteriaUrl(params);
    },
    buildClientChart: function(buildURLFunction) {
      let useSaveParameters = typeof this.clientChart.criteria != undefined && this.clientChart.criteria != "" && this.autoGenerateChartRun;
      const params = useSaveParameters ? new URLSearchParams(this.clientChart.criteria) : buildURLFunction();
      this.clientChart.criteria = params.toString();
      this.clientChart.chartTypeId = this.chartType.chartTypeId;
      this.clientChart.clientId = this.clientId;
      if (this.periods.length > 0) {
        this.clientChart.clientTimePeriodId = this.periods[0].clientTimePeriodId;
      }
      if (typeof this.clientChart.displayType != "undefined" && typeof this.$refs.chartCriteria != "undefined") {
        this.clientChart.displayType = this.$refs.chartCriteria.$data.displayType;
      } else {
        this.clientChart.displayType = "response-rates";
      }
      if (typeof this.saveClientChartObject.name == "undefined" || this.saveClientChartObject.name == "") {
        let selectedQuestion = this.questionLabelList.find(x => x.label == this.eChartCriteria.questionLabel);
        if (typeof selectedQuestion != "undefined") {
          this.clientChart.name = selectedQuestion.segmentLabel;
        } else {
          this.clientChart.name = this.chart.title + " Analytics";
        }
      }
      return params;
    },
    processAutoGenerateChart() {
      if (this.autoGenerateChartRun) {
        if (typeof this.clientChart != "undefined") {
          if (typeof this.clientChart.criteria != "undefined" && this.clientChart.criteria != "") {
            let criteriaParameters = this.clientChart.criteria.split("&");
            for (let criteriaIndex = 0; criteriaIndex < criteriaParameters.length; criteriaIndex++) {
              let criteria = criteriaParameters[criteriaIndex];
              this.processSavedChartCriteria(criteria);
            }
          }
        }
      }
      this.getResponseRates(false);
    },            
    getResponseRatesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.setDisplayView(true);
        this.criteriaResultsList = (typeof response.data.data.criteriaList != "undefined") ? AdminService.removeByName(response.data.data.criteriaList, "Competitor") : [];
        this.responseRatesData = response.data.data.responseRatesData;
        this.questionLabelList = this.responseRatesData.questionLabelList;
        this.segmentQuestions = response.data.data.segmentQuestions;
        this.mostRecentTimePeriod = response.data.data.mostRecentTimePeriod;
        this.timePeriods = response.data.data.timePeriods;
        this.responseRatesTable.configuration.order = [];
        let vm = this;
        setTimeout(() => {
          var tableSelector = "#" + vm.responseRatesTable.id;

          let isSavedCard = vm.clientChart.clientChartId != 0;
          $.extend(vm.responseRatesTable.configuration, TableService.getTableButtons(vm, "response-rates-data-container"),isSavedCard);
          vm.responseRatesTable.dataTable = $("#" + vm.responseRatesTable.id).DataTable(vm.responseRatesTable.configuration);
          if (vm.eChartCriteria.questionLabel == ""){
            vm.eChartCriteria.questionLabel = vm.questionLabelList.length > 0 ? vm.questionLabelList[0].label : "";
          }
          let searchableQuestionLabel = vm.questionLabelList.find(x => x.label == vm.eChartCriteria.questionLabel);
          if (vm.hasChartRun &&  typeof searchableQuestionLabel == "undefined"){
            let errorMessage = "The segment selected is not included in any of the surveys in the group selected. Please select a different segment and/or group";
            AdminService.displayErrorMessage({ text: errorMessage });
            vm.disableGenerate = false;
            return;            
          }
          vm.$refs.responseRateChart.processChart(true,vm.eChartCriteria);
          let responseRatesHeaderContainer = $("#response-rates-data-container")
          .find(".title-and-table-left");

          if (responseRatesHeaderContainer.length > 1) {
            responseRatesHeaderContainer[0].remove();
          }               
          $("#response-rates-title-and-chart").addClass("chart-wrapper");            
          vm.generatingChart = false;
        }, 1500);
      } else if (statusCode == 201) {
        AdminService.displaySuccessMessage({ text: response.data.message },true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: function() {
      if (!this.isW5OUser){
        this.initializeData();
      }
    },
    async initializeData() {
      this.setDisplayView(false);
      if (!this.$parent.autoGenerateChartRun) {
        this.criteriaResults = [];
        this.resetChartCriteria(false);
      }
      this.clearTable();     
      this.hasChartRun = false; 
      this.initialPageLoad = true;
      await ClientService.getPredictiveAnalyticsOptions(this.getChartCriteriaOptionsCallback, this);
    },
    getChartCriteriaOptionsCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.period_options = response.data.data.clientTimePeriods;
        let additionalOptions = response.data.data.additionalOptions;
        this.criteriaResults = [];
        for (var i in additionalOptions) {
          this.criteriaResults.push(additionalOptions[i]);
        }
        this.surveyGroupList = response.data.data.surveyGroupList;
        this.segmentQuestions = response.data.data.segmentQuestions;
        this.eChartCriteria.questionLabel = this.segmentQuestions.length > 0 ? this.segmentQuestions[0].label : "";
        this.survey_group_options = this.getSurveyGroupOptions();
        this.processAutoGenerateChart();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    getDefaultEChartCriteria: function(){
      let defaultEChartCriteria = {
        recalculate: true,
        displayOrder: "question",
        selectedOptionsList: [],
        questionLabel: "",
      };
      $.extend(defaultEChartCriteria, EChartsService.getBaseEChartCriteria());
      if (typeof this.questionLabelList != "undefined" && this.questionLabelList.length > 0){
        defaultEChartCriteria.questionLabel = this.questionLabelList[0].label;
      }
      return defaultEChartCriteria;
    },
    resetChartCriteria: function() {
      this.resetPeriods();
      this.disableGenerate = false;
      this.resetOption = !this.resetOption;
      this.surveyGroup = "";
      this.autoGenerateChartRun = false;
      this.periods = [];
      this.resetCriteriaResults();
      this.hasChartRun = false;
      GoogleService.logResetCriteria(this.chart.title, "", this.survey.name, this);
      this.eChartCriteria = JSON.parse(JSON.stringify(this.getDefaultEChartCriteria()));
      this.$refs.segmentQuestion.resetSegmentQuestion();
    },    
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let defaultParameter = criteriaParameters[0];
      let defaultValue = criteriaParameters[1];
      let defaultCriteria = this.findCriteriaByParameter(defaultParameter);
      if (defaultParameter == "questionLabel"){
        let questionLabel = "";
        try {
          questionLabel = JSON.parse(defaultValue);
        } catch (e) {
          questionLabel = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }        
        this.eChartCriteria.questionLabel = questionLabel;       
      }
      if (defaultParameter == "hasChartRun"){
        this.hasChartRun =  defaultValue == "true";
        this.displayChartCriteria = this.hasChartRun;        
      }
      if (defaultParameter == "periodIds"){
        let savedPeriodIds;
        try {
          savedPeriodIds = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSavedPeriodIds = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedPeriodIds = decodedSavedPeriodIds.split(',');
        }     
        if (typeof savedPeriodIds == "number"){
          let period = this.period_options.find(x => x.clientTimePeriodId == savedPeriodIds);
          if (typeof period != "undefined") {
            period.checked = true;
            this.periods.push(period);
          }
        }
        for (let i = 0; i < savedPeriodIds.length; i++) {
          let periodId = savedPeriodIds[i];
          let period = this.period_options.find(x => x.clientTimePeriodId == periodId);
          if (typeof period != "undefined") {
            period.checked = true;
            this.periods.push(period);
          }
        }
      }
      if (defaultParameter == "displayOrder"){
        let displayOrder = "";
        try {
          displayOrder = JSON.parse(defaultValue);
        } catch (e) {
          displayOrder = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }        
        this.eChartCriteria.displayOrder = displayOrder;
      }
      
      if (defaultParameter == "colorPaletteId"){
        let colorPaletteId = "";
        try {
          colorPaletteId = JSON.parse(defaultValue);
        } catch (e) {
          colorPaletteId = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }        
        if (!isNaN(colorPaletteId)) {
          this.eChartCriteria.colorPaletteId = Number(colorPaletteId);
        }        
      }
      if (defaultParameter == "eChartType"){
        let eChartType = "";
        try {
          eChartType = JSON.parse(defaultValue);
        } catch (e) {
          eChartType = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }     
        this.eChartCriteria.eChartType = eChartType;   
      }
      if (defaultParameter == "recalculate"){
        let recalculate = "";
        try {
          recalculate = JSON.parse(defaultValue);
        } catch (e) {
          recalculate = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.eChartCriteria.recalculate = recalculate || (recalculate == "true");   
      }
      if (defaultParameter == "showLegend"){
        let showLegend = "";
        try {
          showLegend = JSON.parse(defaultValue);
        } catch (e) {
          showLegend = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.eChartCriteria.showLegend = showLegend || (showLegend == "true");   
      }
      if (defaultParameter == "showLabels"){
        let showLabels = "";
        try {
          showLabels = JSON.parse(defaultValue);
        } catch (e) {
          showLabels = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.eChartCriteria.showLabels = showLabels || (showLabels == "true");   
      }
      if (defaultParameter == "selectedOptionsList"){
        let selectedOptionsList;
        try {
          selectedOptionsList = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSelectedOptionsList = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          selectedOptionsList = decodedSelectedOptionsList.split(';');
          selectedOptionsList.filter(value => value != "");
        }      
        this.eChartCriteria.selectedOptionsList = JSON.parse(JSON.stringify(selectedOptionsList));
        this.saveSelectedOptionsList = JSON.parse(JSON.stringify(selectedOptionsList));
      }
      if (typeof defaultCriteria != "undefined") {
        let isSurveyGroup = defaultCriteria.parameterName == "survey_group" ? true : false;
        let isShowPublicGroups = defaultCriteria.parameterName == "show_public_groups" ? true : false;
        let updateValue = false;
        if (isShowPublicGroups) {
          this.showPublicGroups = defaultValue;
          this.survey_group_options = this.getSurveyGroupOptions();
        }
        if (isSurveyGroup) {
          this.surveyGroup = defaultValue;
        }        
        this.saveResultValue(defaultValue, defaultCriteria.name, defaultCriteria.parameterName, updateValue);
      }
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Response Rates") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      } 
    },
    saveAllReportPages() {
      this.saveReportPage("response-rates-chart","Chart - Response Rates",this.clientChart);
    },   
  },
  mounted() {},
  watch: {
    periods: function() {
      this.disableGenerate = false;
    },
    surveyGroup: function() {
      this.disableGenerate = false;
    },
    showPublicGroups: function() {
      this.disableGenerate = false;
    },
  },
  
};
</script>
