<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
    </div>
    <div class="survey-status container">
      <div class="d-flex">
        <div class="form-group form-inline">
          <label for="startDate" class="mr-1">Start Date</label>
          <datepicker format="MMM dd yyyy" v-model="startDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="startDate"></datepicker>
        </div>
        <div class="form-group form-inline ml-2">
          <label for="endDate" class="mr-1">End Date</label>
          <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
        </div>
        <div class="pr-2" style="padding-top: 17px;">
          <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Run</button>
        </div>
        <div class="w-100">
          <download-survey-data :input-download-sets="downloadSets" :input-disable-download-button="disableDownloadButton"/>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead>
          
          <tr>
            <th><input type="checkbox" id="select-all"></th>
            <th>Client Name</th>
            <th>Survey Name</th>
            <th>Total Opens</th>
            <th>Total Complete</th>
            <th>Total Partial</th>
            <th>Participation Rate</th>
            <th>Average Time</th>
            <th></th>
          </tr>
          
        </thead>
        <tbody></tbody>
      </table>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
  </div>
</template>
<script>
import RespondentMixins from "../../mixins/RespondentMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import Datepicker from "vuejs-datepicker";
import DownloadSurveyData from "../../components/DownloadSurveyData.vue";

import SurveySummaryService from "../../services/survey.summary.service";
import $ from "jquery";
import jQuery from "jquery";
export default {
  name: "SurveyStatus",
  mixins: [RespondentMixins, DataTableMixins],
  components: { Datepicker,DownloadSurveyData },
  props: {},
  data() {
    return {
      table: {
        id: "survey-status-download-data",
        columns: SurveySummaryService.getSurveyStatusTableColumnData(),
        configuration: {
          dom: '<"top"lif>rt<"bottom justify-content-between"Bp><"clear">', 
          searching: true,
          paging: true,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          bSort: false,
          responsive: false,
          pageLength: 100,
          buttons: [
            {
              extend: "copy",
              text: "Copy Status to Clipboard",
              className: "btn btn-primary"
            },
            {
              extend: "csv",
              text: "Download Status",
              className: "btn btn-primary"
            }
          ]          
        },        
      },
      pageNumber: 12,
      startDate: new Date(2014, 1, 1),
      endDate: new Date(),
      title: "Survey Status",
      downloadSets: [],
      disableDownloadButton: false,
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest();
      SurveySummaryService.getSummaryDataByClientIdAndDate(this.startDate, this.endDate, this.getTableDataCallback, this);
    },
    getDownloadFilename(){
      return "Survey Status - " + this.startDate.toLocaleDateString("en-US") + " - " + this.endDate.toLocaleDateString("en-US");
    },    
    loadData: function() {
      this.downloadSets = [];
      this.getTableData();
      this.table.configuration.order = [[2, "asc"],[3, "asc"]];
    },
    deselectAll: function(){
      this.downloadSets = [];
      // uncheck all rows in table
      let vm = this;
      vm.table.dataTable.rows().every(function(){
        var $row = $( this.node() );
        $row.find('input[name="id[]"]').prop('checked', false)
      }); 
      this.disableDownloadButton = false;   
    },
    addToDownloadSets: function(data){
      if (!jQuery.isEmptyObject(data)) {
          let downloadSet = new Object();
          downloadSet.id = data.surveyId;
          downloadSet.surveyId = data.surveyId;
          downloadSet.startDate = this.startDate;
          downloadSet.endDate = this.endDate;
          this.downloadSets.push(downloadSet);
          this.disableDownloadButton = true;
        }
    },
    selectAll: function(){
      this.downloadSets = [];
      let vm = this;
      vm.table.dataTable.rows().every(function(rowIdx){
        var $row = $( this.node() );
        let data = vm.table.dataTable.row($row).data();
        vm.addToDownloadSets(data);
        $row.find('input[name="id[]"]').prop('checked', true)
      });
    }
  },
  created() {},
  mounted() {
    let vm = this;
    $("#survey-status-download-data tbody").on("click", 'input[type="checkbox"]', function(e, dt, type, indexes) {
      var $row = $(this).closest("tr");
      var data = vm.table.dataTable.row($row).data();
      if (!jQuery.isEmptyObject(data)) {
        if (e.target.checked) {
          vm.addToDownloadSets(data);
        } else {
          let removedItem = vm.downloadSets.find(x => x.surveyId == data.surveyId);
          if (typeof removedItem !== "undefined") {
            vm.downloadSets.splice($.inArray(removedItem, vm.downloadSets), 1);
            if (vm.downloadSets.length == 0) {
              vm.disableDownloadButton = false;
            }
          }
        }
      }
    });
    $('#select-all').on('click', function(){
      if ($(this).is( ":checked" )) {
        vm.selectAll();
      } else {
        vm.deselectAll();
      }      
    });    
  },
  watch: {},
  computed: {}
};
</script>
