<template>
    <div class="locale-import-buttons">
        <a 
            :class="[this.table.rowSelected ? 'pr-2 ' : 'pr-2 disable-link']"
            href="#" @click="$emit('trigger-open-import-modal')">
            <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
            <span class="align-text-bottom">{{ importDataButton }} <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
        </a>
        <a
            href="/api/v1/locale/download-survey-locale-template"
            @click.prevent="downloadItem()"
            id="DownloadSurveyLocaleTemplate"
            :class="[this.table.rowSelected ? 'float-right ' : 'float-right disabled disable-link']"
            role="button"
            aria-disabled="true"
        >
            <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
            <span class="align-text-bottom">{{ downloadTemplateButton }} <font-awesome-icon :icon="['fa', 'download']"/></span>
            <span v-show="isModalLoading" class="spinner-border spinner-border-sm"></span>
            </button>
        </a>
        <a 
            :class="[this.table.rowSelected ? 'pr-2 ' : 'pr-2 disable-link']"
            href="#" @click="$emit('trigger-locale-preview-modal')">
            <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
            <span class="align-text-bottom">{{ surveyPreviewButton }} <font-awesome-icon :icon="['fas', 'eye']"/></span>
            </button>
        </a>
    </div>
</template>
<script>
import { isMobile } from "mobile-device-detect";
import LocaleService from "../services/locale.service";
import AdminService from "../services/admin.service";
import ErrorService from "../services/error.service";
export default {
  name: "LocaleImportButtons",
  data() {
    return {
        importDataButton: "Import Data",
        downloadTemplateButton: "Download Template",
        surveyPreviewButton: "Preview Locale"
    };
  },
  umnounted() {},
  computed: {},
  created() {
    if (!isMobile) {
        this.downloadTemplateButton = "Download Locale Template";
        this.importDataButton = "Import Locale File";        
        this.surveyPreviewButton = "Preview Locale";
    } else {
        this.downloadTemplateButton = "Download Locale";
        this.importDataButton = "Import Locale";        
        this.surveyPreviewButton = "Preview";
    }       
  },
  mounted() {},
  methods: {
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DataTableMixins.downloadItem", error, "survey-designer", this);
      this.$gtag.exception("Error Downloading the file");
    },    
    async downloadItem() {
      this.setupDownloadItem();
      let surveyLocaleId = this.table.rowSelectedId;     
      LocaleService.downloadSurveyLocaleFile(surveyLocaleId, 
          this.downloadItemCallback, this); 
    },
    clickLink(blob, includeExtention = false, extension = ".xlsx"){
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.getDownloadFilename();
      if (includeExtention == true){
        link.download += extension;
      }
      link.click();
      URL.revokeObjectURL(link.href);      
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      this.clickLink(blob, true);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },    
    getDownloadFilename() {
      return "Survey Locale Template - " + this.currentSurveyLocale.surveyName;
    },
    async setupDownloadItem() {
      this.$emit("set-modal-loading");
    },
    openImportModal: function() {
      this.$emit('trigger-open-import-modal')
    },  
  },
  watch: {},
  props: {
    currentSurveyName: {
      type: String,
      default: ""
    },
    isModalLoading: {
      type: Boolean,
      default: false
    },
    table: {
      type: Object,
      default: function() {
        return {};
      }
    },
    currentSurveyLocale: {
      type: Object,
      default: function() {
        return {};
      }
    },
  }
};
</script>
