<template>
  <div class="form-inline w-100">
    <div class="d-none col-1 font-weight-bold">
      {{ periodName }}
    </div>
    <div class="col-1">
      {{ inputRespondentWaveObject.numberOfRespondents }}
    </div>
    <div class="col-1">
      <select id="year" name="year" class="custom-select required" :value="inputRespondentWaveObject.year" @input="updateRespondentWave($event.target.value,'year')">
        <option value>Year</option>
        <option v-for="(year_option, idxYearOptions) in this.$parent.$data.year_options" :value="year_option.value" :key="idxYearOptions">
          {{ year_option.name }}
        </option>
      </select>
    </div>
    <div class="col-1">
      <select id="period" name="period" class="custom-select required" :value="inputRespondentWaveObject.period" @input="updateRespondentWave($event.target.value,'period')">
        <option v-for="(period_option, idxPeriodOptions) in this.periodList" :value="period_option.value" :key="idxPeriodOptions">
          {{ period_option.name }}
        </option>
      </select>
    </div>
    <div class="col-2">
      <input :value="inputRespondentWaveObject.displayName" id="name" name="name" type="text" class="form-control w-75" @input="updateRespondentWave($event.target.value,'displayName')">
    </div>
    <div class="col-2">
      <datepicker
        :value="inputRespondentWaveObject.startDate"
        wrapper-class="text-center"
        @selected="updateStartDate"
        @opened="this.createMoreSpace"
        :typeable="true" 
        :openDate="openDate"
        input-class="form-control required w-75"
        name="startDate"
      ></datepicker>
    </div>
    <div class="col-2">
      <datepicker
        :value="inputRespondentWaveObject.endDate"
        wrapper-class="text-center"
        @selected="updateEndDate"
        @opened="this.createMoreSpace"
        :typeable="true" 
        :openDate="openDate"
        input-class="form-control required w-75"
        name="endDate"
      ></datepicker>
    </div>
    <div class="col-1 d-flex">
      <div class="w-30">
        <a v-if="displayAddWave()" href="#!" @click="this.addRespondentWave" class="btn-lg">
          <font-awesome-icon :icon="['fa', 'plus-circle']" />
        </a>
      </div>
      <div class="w-30">
        <a v-if="displayRemoveWave()" href="#!" v-show="!isNewRecord" @click="this.removeRespondentWave" class="btn-lg">
          <font-awesome-icon :icon="['fa', 'minus-circle']" />
        </a>
      </div>
      <div v-show="isNewRecord" class="btn-lg"></div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Vue from "vue";
import { reactive, toRaw } from 'vue'
import AdminService from "../services/admin.service";
import ClientTimePeriodService from "../services/client.time.period.service";
import GoogleService from "../services/google.service";

Vue.prototype.moment = moment;

export default {
  name: "RespondentWaveEntry",
  components: {
    Datepicker
  },
  props: {
    inputRespondentWaveObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    newRespondentWaveIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      openDate: null,
      respondentWaveObject: {}
    };
  },
  methods: {
    displayAddWave: function() {
      let currentWaveSize = this.$parent.respondentWaveListObject.length;
      if (this.newRespondentWaveIndex == currentWaveSize - 1) {
        return true;
      } else {
        return false;
      }      
    },
    displayRemoveWave: function() {
      if (this.isNewRecord == true) {
        return false;
      }
      return (this.newRespondentWaveIndex >= 0);
    },
    createMoreSpace: function() {
      //this.$log.info("Create more space");
      // eslint-disable-next-line no-console
      console.log("Create more space");
    },
    addRespondentWave: function() {
      let error_message = "";
      if (this.respondentWaveObject.startDate > this.respondentWaveObject.endDate) {
        error_message += "The start date cannot be greater than the end date. ";
      }
      if (typeof this.respondentWaveObject.year == "undefined" || this.respondentWaveObject.year == "") {
        error_message += "The year field is required. ";
      }
      if (typeof this.respondentWaveObject.period == "undefined" || this.respondentWaveObject.period == "") {
        error_message += "The period field is required. ";
      }
      if (error_message == "") {
        GoogleService.logClickEvent(this.$parent.clientName, "Add New Wave - " + this.periodName,  0, this);
        this.$emit("save");
      } else {
        AdminService.displayErrorMessage({ text: error_message });
      }
    },
    removeRespondentWave: function() {
      GoogleService.logClickEvent(this.$parent.clientName, "Remove Wave - " + this.periodName,  0, this);
      this.$emit("remove", this.respondentWaveObject.clientTimePeriodId);
    },
    updateStartDate: function(value) {
      this.respondentWaveObject = JSON.parse(JSON.stringify(this.inputRespondentWaveObject));
      this.respondentWaveObject.startDate = value;
      this.$emit("update", this.respondentWaveObject.clientTimePeriodId, this.respondentWaveObject);
    },
    updateEndDate: function(value) {
      this.respondentWaveObject = JSON.parse(JSON.stringify(this.inputRespondentWaveObject));
      this.respondentWaveObject.endDate = value;
      this.$emit("update", this.respondentWaveObject.clientTimePeriodId, this.respondentWaveObject);
    },

    updateRespondentWave(value,key){
      this.respondentWaveObject = JSON.parse(JSON.stringify(this.inputRespondentWaveObject));
      if (key == "displayName"){
        this.respondentWaveObject.displayName = value;
      } else if (key == "period"){
        this.respondentWaveObject.period = parseInt(value);
      } else if (key == "year"){
        this.respondentWaveObject.year = parseInt(value);
      } 
      this.$emit("update", this.respondentWaveObject.clientTimePeriodId, this.respondentWaveObject);
    },
    initializeDate: function() {
      if (typeof this.respondentWaveObject.year != "undefined" && this.respondentWaveObject.year != "") {
        let dates = ClientTimePeriodService.getStartAndEndDateInitializeDate(this.respondentWaveObject.year, 
          this.respondentWaveObject.period, this.$parent.clientSettings.numberOfPeriods);
        this.respondentWaveObject = JSON.parse(JSON.stringify(this.inputRespondentWaveObject));
        this.respondentWaveObject.startDate = new Date(dates.start_date);
        this.respondentWaveObject.endDate = new Date(dates.end_date);
        this.openDate = new Date(dates.start_date);
        this.respondentWaveObject.displayName = this.periodName;
        this.respondentWaveObject.numberOfRespondents = 0;
        this.$emit("update", this.respondentWaveObject.clientTimePeriodId, this.respondentWaveObject);
      } else {
        this.openDate = new Date();
      }
    }
  },
  created() {},
  mounted() {},
  watch: {
    periodName: function() {
      if (this.inputRespondentWaveObject != undefined && this.periodName != undefined) {
        this.respondentWaveObject.periodName = this.periodName;
        if (typeof this.inputRespondentWaveObject.startDate == "undefined") {
          this.initializeDate();
        }
      }
    }
  },
  computed: {
    period_options: function() {
      return ClientTimePeriodService.getPeriodOptions();
    },    
    periodList(){
      let originalPeriodOptions = this.period_options.slice(0,this.$parent.clientSettings.numberOfPeriods);
      var match = originalPeriodOptions.find(x => x.value == this.respondentWaveObject.period);
      if (typeof match == "undefined" && typeof this.respondentWaveObject.period != "undefined"){
          originalPeriodOptions.push({ name: this.respondentWaveObject.period.toString(), value: this.respondentWaveObject.period})
      }
      return originalPeriodOptions;
    },    
    
    isNewRecord: function(){
      if (this.respondentWaveObject != undefined && this.periodName != undefined) {
        return this.respondentWaveObject.clientTimePeriodId == 0;
      }
      return false;
    },
    periodName: function() {
      if (
        this.respondentWaveObject.year != undefined &&
        this.respondentWaveObject.period != undefined &&
        this.respondentWaveObject.year != "" &&
        this.respondentWaveObject.period != ""
      ) {
        return this.respondentWaveObject.year + " - P" + this.respondentWaveObject.period;
      } else {
        return "";
      }
    }
  }
};
</script>
