import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import UserService from "./user.service";
import AdminService from "./admin.service";
import $, { isEmptyObject } from "jquery";
import jQuery from "jquery";

const BASE_URL = AdminService.getBaseUrl();

class ThirdPartyService {
  processQualtricsRedirect(app) {
    if (localStorage.getItem("VID") != null) {
      let requestString = "?P=2&VID=";
      requestString += localStorage.getItem("VID");
      requestString += "&D=";
      requestString += app.surveyDuration;

      if (localStorage.getItem("ID1") != null) {
        requestString += "&ID1=";
        requestString += localStorage.getItem("ID1");
      }
      if (localStorage.getItem("ID2") != null) {
        requestString += "&ID2=";
        requestString += localStorage.getItem("ID2");
      }
      if (localStorage.getItem("ID3") != null) {
        requestString += "&ID3=";
        requestString += localStorage.getItem("ID3");
      }
      if (localStorage.getItem("Q1") != null) {
        requestString += "&Q1=";
        requestString += localStorage.getItem("Q1");
      }
      if (localStorage.getItem("Q2") != null) {
        requestString += "&Q2=";
        requestString += localStorage.getItem("Q2");
      }
      if (localStorage.getItem("Q3") != null) {
        requestString += "&Q3=";
        requestString += localStorage.getItem("Q3");
      }
      app.$gtag.event("Qualtrics Redirect", {
        event_category: app.surveyName,
        event_label: requestString,
        value: 0
      });
      localStorage.removeItem("VID");
      localStorage.removeItem("ID1");
      localStorage.removeItem("ID2");
      localStorage.removeItem("ID3");
      localStorage.removeItem("Q1");
      localStorage.removeItem("Q2");
      localStorage.removeItem("Q3");
      localStorage.removeItem("D");

      let baseUrl = "https://uky.az1.qualtrics.com/jfe/form/SV_eVZIOwYirwiv5cO/";
      let redirectUrl = baseUrl + requestString;
      window.location.href = baseUrl + requestString;
    }
  }
  initializeQualtricsRedirect(app) {
    /**
     * Mapping
     * 1. id = id
     */
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.VID != "undefined") {
      localStorage.setItem("VID", app.$route.query.VID);
      app.externalSurveyTakerId = JSON.stringify(app.$route.query);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.D != "undefined") {
      localStorage.setItem("D", parseInt(app.$route.query.D));
      app.surveyDuration = parseInt(app.$route.query.D);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.ID1 != "undefined") {
      localStorage.setItem("ID1", app.$route.query.ID1);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.ID2 != "undefined") {
      localStorage.setItem("ID2", app.$route.query.ID2);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.ID3 != "undefined") {
      localStorage.setItem("ID3", app.$route.query.ID3);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.Q1 != "undefined") {
      localStorage.setItem("Q1", app.$route.query.Q1);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.Q2 != "undefined") {
      localStorage.setItem("Q2", app.$route.query.Q2);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.Q3 != "undefined") {
      localStorage.setItem("Q3", app.$route.query.Q3);
    }
  }
  processIpsosRedirect(app) {
    if (localStorage.getItem("id") != null) {
      let pid = "";
      let maxDuration = 0;

      let maxDurationField = app.surveyFields.find(x => x.surveyFieldName == "max-duration");
      let pidField = app.surveyFields.find(x => x.surveyFieldName == "pid");
      if (typeof maxDurationField != "undefined") {
        maxDuration = parseInt(maxDurationField.surveyFieldValue);
      }
      if (typeof pidField != "undefined") {
        pid = pidField.surveyFieldValue;
      }
      let duration = app.surveyDuration;
      let status = maxDuration > duration ? "sc" : "c";
      
      let requestString = "?pid=";
      requestString += pid;
      requestString += "&stat=";
      requestString += status;
      requestString += "&id=";
      requestString += localStorage.getItem("id");
      app.$gtag.event("Ipsos Redirect", {
        event_category: app.surveyName,
        event_label: requestString,
        value: 0
      });
      localStorage.removeItem("pid");
      localStorage.removeItem("id");
      let baseUrl = "https://surveys.ipsosinteractive.com/surveys/";
      //let baseUrl = "https://uky.az1.qualtrics.com/jfe/form/SV_eVZIOwYirwiv5cO/";
      let redirectUrl = baseUrl + requestString;
      window.location.href = baseUrl + requestString;
    }
  }
  initializeIpsosRedirect(app) {
    /**
     * Mapping
     * 1. p = pid
     * 3. id = id
     */
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.p != "undefined") {
      localStorage.setItem("pid", app.$route.query.p);
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.id != "undefined") {
      localStorage.setItem("id", app.$route.query.id);
      app.externalSurveyTakerId = app.$route.query.id;
    }
  }
  initializeHomeInnovation(app){
    /**
     * Mapping
     * 1. r = r id
     * 3. s = s id
     */
    let externalSurveyTakerId = "";
     if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.r != "undefined") {
      if (externalSurveyTakerId != ""){
        externalSurveyTakerId += "&";
      }
      localStorage.setItem("r", app.$route.query.r);
      externalSurveyTakerId += "r=";
      externalSurveyTakerId += app.$route.query.r; 
    }
    if (!jQuery.isEmptyObject(app.$route.query) && typeof app.$route.query.s != "undefined") {
      if (externalSurveyTakerId != ""){
        externalSurveyTakerId += "&";
      }
      localStorage.setItem("s", app.$route.query.s);
      externalSurveyTakerId += "s=";
      externalSurveyTakerId += app.$route.query.s; 
    }
    if (externalSurveyTakerId != ""){
      app.externalSurveyTakerId = externalSurveyTakerId;
    }
  }
  processHomeInnovation(app) {
    if (localStorage.getItem("r") != null || localStorage.getItem("s") != null) {
      let maxDuration = 0;
      let maxDurationField = app.surveyFields.find(x => x.surveyFieldName == "max-duration");
      if (typeof maxDurationField != "undefined") {
        maxDuration = parseInt(maxDurationField.surveyFieldValue);
      }
      let requestString = "?";
      if (localStorage.getItem("r") != null) {
        requestString += "r=";
        requestString += localStorage.getItem("r");
      }
      if (localStorage.getItem("s") != null) {
        requestString += "&s=";
        requestString += localStorage.getItem("s");
      }
      let duration = app.surveyDuration;
      let status = maxDuration > duration ? "screen" : "complete";
      requestString += "&surveyStatus=";
      requestString += status;
      app.$gtag.event("HomeInnovation Redirect", {
        event_category: app.surveyName,
        event_label: requestString,
        value: 0
      });
      localStorage.removeItem("r");
      localStorage.removeItem("s");
      let baseUrl = "https://surveys.homeinnovation.com/wix/p429141863510.aspx";
      let redirectUrl = baseUrl + requestString;
      // eslint-disable-next-line no-console
      //console.log("Redirect Url " + redirectUrl);
      window.location.href = baseUrl + requestString;
    }
  } 
}
export default new ThirdPartyService();
