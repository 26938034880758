import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import ChartService from "./chart.service";
import AlertService from "./alert.service";

import moment from "moment-timezone";
import Vue from "vue";

Vue.prototype.moment = moment;

import $ from "jquery";
import jQuery from "jquery";
import { compressSpaces } from "canvg";

const GENERATE_REPORT = "Generating report.....";
const SAVING_REPORT = "Saving report.....";
const DOWNLOADING_REPORT = "Downloading report.....";
const DELETING_REPORT = "Deleting report.....";
const API_URL = AdminService.getBaseUrl() + "/api/v1/printreport";

class ReportService {
  getReportGenerationMessage() {
    return "Please stand by as your winning report is on its way....... <br> <span class='company-data-column'>Do not navigate away from this screen.....</span>";
  }
  getReportRenderMessage() {
    return "Generating report...... <br> <span class='company-data-column'>Do not navigate away from this screen.....</span>";
  }
  getReportDownloadCompleteMessage() {
    return "<span style='color: #68cd86;'>Download complete.</span> <br> <span class='company-data-column'>Your winning report is located in your Downloads folder.....</span>";
  }
  getReportSavedCompleteMessage(){
    return "<span style='color: #68cd86;'>Report saved.</span> <br>  <span class='company-data-column'> Go to The W Report page to download </span>";
  }
  getReportDownloadStillRunningMessage() {
    return "<span style='color: #68cd86;'>We are still working on your report.</span> <br> <span class='company-data-column'>The process can take up to 5 minutes to complete. You will receive an email once the report is generated. At that point you can navigate to Analytics -> The W Report to review and download your report</span>";
  }
  clearReportAutoChartGenerate() {
    localStorage.removeItem("report-auto-chart-generate");
    localStorage.removeItem("report-client-charts");
  }
  setReportAutoChartGenerateStatus(autoChartGenerate) {
    localStorage.setItem("report-auto-chart-generate", autoChartGenerate);
  }
  getReportAutoChartGenerateStatus() {
    if (localStorage.getItem("report-auto-chart-generate") != null) {
      return localStorage.getItem("report-auto-chart-generate");
    }
    return "";
  }
  addReportClientChart(clientChart) {
    let reportClientCharts = [];
    if (localStorage.getItem("report-client-charts") != null) {
      reportClientCharts = JSON.parse(localStorage.getItem("report-client-charts"));
    }
    reportClientCharts.push(clientChart);

    localStorage.setItem("report-client-charts", JSON.stringify(reportClientCharts));
  }
  removeReportClientChart(clientChart) {
    let reportClientCharts = [];
    if (localStorage.getItem("report-client-charts") != null) {
      reportClientCharts = JSON.parse(localStorage.getItem("report-client-charts"));
      let removedItem = reportClientCharts.find(x => Number(x.clientChartId) === Number(clientChart.clientChartId));
      if (typeof removedItem !== "undefined") {
        reportClientCharts.splice($.inArray(removedItem, reportClientCharts), 1);
      }
    }
    localStorage.setItem("report-client-charts", JSON.stringify(reportClientCharts));
  }
  getReportClientChartByChartType(chartType) {
    let reportReportClientCharts = this.getReportClientCharts();
    let reportReportClientChart = reportReportClientCharts.find(reportClientChart => Number(reportClientChart.chartTypeId) === Number(chartType.chartTypeId));
    return reportReportClientChart;
  }
  getSavedDashboardCardLink(clientChart, app) {
    let link = app.$router.resolve({
      name: "view-saved-dashboard-cards",
      params: { saveClientChartObject: clientChart }
    });
    return link;
  }
  isSavedDashboardCard(chartTypeId){
    let chartTypes = ChartService.getDashboardChartTypes();
    for (let index = 0; index < chartTypes.length; index++) {
      let chartType = chartTypes[index];
      if (chartType.chartTypeId == chartTypeId){
        return true;
      }
    }
    return false;
  }
  getClientChartLink(clientChart, app) {
    if (this.isSavedDashboardCard(clientChart.chartTypeId) == true){
      let dashboardLink = app.$router.resolve({
        name: "view-saved-dashboard-cards",
        params: { saveClientChartObject: clientChart }
      });
      return dashboardLink;
    }
    let link = app.$router.resolve({
      name: ChartService.findChartTypeById(clientChart.chartTypeId).shortName,
      params: { autoGenerateChart: true, saveClientChartId: clientChart.clientChartId }
    });
    return link;
  }
  async openClientChartWindow(clientChart, minimizeWindow = false, app) {
    if (!(await app.$auth.isAuthenticated())) {
      app.$router.push({ path: "/login" });
      return;
    }
    let link = this.getClientChartLink(clientChart, app);
    var randomnumber = Math.floor(Math.random() * 10000 + 1);
    var windowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=no";
    windowFeatures += ",width=" + screen.width;
    if (minimizeWindow === true) {
      windowFeatures += ",top=" + screen.height;
      windowFeatures += ",height=10";
    }
    let win = window.open(link.href, "_blank", windowFeatures, randomnumber);
  }
  getReportClientCharts() {
    if (localStorage.getItem("report-client-charts") != null) {
      return JSON.parse(localStorage.getItem("report-client-charts"));
    } else {
      return [];
    }
  }
  getReportPage(reportTypeName, reportPagesList) {
    let defaultReportPage = {
      reportPageId: 0
    };
    var match = reportPagesList.find(x => x.reportPageTypeName == reportTypeName);
    if (typeof match !== "undefined") {
      return match;
    }
    return defaultReportPage;
  }
  getInsightDescription(reportTypeName, pageContent) {
    let contentName = reportTypeName + " - Description";
    var match = pageContent.find(x => x.title == contentName);
    if (typeof match !== "undefined") {
      return match.contentText;
    }
    return "";
  }
  compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (Object.prototype.hasOwnProperty.call(a, "key") || Object.prototype.hasOwnProperty.call(b, "key")) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }
  async getReportPageTypes(callback, chatApp) {
    const params = new FormData();
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/get-all-report-page-types", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.getReportPageTypes", error, "admin", chatApp);
      });
  }
  async getReportsByClientId(clientId, callback, chatApp) {
    const params = new FormData();
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/get-reports-by-client-id", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.getReportsByClientId", error, "admin", chatApp);
      });
  }
  async getReportPages(clientChartId, callback, chatApp) {
    const params = new FormData();
    params.append("clientChartId", clientChartId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/get-report-pages", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.getReportPages", error, "admin", chatApp);
      });
  }
  async saveOverview(reportPage, clientChartParam, callback, chatApp) {
    const params = new FormData();
    params.append("reportPage", JSON.stringify(reportPage));
    params.append("clientChart", JSON.stringify(clientChartParam));
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/save-overview", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.saveOverview", error, "admin", chatApp);
      });
  }
async saveReportPage(reportPageContent, reportTypeName, clientChartParam, callback, chatApp) {
    const formData = new FormData();
    formData.append("reportPage", reportPageContent);
    formData.append("reportTypeName", reportTypeName);
    formData.append("userId", UserService.getUserId());
    formData.append("clientChart", JSON.stringify(clientChartParam));
    await axios
      .post(API_URL + "/save-report-page", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.saveReportPage", error, "admin", chatApp);
      });
  }
  async downloadReport(clientReportId, callback, chatApp){
    const params = new URLSearchParams();
    params.append("clientReportId", clientReportId);
    params.append("userId", UserService.getUserId());
    await axios({
      url: AdminService.getBaseUrl() + "/api/v1/printreport/download-report",
      method: "GET",
      responseType: "blob",
      params: params
    })
    .then(response => callback(response))
    .catch(error => chatApp.downloadItemError(error));

  }
  async saveReportPageFile(fileConent, fileExtension, reportTypeName, clientChartParam, callback, chatApp) {
    const formData = new FormData();
    formData.append("reportTypeName", reportTypeName);
    formData.append("userId", UserService.getUserId());
    formData.append("fileConent", fileConent);
    formData.append("fileExtension", fileExtension);
    formData.append("clientChart", JSON.stringify(clientChartParam));
    await axios
      .post(API_URL + "/save-report-page-file", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.saveReportPageFile", error, "admin", chatApp);
      });
  }  
  async saveReportTableFile(tableData, reportTypeName, clientChartParam, callback, chatApp) {
    const formData = new FormData();
    formData.append("reportTypeName", reportTypeName);
    formData.append("userId", UserService.getUserId());
    formData.append("tableData", tableData);
    formData.append("clientChart", JSON.stringify(clientChartParam));
    await axios
      .post(API_URL + "/save-report-table-file", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.saveReportTableFile", error, "admin", chatApp);
      });
  }    
  async checkReportName(clientReport, callback, chatApp) {
    if (clientReport.clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientReport", JSON.stringify(clientReport));
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/check-report-name", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ReportService.checkReportName", error, "admin", chatApp);
        });
    }
  }
  async deleteClientReport(clientReportId, callback, chatApp) {
    AdminService.displayInfoMessage({ text: DELETING_REPORT, duration: -1 });
    const params = new URLSearchParams();
    params.append("clientReportId", clientReportId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/delete-report", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ReportService.deleteClientReport", error, "admin", chatApp);
      });
  }
  async previewReport(clientReport, callback, chatApp) {
    AdminService.displayInfoMessage({ text: GENERATE_REPORT, duration: -1 });
    if (clientReport.clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientReport", JSON.stringify(clientReport));
      //params.append("clientChartIds", clientChartIds);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/preview-report", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (error.response.status != 504) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ReportService.previewReport", error, "admin", chatApp);
          } else {
            AlertService.closeAlert();
            AdminService.displayInfoMessage({
              text: "Still generating report.....This process takes more than 5 minutes. Please navigate to Analytics -> The W Report to review and download your report",
              duration: -1
            });
            chatApp.reportGenerationModalContent = this.getReportDownloadStillRunningMessage();
          }
        });
    }
  }
  async generateReportPDF(clientReport, saveReport, callback, chatApp) {
    if (saveReport == true || saveReport == "true"){
      AdminService.displayInfoMessage({ text: SAVING_REPORT, duration: -1 });
    } else {
      AdminService.displayInfoMessage({ text: DOWNLOADING_REPORT, duration: -1 });
    }
    if (clientReport.clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientReport", JSON.stringify(clientReport));
      params.append("userId", UserService.getUserId());
      params.append("saveReport", saveReport);
      await axios
        .post(API_URL + "/generate-report-pdf", params, { responseType: "blob", headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (error.response.status != 504) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ReportService.generateReportPDF", error, "admin", chatApp);
          } else {
            AlertService.closeAlert();
            AdminService.displayInfoMessage({
              text: "Still generating report.....This process takes more than 5 minutes. Please navigate to Analytics -> The W Report to review and download your report",
              duration: -1
            });
            chatApp.reportGenerationModalContent = this.getReportDownloadStillRunningMessage();
          }
        });
    }
  }
  async generatePowerPointReport(clientReport, saveReport, callback, chatApp) {
    if (saveReport == true || saveReport == "true"){
      AdminService.displayInfoMessage({ text: SAVING_REPORT, duration: -1 });
    } else {
      AdminService.displayInfoMessage({ text: DOWNLOADING_REPORT, duration: -1 });
    }
    if (clientReport.clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientReport", JSON.stringify(clientReport));
      params.append("userId", UserService.getUserId());
      params.append("saveReport", saveReport);
      await axios
        .post(API_URL + "/generate-report-pptx", params, { responseType: "blob", headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (typeof error.response == "undefined" || error.response.status != 504) {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ReportService.generatePowerPointReport", error, "admin", chatApp);
          } else {
            AlertService.closeAlert();
            AdminService.displayInfoMessage({
              text: "Still generating report.....This process takes more than 5 minutes. Please navigate to Analytics -> The W Report to review and download your report",
              duration: -1
            })
            chatApp.reportGenerationModalContent = this.getReportDownloadStillRunningMessage();
          }
        });
    }
  }
  getTableOfContents2() {
    return [
      {
        number: 2,
        name: "Contents"
      },
      {
        number: 3,
        name: "Research Responses"
      },
      {
        number: 4,
        name: "Executive Summary"
      }
    ];
  }
  getTableOfContents() {
    return [
      {
        number: 2,
        name: "Contents"
      },
      {
        number: 3,
        name: "Research Responses"
      },
      {
        number: 4,
        name: "Executive Summary"
      },
      {
        number: 5,
        name: "Summary Dashboard"
      },
      {
        number: 6,
        name: "Key Recommendations"
      },
      {
        number: 2,
        name: "Contents"
      },
      {
        number: 3,
        name: "Research Responses"
      },
      {
        number: 4,
        name: "Executive Summary"
      },
      {
        number: 5,
        name: "Summary Dashboard"
      },
      {
        number: 6,
        name: "Key Recommendations"
      },
      {
        number: 2,
        name: "Contents"
      },
      {
        number: 3,
        name: "Research Responses"
      },
      {
        number: 4,
        name: "Executive Summary"
      },
      {
        number: 5,
        name: "Summary Dashboard"
      },
      {
        number: 6,
        name: "Key Recommendations"
      }
    ];
  }
  getSavedReportsTableColumnData() {
    return [
      {
        title: "",
        bVisible: true,
        data: "clientReportId",
        name: "clientReportId",
        width: "4rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          let downloadClickHtml = '<button type="button" class="btn btn-primary btn-download" value="';
          downloadClickHtml += data;
          downloadClickHtml += ">";
          downloadClickHtml += '<span aria-hidden="true">Download</span>';
          downloadClickHtml += "</button>";
          return downloadClickHtml;
        }
      },
      {
        title: "",
        bVisible: true,
        data: "clientReportId",
        name: "clientReportId",
        width: "4rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          let editClickHtml = '<button type="button" class="btn btn-primary btn-edit ml-2" value="';
          editClickHtml += data;
          editClickHtml += ">";
          editClickHtml += '<span aria-hidden="true">Edit</span>';
          editClickHtml += "</button>";
          return editClickHtml;
        }
      },
      {
        title: "",
        bVisible: true,
        data: "clientReportId",
        name: "clientReportId",
        width: "4rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          let deleteClickHtml = '<button type="button" class="btn btn-primary btn-delete ml-2" value="';
          deleteClickHtml += data;
          deleteClickHtml += ">";
          deleteClickHtml += '<span aria-hidden="true">Delete</span>';
          deleteClickHtml += "</button>";
          return deleteClickHtml;
        }
      },
      { title: "Title", data: "title", name: "title", width: "10rem" },
      { title: "Sub Title", data: "subTitle", name: "subTitle", width: "10rem" },
      { title: "Client Name", data: "clientName", name: "clientName", width: "10rem" },
      { title: "File Path", data: "filePath", name: "filePath", width: "10rem" },
      {
        title: "Date Created",
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "4rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        title: "Last Modified",
        data: "dateModified",
        type: "date",
        name: "dateModified",
        width: "4rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
  }
  getReportConfiguration() {
    return {
      fontNames: [
        "Arial Black",
        "Comic Sans MS",
        "Courier New",
        "Franklin Gothic Demi Cond",
        "Franklin Gothic Heavy",
        "Helvetica",
        "Impact",
        "Roboto",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      direction: "ltr",
      height: 150,
      tabsize: 2,
      dialogsInBody: true,
      disableDragAndDrop: true,
      tooltip: false,
      focus: false,
      toolbar: [
        ["style", ["fontname", "fontsize", "color", "bold", "italic", "underline", "strikethrough"]],
        ["Misc", ["undo", "redo"]]
      ]
    };
  }
}

export default new ReportService();
