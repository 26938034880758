<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="survey-locale-list container">
        <div class="button-container">
          <div class="button-left">
            <div class="pr-2">
              <a class="pr-2" href="#" @click="mode='add';$modal.show('survey-locale-config')">
                <button type="button" class="btn btn-success" >
                    Add Survey Locale
                    <span class="ml-auto">
                      <font-awesome-icon :icon="['fa', 'plus-circle']" />
                    </span>
                </button>
              </a>
            </div>
            <div class="pr-2">
              <a 
                :class="[this.table.rowSelected ? 'pr-2 ' : 'pr-2 disable-link']"
                href="#" @click="mode='edit';$modal.show('survey-locale-config')">
                <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
                    Edit Survey Locale
                    <span class="ml-auto">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                </button>
              </a>
            </div>
            <div class="pr-2">
            <a 
              :class="[!this.table.rowSelected ? 'disable-link' : '']"
              href="#" @click="displayDeleteConfirmation(deleteSurveyLocale,table.rowSelectedId,'Delete Confirmation Survey Locale','Are you sure you want delete the survey/locale combination?')">
              <button type="button" class="btn btn-primary" id="DeleteLocaleButton" :disabled="!table.rowSelected">
                Delete Locale
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>

          </div>
          <div class="button-right">
            <locale-import-buttons 
              :table="table"
              is-modal-loading:="getModalLoading"
              :current-survey-locale="getSurveyLocaleObject()"
              :input-survey-locale-id="table.rowSelectedId"
              @set-modal-loading:="setModalLoading"
              @trigger-open-import-modal="openImportModal"
              @trigger-locale-preview-modal="openLocalePreviewModal"
            />
          </div>
        </div>

        <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          <thead></thead>
          <tbody></tbody>
        </table>
    </div>
    <survey-locale-config :input-survey-locale="getSurveyLocaleObject()"
      :input-locale-options="localeOptions"
      :input-survey-options="surveyOptions"
      :mode="mode"  
      @reload="reloadTable"/>

    <import-locale-file 
    :input-survey-locale-id="table.rowSelectedId"
      @reload="reloadTable"/>

      <preview-locale
        :input-survey-locale="getSurveyLocaleObject()"
      />


  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import SurveyLocaleConfig from "../../modals/SurveyLocaleConfig.vue";
import ImportLocaleFile from "../../modals/ImportLocaleFile.vue";
import LocaleImportButtons from "../../components/LocaleImportButtons.vue";
import PreviewLocale from "../../modals/PreviewLocale.vue";

import LocaleService from "../../services/locale.service";
import AdminService from "../../services/admin.service";

export default {
  name: "SurveyLocaleList",
  mixins: [AdminMixins, DataTableMixins],
  components: {SurveyLocaleConfig, ImportLocaleFile, LocaleImportButtons,PreviewLocale},
  data() {
    return {
      returnMessage: "",
      mode: "add",
      surveyOptions: [],
      localeOptions: [],
      title: "Survey Locale List",
      table: {
        id: "survey-locale-list-table",
        columns: [],
        configuration: {
          searching: true,
          dom: '<"top"fi>rtp<"bottom"B><"clear">',
        }
      }
    };
  },
  methods: {
    deleteSurveyLocale() {
      this.$root.processServerRequest("Deleting survey locale");
      LocaleService.deleteSurveyLocale(this.table.rowSelectedId, this.deleteSurveyLocaleCallback, this);
    },
    deleteSurveyLocaleCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableData();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },

    async getTableData() {
      this.table.rowSelected = false;
      this.table.columns = LocaleService.getSurveyLocaleColumns();
      this.$root.processServerRequest("Loading table data");
      await LocaleService.getSurveyLocaleOptions(this.getSurveyLocaleOptionsCallback, this);
      await LocaleService.getSurveyLocaleList(this.getTableDataCallback, this);
    },
    getSurveyLocaleOptionsCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyOptions = response.data.data.surveyOptions;
        this.localeOptions = response.data.data.localeOptions;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getSurveyLocaleObject() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject;
      }
      return LocaleService.getSurveyDefaultLocale();
    },
    reloadTable() {
      this.getTableData();
    },
    openImportModal: function() {
      this.returnMessage = "";
      this.$modal.show("import-locale-file");
    },    
    openLocalePreviewModal: function() {
      this.returnMessage = "";
      this.$modal.show("preview-locale");
    },    
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {}
};
</script>
