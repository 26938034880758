<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <company-menu></company-menu>
    <div class="edit-survey-group">
      <form accept-charset="UTF-8" enctype="multipart/form-data" id="SurveyGroupForm" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate">
        <div class="pt-2 pb-2">
          <router-link :to="{ name: 'survey-group-list' }">
            <button type="button" class="btn btn-success" id="ReturnToSurveyGroupListButton">Return to Survey Group List</button>
          </router-link>
        </div>
        <div class="form-row">
          <div class="form-group form-inline no-gutters w-50">
            <label for="surveyGroupName" class="col-3 pr-3 p-1"> Survey Group Name </label>
            <input v-model="surveyGroupName" id="surveyGroupName" name="surveyGroupName" :disabled="!displayView" type="text" class="form-control col-9 p-1 required" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group form-inline no-gutters w-50">
            <label class="col-3 pr-3 p-1">Is Public</label>
            <input
              id="isPublic"
              name="isPublic"
              type="checkbox"
              true-value="Y"
              false-value="N"
              v-model="surveyGroupIsPublic"
              @click="checkIsPublic($event)"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="pl-2 pb-2">Drag & Drop surveys to add or remove them from the group</div>
        </div>
        <div class="survey-group-container form-row">
          <div class="col-5">
            <div class="draggable-group-header">Current Survey Group</div>
            <!-- https://vue-draggable-plus.pages.dev/en/guide/-->
            <VueDraggable
              v-model="surveyGroupSurveyList"
              :disabled="!enabled"
              class="list-group draggable-container"
              ghost-class="draggable-ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="endOfDragRemoveSurveyGroup"
              options="people"
              group="bucket"
            >
              <div class="list-group-item draggable-card" v-for="element in surveyGroupSurveyList" :key="element.id+'_'+element.name" :index="element.name">
                <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                <div class="float-right">
                  <a load-on-starup="true" href="javascript:;" class="reload" @click="$modal.show('Survey - ' + element.surveyId)">
                    <font-awesome-icon :icon="['fas', 'window-close']" />
                  </a>
                </div>
                <span class="pl-1">{{ element.name }}</span>
                <modal
                  :modal-id="'Survey - ' + element.surveyId"
                  :modal-title="'Remove From Survey Group'"
                  :modal-content="'Are you sure you remove ' + element.name + ' from the survey group?'"
                  :modal-result-id="element.surveyId"
                  :include-confirmation="true"
                  @confirm="removeFromSurveyGroup(element)"
                />
              </div>
              <div class="text-center" v-show="emptySurveyGroupList">Drag & Drop here to add survey to the group</div>
            </VueDraggable>
          </div>
          <div class="col-1"></div>
          <div class="col-5">
            <div class="draggable-group-header">Available Surveys</div>
            <div class="form-group form-inline no-gutters pt-2">
              <input
                v-model="availableSurveyListSearch"
                id="availableSurveyListSearch"
                placeholder="Search"
                name="availableSurveyListSearch"
                :disabled="!displayView"
                type="text"
                class="form-control w-100"
              />
            </div>
            <VueDraggable
            v-model="filteredAndSortedAvailableSurveyList"
              :disabled="!enabled"
              class="list-group draggable-container"
              ghost-class="draggable-ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="endOfDragAddSurveyGroup"
              options="people"
              group="bucket"
            >
              <div class="list-group-item draggable-card" v-for="element in filteredAndSortedAvailableSurveyList" :key="element.id+'_'+element.name" :index="element.name">
                <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                <div v-if="isSurveyGroup(element)" class="float-right">
                  <i class="fa fa-files-o" aria-hidden="true"></i>
                </div>
                <span class="pl-1">{{ element.name }}</span>
              </div>

              <div class="text-center" v-show="availableSurveysList.length <= 0">Drag & Drop here to remove survey from the group</div>
            </VueDraggable>
          </div>
        </div>
        <div class="col mt-3" role="group">
          <button type="submit" class="btn btn-primary float-right ml-2" :disabled="emptySurveyGroupList" @click="saveSurveyGroup()">
            <span>Save</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </form>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { VueDraggable } from 'vue-draggable-plus';
import AdminService from "../../services/admin.service";
import SurveyGroupService from "../../services/survey.group.service";
import FormService from "../../services/form.service";
import { reactive, toRaw } from 'vue'

import CompanyMixins from "../../mixins/CompanyMixins.vue";
import ViewMixins from "../../mixins/ViewMixins.vue";

let id = 1;
export default {
  name: "EditSurveyGroup",
  mixins: [CompanyMixins, ViewMixins],
  components: {
    VueDraggable
  },
  props: {
    initialMode: {
      type: String,
      default: "add-survey-group"
    },
    initialSurveyGroupId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      surveyGroupForm: null,
      validator: null,
      title: "",
      mode: "",
      enabled: true,
      dragging: false,
      surveyGroupId: 0,
      surveyGroupName: "",
      surveyGroupIsPublic: "N",
      surveyGroupSurveyList: [],
      availableSurveysList: [],
      availableSurveyListSearch: ""
    };
  },
  methods: {
    checkMove: function(e) {},
    isSurveyGroup: function(element) {
      return SurveyGroupService.isSurveyGroup(element);
    },
    checkIsPublic: function(e) {
      if (e.target.checked) {
        this.surveyGroupIsPublic == "Y";
      } else {
        this.surveyGroupIsPublic == "N";
      }
    },    
    resetForm: function() {
      this.surveyGroupId = 0;
      this.surveyGroupName = "";
      this.surveyGroupIsPublic = "N";
      (this.surveyGroupSurveyList = []), (this.availableSurveysList = []);
    },
    loadData: function() {
      this.resetForm();
    },
    endOfDragAddSurveyGroup(event) {
      this.dragging = false;
      if (typeof event.pullMode != "undefined"){
        let element = toRaw(event.clonedData);
        var match = this.availableSurveysList.find(x => Number(x.surveyId) === Number(element.surveyId));
        if (typeof match !== "undefined") {
          this.availableSurveysList.splice($.inArray(match, this.availableSurveysList), 1);
        }      
      }
    },
    endOfDragRemoveSurveyGroup(event) {
      this.dragging = false;
      if (typeof event.pullMode != "undefined"){
        let element = toRaw(event.clonedData);
        this.availableSurveysList.push(element);
      }
    },    
    removeFromSurveyGroup(element) {
      this.surveyGroupSurveyList.splice($.inArray(element, this.surveyGroupSurveyList), 1);
      this.availableSurveysList.push(element);
    },
    async saveSurveyGroup() {
      if (!this.surveyGroupForm.valid()) {
        return false;
      } else {
        this.$root.processServerRequest("Saving survey group data");
        let surveyGroup = {};
        surveyGroup.mode = this.mode;
        surveyGroup.surveyGroupId = this.surveyGroupId;
        surveyGroup.surveyGroupName = this.surveyGroupName;
        surveyGroup.surveyGroupIsPublic = this.surveyGroupIsPublic;
        surveyGroup.stringSurveyGroupSurveyList = JSON.stringify(this.surveyGroupSurveyList);
        SurveyGroupService.saveSurveyGroup(surveyGroup, this.getSurveyGroupCallback, this);
      }
    },
    async getSurveyGroup() {
      this.$root.processServerRequest("Retrieving survey group data");
      SurveyGroupService.getSurveyGroup(this.initialSurveyGroupId, this.mode, this.getSurveyGroupCallback, this);
    },
    getSurveyGroupCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.setDisplayView(true);
        this.title = "Modify Survey Group";
        this.surveyGroupName = response.data.data.surveyGroupName;
        this.surveyGroupIsPublic = response.data.data.isPublic;
        if (this.mode == "copy-survey-group") {
          this.surveyGroupName += " - Copy";
          this.surveyGroupId = 0;
        } else {
          this.surveyGroupId = this.initialSurveyGroupId;
        }
        this.mode = "edit-survey-group";

        this.surveyGroupSurveyList = response.data.data.surveyGroupSurveyList;
        this.surveyGroupSurveyList = this.surveyGroupSurveyList.sort(this.compare);
        this.availableSurveysList = response.data.data.availableSurveysList;
        this.availableSurveysList = this.availableSurveysList.sort(this.compare);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.surveyGroupForm = $("#SurveyGroupForm");
      this.setupForm();
    },
    setupForm() {
      FormService.setupForm(this.surveyGroupForm);
      this.validator = this.surveyGroupForm.validate(FormService.getBaseValidator());
    },
    compare(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  },
  created() {
    this.surveyGroupId = this.initialSurveyGroupId;
    this.mode = this.initialMode;
    if (this.mode == "edit-survey-group") {
      this.title = "Modify Survey Group";
    } else if (this.mode == "add-survey-group") {
      this.title = "Add Survey Group";
    } else if (this.mode == "copy-survey-group") {
      this.title = "Copy Survey Group";
    }
    this.getSurveyGroup();
  },
  mounted() {},
  computed: {
    emptySurveyGroupList: function() {
      return this.surveyGroupSurveyList.length <= 0;
    },
    filteredAndSortedAvailableSurveyList: {
      set: function(newValue) {},      
      get: function() {
        if (this.availableSurveyListSearch != "") {
          return this.availableSurveysList.filter(survey => {
            return survey.name.toLowerCase().includes(this.availableSurveyListSearch.toLowerCase());
          });
          //.sort(this.compare);
        } else {
          //return this.availableSurveysList.sort(this.compare);
          return this.availableSurveysList;
        }
      }
    }
  }
};
</script>
