<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container company-list">
      <div class="button-container">
        <div class="button-left"></div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="$modal.show('import-user-file')">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Companies <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a href="/api/v1/admin/download-company-import-template" @click.prevent="downloadItem()" id="DownloadUserImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download Company Import Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
    <vue-modal ref="import-user-file" name="import-user-file" :width="368" :height="400">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Company Data</h5>
        </div>
        <div class="form-group form-row p-2">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importCompanyUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </vue-modal>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "CompanyList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importCompanyImportFile: "",
      title: "Company List",
      table: {
        id: "company-list-table",
        columns: [
          {
            title: "Company Id",
            data: "companyId",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "0rem",
            bVisible: false
          },
          { title: "Company Code", data: "companyCode", name: "companyCode", class: "text-left", width: "8rem" },
          { title: "Name", data: "name", name: "name", width: "15rem" },
          { 
            title: "Image", 
            data: "imageFileName", 
            name: "imageFileName", 
            width: "7rem",
            render: function(data, type, row) {
              if (data != "") {
                return "<div class='moat-comps-container'><div class='moat-comps-results' style='height:23px;'><div class='company-image' ><div class='" + data  + "'></div></div></div></div>";
              } else {
                return '';
              }
            }
          },
          { title: "Company Type", data: "companyType", name: "companyType", width: "10rem" },
          { title: "Sector Name", data: "sectorName", name: "sectorName", width: "10rem" },
          { title: "Benchmark Company", data: "isTopCompany", name: "isTopCompany", width: "7rem" },
          { title: "Framework", data: "framework", name: "framework", width: "7rem" },
          { title: "Discount", data: "discount", name: "discount", width: "6rem" },
          { title: "Color", data: "color", name: "color", width: "5rem" },
          { title: "Active", data: "active", name: "active", width: "5rem" },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            class: "text-left",
            width: "7rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Date Updated",
            data: "dateModified",
            type: "date",
            name: "dateModified",
            class: "text-left",
            width: "7rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          }
        ]
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      await axios
        .get(this.baseUrl + "/api/v1/admin/get-company-list", { headers: await authHeader() })
        .then(response => this.getTableDataCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("CompanyList.getTableData", error, "admin", this);
        });
    },
    getDownloadFilename() {
      return "Company Import Template";
    },
    onFileChange: function() {
      this.importCompanyImportFile = this.$refs.importCompanyUpload.files[0];
    },
    async importFile() {
      this.$root.processServerRequest("Importing company data");
      this.setModalLoading(true);
      let formData = new FormData();
      formData.append("file", this.importCompanyImportFile);

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/admin/import-companies", formData, { headers: headers })
        .then(response => this.importFileCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("CompanyList.importFile", error, "admin", this);
        });
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    closeModal: function() {
      if (this.$refs['import-user-file'] != null) {
        this.$refs['import-user-file'].visible = false;
      }
      this.$router.go(0);
    }
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/admin/download-company-import-template";
    }
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[5, "desc"]];
    }
  },
  props: {},
  mounted() {}
};
</script>
