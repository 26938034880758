import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import ClientService from "./client.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import ReportService from "./report.service";
import AlertService from "./alert.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;
const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/survey-summary";
const GET_SUMMARY_DATA_BY_SURVEY_ID = "Loading survey respondent data.....";

class SurveySummaryService {

  
  async deleteResponses(suveySummaryIdList, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveySummaryIdString", suveySummaryIdList);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/delete-responses", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        if (typeof error.response == "undefined" || error.response.status != 504) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.deleteResponses", error, "admin", chartApp);
        } else {
          AlertService.closeAlert();
          AdminService.displayInfoMessage({
            text: "Still deleting responses.....This process takes more than 5 minutes. There will be an email with the subject 'Grade Responses - Admin' when the process is complete.",
            duration: -1
          })
        }
      });
  }
  async deleteResponsesByFile(file, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", UserService.getUserId());
    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_URL + "/delete-responses-by-file", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        if (typeof error.response == "undefined" || error.response.status != 504) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.deleteResponsesByFile", error, "admin", chartApp);
        } else {
          AlertService.closeAlert();
          AdminService.displayInfoMessage({
            text: "Still grading responses.....This process takes more than 5 minutes. There will be an email with the subject 'Grade Responses - Admin' when the process is complete.",
            duration: -1
          })
        }
      });
  }
  async gradeResponsesByFile(file, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", UserService.getUserId());
    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_URL + "/grade-responses-by-file", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        if (typeof error.response == "undefined" || error.response.status != 504) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.gradeResponsesByFile", error, "admin", chartApp);
        } else {
          AlertService.closeAlert();
          AdminService.displayInfoMessage({
            text: "Still grading responses.....This process takes more than 5 minutes. There will be an email with the subject 'Grade Responses - Admin' when the process is complete.",
            duration: -1
          });
        }
      });
  }
  async gradeResponsesByList(suveySummaryIdList, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveySummaryIdString", suveySummaryIdList);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/grade-responses-by-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        if (typeof error.response == "undefined" || error.response.status != 504) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.gradeResponsesByList", error, "admin", chartApp);
        } else {
          AlertService.closeAlert();
          AdminService.displayInfoMessage({
            text: "Still grading responses.....This process takes more than 5 minutes. There will be an email with the subject 'Grade Responses - Admin' when the process is complete.",
            duration: -1
          });
        }
      });
  }
  async gradeResponsesBySurveyId(surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/grade-responses-by-survey-id", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        if (typeof error.response == "undefined" || error.response.status != 504) {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.gradeResponsesBySurveyId", error, "admin", chartApp);
        } else {
          AlertService.closeAlert();
          AdminService.displayInfoMessage({
            text: "Still grading responses.....This process takes more than 5 minutes. There will be an email with the subject 'Grade Responses - Admin' when the process is complete.",
            duration: -1
          });
        }
      });
  }

  async getDownloadSurveyDataUrlMultiple(dataSet, includeQualityGrades, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    params.append("dataSetString", JSON.stringify(dataSet));
    params.append("includeQualityGrades", includeQualityGrades);
    let url = API_URL + "/download-survey-data-by-time-period";
    await axios({
      url: url,
      params: params,
      method: "POST",
      responseType: "blob"
    })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        chartApp.downloadItemError(error);
      });
  }

  async getSummaryDataByClientIdAndDate(startDate, endDate, callback, chatApp) {
    AdminService.displayInfoMessage({ text: GET_SUMMARY_DATA_BY_SURVEY_ID }, { duration: -1 });
    let clientId = ClientService.getClientId();
    let clientCode = ClientService.getClientCode();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("startDate", startDate);
      params.append("endDate", endDate);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/get-survey-aggregate-data-by-survey-id-and-date", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getSummaryDataByClientIdAndDate", error, "survey-designer", chatApp);
        });
    }
  }
  async getSummaryDataBySurveyId(callback, chatApp) {
    AdminService.displayInfoMessage({ text: GET_SUMMARY_DATA_BY_SURVEY_ID }, { duration: -1 });
    let clientId = ClientService.getClientId();
    let clientCode = ClientService.getClientCode();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      await axios
        .post(API_URL + "/get-survey-aggregate-data-by-survey-id", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getSummaryDataBySurveyId", error, "survey-designer", chatApp);
        });
    }
  }
  async getRanks(value, callback, chatApp) {
    let clientId = ClientService.getClientId();
    let clientCode = ClientService.getClientCode();    
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientCode", clientCode);
      params.append("clientTimePeriodId", value);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      await axios
        .post(API_URL + "/get-ranks-by-time-period", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getRanks", error, "survey-designer", chatApp);
        });
    }
  }
  async processPartialResponses(maxNumberOfPartialRecords, callback, chatApp) {
    let userId = UserService.getUserId();
    if (typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("userId", userId);
      params.append("maxNumberOfPartialRecords", maxNumberOfPartialRecords);
      await axios
        .post(API_URL + "/process-partial-respondents", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.processPartialResponses", error, "admin", chatApp);
        });
    }
  }
  async processPartialResponsesForSurvey(surveryId, maxNumberOfPartialRecords, callback, chatApp) {
    let userId = UserService.getUserId();
    if (surveryId != 0 && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("surveyId", surveryId);
      params.append("userId", userId);
      params.append("maxNumberOfPartialRecords", maxNumberOfPartialRecords);
      await axios
        .post(API_URL + "/process-partial-respondents", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.processPartialResponses", error, "admin", chatApp);
        });
    }
  }
  getDownloadColumnData(){
    return {
      
      bVisible: true,
      data: "id",
      name: "id",
      width: "5rem",
      className: "select-checkbox text-center",
      render: function(data, type, full, meta) {
        return '<input type="checkbox" name="id[]" value="' + full.surveyId + '">';
      }
    };
  }
  getBaseRespondentDownloadTableColumnData() {
    return [
      { data: "totalSurveysSent", className: "text-center", name: "totalSurveysSent", width: "5rem" },
      { data: "completedSurveyTotal", className: "text-center", name: "completedSurveyTotal", width: "5rem" },
      { data: "incompletedSurveyTotal", className: "text-center", name: "incompletedSurveyTotal", width: "5rem" },
      {
        data: "completionRate",
        className: "text-center",
        name: "completionRate",
        width: "6rem",
        render: function(data, type, row) {
          return Number(data).toFixed(1) + " %";
        }
      },
      {
        data: "averageCompletionTime",
        className: "text-center",
        name: "averageCompletionTime",
        width: "7rem",
        render: function(data, type, row) {
          if (data > 120) {
            return moment
              .unix(data)
              .utc()
              .format("m[m :]s[s]");
          } else if (data >= 60 && data <= 120) {
            return moment
              .unix(data)
              .utc()
              .format("m[m :]s[s]");
          } else if (data > 0 && data < 60) {
            return moment
              .unix(data)
              .utc()
              .format("s[s]");
          } else {
            return "--";
          }
        }
      },
      { title: "", bVisible: false, data: "startDate", name: "startDate", width: "0rem" }

    ]
  }
  getRespondentDownloadTableColumnData() {
    let columnData = [
      this.getDownloadColumnData(),
      {data: "baseSurveyName", name: "baseSurveyName", width: "9rem" },
      {data: "surveyName", name: "surveyName", width: "12rem" },
    ];
    return columnData.concat(this.getBaseRespondentDownloadTableColumnData());
  }
  buildSurveyOptionList(surveySummaryList, clientId = 0) {
    const list = [];
    list.push({ name: "Select Survey", value: 0 });
    if (clientId != 0){
      surveySummaryList = surveySummaryList.filter(s => Number(s.clientId) == Number(clientId));
    }
    const surveyFilteredList = [...new Set(surveySummaryList.map(x => x.surveyId))]
      .map(surveyId => {
        return {
          surveyId: surveyId,
          surveyName: surveySummaryList.find(s => s.surveyId == surveyId).surveyName
        };
      });
    if (surveyFilteredList.length > 0) {
      surveyFilteredList.sort(ReportService.compareValues("surveyName", "asc"));
      surveyFilteredList.forEach(surveySummaryClient => {
        const option = {
          name: surveySummaryClient.surveyName,
          value: surveySummaryClient.surveyId,
        };
        list.push(option);
      });
    }
    return list;
  }  
  getSurveyStatusTableColumnData() {
    let columnData = [
      this.getDownloadColumnData(),
      { data: "clientName", name: "clientName", width: "10rem" },
      { data: "baseSurveyName", name: "baseSurveyName", width: "10rem" },
    ];
    return columnData.concat(this.getBaseRespondentDownloadTableColumnData());
  }
}

export default new SurveySummaryService();
