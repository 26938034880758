import moment from "moment-timezone";
import Vue from "vue";

Vue.prototype.moment = moment;


class LibraryService {
    getSortByOptions(){
      return [
        { name: "Date Added Newest", value: "date-added-newest" },
        { name: "Date Added Oldest", value: "date-added-oldest" },
        { name: "Title Ascending", value: "name-ascending" },
        { name: "Title Descending", value: "name-descending" },
      ];
    }
    getDownloadButton(){
        return {
          title: "",
          bVisible: true,
          data: "id",
          name: "id",
          width: "5rem",
          className: "select-checkbox text-center",
          render: function(data, type, full, meta) {
            let viewClickHtml = '<a class="download-report">';
            viewClickHtml += 'Download PDF <span class="ml-auto"> >> </span>';
            viewClickHtml += "</a>";
            return viewClickHtml;
          }
        };
    }    
    getLibraryTableColumnData() {
        let columnData = [];
        let baseData = this.getBaseLibraryTableColumnData();
        columnData.push(this.getDownloadButton());
        //columnData.push({ title: "Client Document Id", data: "clientDocumentId", name: "clientDocumentId", width: "5rem" });
        columnData = columnData.concat(baseData);
        return columnData;
    }
  
    getBaseLibraryTableColumnData() {
        return [
          { title: "Title", data: "header", name: "header", width: "8rem" },
          { title: "Description", data: "description", name: "description", width: "25rem" },
          { title: "File Name", data: "fileName", name: "fileName", width: "10rem" },
          {
            title: "Date Created",
            data: "createdDate",
            type: "date",
            name: "createdDate",
            width: "4rem",
            render: function(data, type, row) {
              return moment(data, "MM/DD/YYYY").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
        ];
    }
    getSavedCardTableColumnData() {
      let columnData = [];
      columnData.push(this.getIncludeInWReport());
      columnData.push(this.getDownloadButton());
      columnData.push(this.getDeleteButton());
      columnData = columnData.concat(this.getBaseSavedCardTableColumnData());
      return columnData;
    }
      
}

export default new LibraryService();
