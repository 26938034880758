import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import ClientService from "./client.service";
import UserService from "./user.service";
import AdminService from "./admin.service";
import GoogleService from "./google.service";
import UserSettingsService from "./user.settings.service";
import $ from "jquery";
import jQuery from "jquery";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

const BASE_URL = AdminService.getBaseUrl();
const API_SURVEY_DESIGN_URL = BASE_URL + "/api/v1/survey-design";
const API_SURVEY_URL = BASE_URL + "/api/v1/survey";

class SurveyService {
  getBaseInterviewUrl(){
    return typeof process.env.VUE_APP_INTERVIEW_URL != "undefined" ? process.env.VUE_APP_INTERVIEW_URL : "https://interview.wratings.com";
  }
  contains(savedSurveyCode) {
    let listOfSurveyCodes = ClientService.getSurveyCodes();
    if (savedSurveyCode != "null" && typeof listOfSurveyCodes != "undefined") {
      var match = listOfSurveyCodes.find(x => x.surveyCode == savedSurveyCode);
      if (typeof match !== "undefined") {
        return true;
      }
    }
    return false;
  }
  getNameBySurveyId(savedSurveyId) {
    var match = this.getSurveyById(savedSurveyId);
    if (typeof match !== "undefined") {
      return match.name;
    }
    return "";
  }
  getSurveyCodeBySurveyId(savedSurveyId) {
    var match = this.getSurveyById(savedSurveyId);
    if (typeof match !== "undefined") {
      return match.surveyCode;
    }
    return "";
  }
  getSurveyById(savedSurveyId) {
    let listOfSurveyCodes = ClientService.getSurveyCodes();
    if (savedSurveyId != "null" && typeof listOfSurveyCodes != "undefined") {
      var match = listOfSurveyCodes.find(x => x.surveyId == savedSurveyId);
      if (typeof match !== "undefined") {
        return match;
      }
    }
    return undefined;
  }
  getSurvey() {
    let listOfSurveyCodes = ClientService.getSurveyCodes();
    let currentSurveyCode = this.getSurveyCode();
    if (currentSurveyCode != "null" && typeof listOfSurveyCodes != "undefined") {
      var match = listOfSurveyCodes.find(x => x.surveyCode == currentSurveyCode);
      if (typeof match !== "undefined") {
        return match;
      }
    }
    return {};
  }
  getSurveyId() {
    let currentSurvey = this.getSurvey();
    if (!jQuery.isEmptyObject(currentSurvey)) {
      return currentSurvey.surveyId;
    }
    return 0;
  }
  getSurveyName() {
    let currentSurvey = this.getSurvey();
    if (!jQuery.isEmptyObject(currentSurvey)) {
      return currentSurvey.name;
    }
    return "";
  }
  getSurveyCode() {
    if (localStorage.getItem("survey-code") != null) {
      return localStorage.getItem("survey-code");
    } else {
      return "";
    }
  }
  setSurveyCode(surveyCode) {
    localStorage.setItem("survey-code", surveyCode);
  }
  resetSurveyCode(clientObject) {
    let surveyCode = UserSettingsService.getSavedSurveyCode();
    if (typeof clientObject.surveyCodes != "undefined" && clientObject.surveyCodes.length > 0) {
      surveyCode = clientObject.surveyCodes[0].surveyCode;
      this.setSurveyCode(surveyCode);
    } else {
      surveyCode = "";
      this.setSurveyCode(surveyCode);     
    }
    return surveyCode;
  }
  async saveSurveyTitle(surveyTitle, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyTitle", surveyTitle);
    params.append("surveyId", this.getSurveyId());
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-title", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyName", error, "survey-designer", chartApp);
      });
  }
  async saveSurveyName(surveyName, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyName", surveyName);
    params.append("surveyId", this.getSurveyId());
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-name", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyName", error, "survey-designer", chartApp);
      });
  }
  clearCache() {
    localStorage.removeItem("survey-code");
  }
  async deleteSurvey(surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_URL + "/delete-survey", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.deleteSurvey", error, "survey-designer", chartApp);
      });
  }
  async copySurvey(surveyId, numberOfCopies, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    params.append("numberOfCopies", numberOfCopies);
    await axios
      .post(API_SURVEY_URL + "/copy-survey", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.copySurvey", error, "survey-designer", chartApp);
      });
  }
  async doesSurveyUrlWork(survey) {
    let doesSurveyUrlWork = false;
    const params = new URLSearchParams();
    params.append("clientId", survey.clientId);
    if (survey.url != "") {
      params.append("surveyUrl", survey.url);
    }

    await axios
      .post(BASE_URL + "/api/v1/interview/get-survey-by-survey", params)
      .then(response => {
        doesSurveyUrlWork = true;
      })
      .catch(error => {
        doesSurveyUrlWork = false;
      });
    return doesSurveyUrlWork;
  }
  async getResponseRates(params, callback, chartApp) {
    let clientId = ClientService.getClientId();
    let surveyId = this.getSurveyId();
    if (surveyId == 0){
      chartApp.setDisplayView(true);
      chartApp.$root.processServerResponse();
    }
    if (clientId != 0 && surveyId != 0) {
      await axios
        .post(API_SURVEY_URL + "/get-response-rates", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveyService.getResponseRates", error, "survey-designer", chartApp);
        });
    }
  }
  async getDownloadRespondentCodesTemplate(callback, chartApp) {
    const params = new URLSearchParams();
    let url = API_SURVEY_DESIGN_URL + "/download-respondent-codes-import-template";
    await axios({
      url: url,
      params: params,
      method: "POST",
      responseType: "blob"
    })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      chartApp.downloadItemError(error);
    });
  }  
  async getSurveyUniqueCodesBySurveyId(surveyId,callback,chartApp){
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/get-survey-unique-codes-by-survey-id", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.getSurveyUniqueCodesBySurveyId", error, "survey-designer", chartApp);
      });
  }  
  async resetSurveyUniqueCode(surveyUniqueCodeId,surveyId,callback,chartApp){
    const params = new URLSearchParams();
    params.append("surveyUniqueCodeId", surveyUniqueCodeId);
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/reset-survey-unique-code", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.getSurveyUniqueCodesBySurveyId", error, "survey-designer", chartApp);
      });
  }  

  async saveSurveyUrl(surveyUrl, surveyId, clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyUrl", surveyUrl);
    params.append("surveyId", surveyId);
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-url", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyUrl", error, "survey-designer", chartApp);
      });
  }
  async saveSurveyDriver(surveyDriver, surveyId, callback, chartApp) {
    GoogleService.logBaseEvent("survey driver saved", this.getSurveyName(), "Name", 0, chartApp);

    const params = new URLSearchParams();
    params.append("surveyDriverObject", JSON.stringify(surveyDriver));
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-driver", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyDriver", error, "survey-designer", chartApp);
      });
  }
  async saveSurveyDriverOrder(surveyDrivers, surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyDriversObject", JSON.stringify(surveyDrivers));
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-driver-order", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyDriverOrder", error, "survey-designer", chartApp);
      });
  }
  async saveSurveyField(result, surveyId, callback, chartApp){
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("name", result.surveyFieldName);
    params.append("value", result.surveyFieldValue);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-survey-field", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.saveSurveyField", error, "survey-designer", chartApp);
      });
  }
	async importFile(file, surveyId, fullUrl, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("surveyId", surveyId);
    formData.append("userId", UserService.getUserId());
    formData.append("interviewUrl", fullUrl);

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_SURVEY_DESIGN_URL + "/import-unique-codes", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyService.importFile", error, "survey-designer", chartApp);
      });
  }
  getTableColumnData() {
    return [
      {
        data: "surveyCode",
        bVisible: false,
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "0rem"
      },
      { data: "surveyName", name: "surveyName", width: "15rem" },
      { data: "surveyCode", name: "surveyCode", width: "5rem" },
      { data: "surveyId", className: "text-left", name: "surveyId", width: "5rem" },
      { data: "title", name: "title", width: "15rem" },
      {
        data: "url",
        name: "url",
        width: "15rem",
        render: function(data, type, row) {
          if (type === "display") {
            if (typeof data != "undefined" && data != null && data.length > 0) {
              let url = "/" + row.clientId + "/s/" + data;
              return url;
            }
          } else {
            return data;
          }
        }
      },
      {
        data: "clientName",
        name: "clientName",
        width: "7rem"
      },
      {
        data: "parentClientName",
        name: "parentClientName",
        width: "7rem"
      },
      {
        data: "buildRequired",
        name: "buildRequired",
        className: "text-center",
        width: "5rem"
      },
      {
        data: "active",
        className: "text-center",
        name: "status",
        width: "4rem",
        render: function(data, type, row) {
          var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        data: "dateCreated",
        type: "date",
        name: "createDate",
        width: "6rem",
        className: "text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateModified",
        type: "date",
        name: "updateDate",
        width: "6rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
  }
}

export default new SurveyService();
