<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :scrollable="true" :adaptive="true"  @opened="loadData" :resizable="true" :width="368" :minHeight="700" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div class="form-group form-row text-success">
                {{ modalReturnMessage }}
            </div>
            <div style="min-height: 300px;">
                <div class="form-group form-row" >
                    <span>Advisor Id: </span>
                    <span class="font-weight-bold pl-2 pr-2">{{ advisorId }}</span>
                    <span>Client Name: </span>
                    <span class="font-weight-bold pl-2">{{ clientName }}</span>
                </div>
                <div class="form-group form-row" >
                    <span>Current Assigned User: </span>
                    <span class="font-weight-bold pl-2">{{ currentUserEmail }}</span>
                </div>
                <div class="form-group form-row" v-show="getDisplayView()">
                    Please select the user you want to assign the advisor to
                    <multiselect
                        v-model="selectedUser"
                        :options="user_options"
                        :multiple="true"
                        :max="1"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :searchable="true"
                        :allow-empty="true"                        
                        :max-height="200"
                        select-label=""
                        deselect-label=""
                        selected-label=""
                        placeholder="Select only one user as the owner"
                        label="emailAddress"
                        name="advisor_destination_email"
                        track-by="userId"
                        :preselect-first="false"
                        >
                        <template #option="props">
                          <span class="checkbox-label" @click.self="select(props.option)">
                              <input name="advisor_destination_email" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                              {{ props.option.firstName }} {{ props.option.lastName }} - {{ props.option.emailAddress }}
                          </span>
                        </template>
                    </multiselect>
                </div>
            </div>                
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="assignAdvisor" :disabled="disableCopyAdvisor">Assign Advisor</button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import AdvisorService from "../services/advisor.service";
import AdminService from "../services/admin.service";
import ViewMixins from "../mixins/ViewMixins.vue";


export default {
  name: "AssignAdvisor",
  mixins: [ViewMixins],
  components: {Multiselect},
  data() {
    return {
      modalReturnMessage: "",
      isError: false,
      disableCopyAdvisor: false,
      selectedUser: [],
      user_options: [],
      refreshModal: true
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    assignAdvisor() {
      this.$root.processServerRequest("Assigning new destination email for advisor");
      AdvisorService.assignAdvisor(this.advisorId, this.selectedUser[0].userId, this.copyAdvisorCallback, this);
    },
    copyAdvisorCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$emit("reload");        
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async loadData(){
        this.setDisplayView(false);
        this.$root.processServerRequest();
        this.selectedUser = [];
        this.modalReturnMessage = "";
        this.modalReturnMessage = "Loading users please stand by....";
        await AdvisorService.getAdvisorUsers(this.advisorId, this.getAdvisorUsersCallback, this);
    },
    getAdvisorUsersCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.setDisplayView(true);
        this.user_options= response.data.data;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    closeModal(){
      this.modalReturnMessage = "";
      if(this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },    
  },
  watch: {},
  props: {
    modalChartData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    advisorId: {
      type: Number,
      default: 0
    },
    clientName: {
      type: String,
      default: "",
    },
    currentUserEmail: {
      type: String,
      default: "",
    },
    modalId: {
      type: String,
      default: "assign-advisor",
    },
    modalTitle: {
      type: String,
      default: "Assign Advisor",
    },
  }
};
</script>
