<template>
  <div class="w-100">
    <div class="title"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
    <div class="client-hierarchy-list">
      <div class="form-row">
        <div class="pl-2 pb-2">Drag & Drop client to add or remove them from the group</div>
      </div>
      <div class="form-row">
        <div class="col-5">
          <div class="draggable-group-header">Current Client Hierarchy</div>
          <VueDraggable
            v-model="clientHierarchyList"
            :disabled="!enabled"
            class="list-group draggable-container"
            ghost-class="draggable-ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
            options="people"
            group="bucket"
          >
            <div class="list-group-item draggable-card" v-for="element in clientHierarchyList" :key="element.label" :index="element.label">
              <font-awesome-icon :icon="['fas', 'grip-vertical']" />
              <div class="float-right">
                <a load-on-starup="true" href="javascript:;" class="reload" @click="$modal.show('Client - ' + element.clientId)">
                  <font-awesome-icon :icon="['fas', 'window-close']" />
                </a>
              </div>
              <span class="pl-1">{{ element.label }}</span>
              <modal
                :modal-id="'Client - ' + element.clientId"
                :modal-title="'Remove From Client Hierarchy'"
                :modal-content="'Are you sure you remove ' + element.label + ' from the client group?'"
                :modal-result-id="element.clientId"
                :include-confirmation="true"
                @confirm="removeFromClientHierarchy(element)"
              />
            </div>
            <div class="text-center" v-show="emptyClientHierarchyList">Drag & Drop here to add client to the hierarchy</div>
          </VueDraggable>
        </div>
        <div class="col-1"></div>
        <div class="col-5">
          <div class="draggable-group-header">Available Clients</div>
          <div class="form-group form-inline no-gutters pt-2">
            <input
              v-model="availableClientList"
              id="availableClientList"
              placeholder="Search"
              name="availableClientList"
              :disabled="!displayView"
              type="text"
              class="form-control w-100"
            />
          </div>
          <VueDraggable
            v-model="filteredAndSortedAvailableClientList"
            :disabled="!enabled"
            class="list-group draggable-container"
            ghost-class="draggable-ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
            options="people"
            group="bucket"
          >
            <div class="list-group-item draggable-card" v-for="element in filteredAndSortedAvailableClientList" :key="element.label" :index="element.label">
              <font-awesome-icon :icon="['fas', 'grip-vertical']" />
              <div v-if="isClientHierarchy(element)" class="float-right">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </div>
              <span class="pl-1">{{ element.label }}</span>
            </div>

            <div class="text-center" v-show="availableClientsList.length <= 0">Drag & Drop here to remove client from the group</div>
          </VueDraggable>
        </div>
      </div>
      <div class="col mt-3" role="group">
        <button class="btn btn-primary float-right ml-2" @click="showConfirmationMessage()">Cancel</button>
        <button type="submit" class="btn btn-primary float-right ml-2" @click="saveClientHierarchy()">
          <span>Save</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>

import AdminService from "../../services/admin.service";
import ClientService from "../../services/client.service";
import AlertService from "../../services/alert.service";

import ViewMixins from "../../mixins/ViewMixins.vue";

import { VueDraggable } from 'vue-draggable-plus';
import { reactive, toRaw } from 'vue'
import $ from 'jquery';
let id = 1;
export default {
  name: "EditClientHierarchy",
  mixins: [ViewMixins],
  components: {
    VueDraggable
  },
  props: {
    initialClientId: {
      type: Number,
      default: 0
    },
    initialClientCode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      validator: null,
      title: "Client Hierachy",
      mode: "",
      enabled: true,
      dragging: false,
      clientHierarchyList: [],
      availableClientsList: [],
      availableClientList: ""
    };
  },
  methods: {
    showConfirmationMessage() {
      AlertService.showConfirmationMessage(this.$parent.toggleDisplayClientHierarchy);      
    },     
    checkMove: function(e) {},
    isClientHierarchy: function(element) {
      return ClientService.isClientHierarchy(element);
    },
    resetForm: function() {
      (this.clientHierarchyList = []), (this.availableClientsList = []);
    },
    loadData: function() {
      this.resetForm();
    },
    removeFromClientHierarchy(inputElement) {
      let element = toRaw(inputElement);      
      var match = this.clientHierarchyList.find(x => Number(x.clientId) === Number(element.clientId));
      if (typeof match !== "undefined") {
        this.clientHierarchyList.splice($.inArray(match, this.clientHierarchyList), 1);
        this.availableClientsList.push(element);
      }      
    },
    async saveClientHierarchy() {
      this.$root.processServerRequest("Saving client group data");
      ClientService.saveClientHierarchy(this.initialClientCode, JSON.stringify(this.clientHierarchyList), this.getClientHierarchyCallback, this);
    },
    async getClientHierarchy() {
      this.$root.processServerRequest("Retrieving client group data");
      ClientService.getClientHierarchy(this.initialClientCode, this.getClientHierarchyCallback, this);
    },
    getClientHierarchyCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        ClientService.clearCache();
        ClientService.getAllClientsFromDatabase(this.$parent.$parent.$parent.getAllClientsCallback);
        this.setDisplayView(true);
        this.title = "Modify Client Hierarchy";
        this.mode = "edit-client-hierarchy";
        this.clientHierarchyList = response.data.data.clientHierarchyList;
        this.clientHierarchyList = this.clientHierarchyList.sort(this.compare);

        this.availableClientsList = response.data.data.availableClientsList;
        this.availableClientsList = this.availableClientsList.sort(this.compare);

        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    compare(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  },
  created() {
    this.mode = this.initialMode;
    if (this.mode == "edit-client-hierarchy") {
      this.title = "Modify Client Hierarchy";
    } else if (this.mode == "add-client-hierarchy") {
      this.title = "Add Client Hierarchy";
    }
    if (this.initialClientCode != ""){
      this.getClientHierarchy();
    }
  },
  mounted() {},
  computed: {
    emptyClientHierarchyList: function() {
      return this.clientHierarchyList.length <= 0;
    },
    filteredAndSortedAvailableClientList: {
      set: function(newValue) {
        this.availableClientsList = newValue;
      },
      get: function() {
        if (this.availableClientList != "") {
          return this.availableClientsList.filter(client => {
            return client.label.toLowerCase().includes(this.availableClientList.toLowerCase());
          });
          //.sort(this.compare);
        } else {
          return this.availableClientsList;
        }
      }
    }
  }
};
</script>