<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" @opened="loadData" :adaptive="true" :resizable="true" :scrollable="true" width="820" :maxHeight="1050" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-show="getDisplayView()" class="modal-body manage-responses">
        <div class="m-1 info-message" v-if="!error">{{ returnMessage }}</div>
        <div class="error-message" v-if="error">{{ returnMessage }}</div>        
            <div class="form-group form-inline pt-1 pb-1 no-gutters">
                <label for="inputFile">Input Type</label>
                <div class="ml-2 form-check form-check-inline">
                  <input type="radio" id="inputList" name="inputList" class="form-check-input" value="list"  v-model="inputType" @click="saveChoice()">By List 
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" id="inputFile" name="inputFile" class="form-check-input" value="file" v-model="inputType" @click="saveChoice()">By File 
                </div>
            </div>                
            <div class="form-row">
            <div v-show="isList" class="form-group form-inline w-100">
                <label for="surveyTakerId" class="mr-1">Survey Taker Id</label>
                <input type="text" class="form-control w-50" placeholder="Enter comma delimited list of survey taker ids" id="surveyTakerId" name="surveyTakerId" v-model="surveyTakerId" />
            </div>
            <div v-show="isFile" class="form-group form-row">
              <span class="pr-2">Select a file to upload</span>
              <input ref="importRespondentDataUpload" type="file" @change="onFileChange" />
            </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
            <button v-show="isFile" type="submit" class="btn btn-primary ml-2" v-on:click="this.importFile">
              <span>Import File</span>
              <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button v-show="isList" type="button" class="btn btn-primary ml-4" @click="processResponses" :disabled="disableButton">{{ buttonName }}</button>
            <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import SurveySummaryService from "../services/survey.summary.service";
import AlertService from "../services/alert.service";
import AdminService from "../services/admin.service";
import ViewMixins from "../mixins/ViewMixins.vue";

export default {
  name: "ManageResponses",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      surveyTakerList: [],
      surveyTakerId: "",
      returnMessage: "",
      disableButton: false,
      error: false,
      importRespondentDataImportFile: "",
      inputType: "list",
      refreshModal: true
    };
  },
  props: {
    inputMode: {
        type: String,
        default: ""
    },     
    modalId: {
      type: String,
      default: "manage-responses",
    },
    modalTitle: {
      type: String,
      default: "Manage Responses",
    },
  },
  computed: {
    isList: function(){
        return this.inputType == "list";
    },
    isFile: function(){
        return this.inputType == "file";
    },    
    buttonName: function(){
        if (this.inputMode == "grade" ){
            return "Grade";
        } else if (this.inputMode == "delete" ){
            return "Delete";
        }
        return "";
    },
    requestMessage: function(){
        if (this.inputMode == "grade" ){
            return "Grading Responses";
        } else if (this.inputMode == "delete" ){
            return "Deleting Responses";
        }
        return "";
    },
  },
  mounted() {},
  created() {},
  methods: {
    onFileChange: function() {
      this.importRespondentDataImportFile = this.$refs.importRespondentDataUpload.files[0];
    },
    async importFile() {
      if (this.importRespondentDataImportFile){  
        this.$root.processServerRequest("Importing respondent data");
        this.setModalLoading(true);
        if (this.inputMode == "grade" ){
          SurveySummaryService.gradeResponsesByFile(this.importRespondentDataImportFile, this.processResponsesCallback, this);
        } else if (this.inputMode == "delete" ){
          SurveySummaryService.deleteResponsesByFile(this.importRespondentDataImportFile, this.processResponsesCallback, this);
        }
      } else {
        AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
      }      

    },
    processResponses(){
        this.surveyTakerList = this.surveyTakerId.split(",");
        this.$root.processServerRequest(this.requestMessage);
        this.disableButton = true;
        if (this.inputMode == "grade" ){
            SurveySummaryService.gradeResponsesByList(this.surveyTakerList, this.processResponsesCallback, this);
        } else if (this.inputMode == "delete" ){
            SurveySummaryService.deleteResponses(this.surveyTakerList, this.processResponsesCallback, this);
        }
    },
    processResponsesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      this.returnMessage = response.data.message;
      this.setDisplayView(true);
      this.setModalLoading(false);      
      this.disableButton = false;
      if (statusCode == 200) {
        this.error = false;
      } else {
        this.error = true;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.disableButton = false;
    },
    loadData() {
      this.reset();
    },
    reset() {
      AlertService.closeAlert();
      this.returnMessage = "";
      this.surveyTakerId = "";
      this.surveyTakerList = [];
      this.error = false;
      this.inputType = "list";
      this.setDisplayView(true);
      this.setModalLoading(false);      
    },
    closeModal() {
      this.setDisplayView(false);
      AlertService.closeAlert();
      if (this.$refs[this.modalId] != null) {
        this.$refs[this.modalId].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },
  },
  watch: {},
};
</script>
