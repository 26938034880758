<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="setupDashboardCard" :resizable="true" :scrollable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        <dashboard-competitive-rank  v-if="saveClientChartObject.displayType == 'competitive-rank'" 
          ref="competitiveRank" dashboard-id="competitiveRank" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject" :read-only="true"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-rank',false)"
        />
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import DashboardCompetitiveRank from "../components/dashboard/DashboardCompetitiveRank.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"
export default {
  name: "DashboardCompetitiveRankModal",
  mixins: [DashboardCardMixins],
  components: {DashboardCompetitiveRank},
  props: {},
  data() {
    return {
      title: "Competitive Rank",
    };
  },
  methods: {
  },
  watch: {},
  created() {},
  mounted() {},
  umnounted() {},
  computed: {}
};
</script>
