<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          {{ title }}
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Expectations Map" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('expectations-map-save-chart-confirmation')"> 
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">            
            View Details
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'arrow-circle-right']" />
            </span>
          </a>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-expectations-map">
        <dashboard-card-dropdown label="By" :read-only="readOnly" :options="expectations_type_options" :default-value="expectationsBy" @change="saveExpectationsBy"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body"  v-show="displayDashboard">
      <div class="expectations-map-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" -->
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" :modal-chart-options="options"/>
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>
<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import DashboardCardDropdown from "./DashboardCardDropdown";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";
import Vue from "vue";
import echarts from "echarts";
import $ from "jquery";
import { markRaw } from 'vue'; 
export default {
  name: "DashboardExpectationsMap",
  mixins: [DashboardMixins],
  components: { DashboardCardDropdown,SaveChart,DashboardCardSkeleton },
  data() {
    return {
      title: "Expectations Map",
      modalName: "expectations-map-save-chart-confirmation",
      expectations_type_options: [
        { name: "Needs Area", value: "need" },
        { name: "Moat Advantage", value: "moat" },
        { name: "Value Driver", value: "value" }
      ],
      colorsByValue: ["#ECD2B2", "#E0B37F", "#A58377", "#9F3F23", "#6B2917"],
      colorsByNeed: ["#2A5C8C", "#5B9BD5", "#92A0AC", "#566571", "#69A0A9"],
      colorsByMoats: ["#4C3A00", "#826300", "#BF9000", "#FFCD2F", "#FFE48F"],
      expectationsBy: "need",
      baseSeries: {
        name: "",
        top: "-20",
        type: "treemap",
        roam: false,
        leafDepth: 1,
        visibleMin: 300,
        label: {
          show: true,
          fontSize: 12,
          formatter: "{b}",
          color: "#000"
        },
        upperLabel: {
          show: true,
          height: 30
        },
        itemStyle: {
          borderColor: "#fff"
        },
        levels: [
          {
            itemStyle: {
              gapWidth: 4
            },
            upperLabel: {
              show: true
            }
          },
          {
            colorSaturation: [0.35, 0.5],
            itemStyle: {
              gapWidth: 1,
              borderColorSaturation: 0.6
            },
            upperLabel: {
              show: true
            }
          }
        ],
        width: "95%",
        height: "100%",
        data: []
      }
    };
  },
  props: {},
  computed: {},
  mounted() {
    //console.log("DashboardExpectationsMap::mounted");
    this.setColors();
  },
  created() {},
  methods: {
    setColors: function() {
      if (this.expectationsBy == "need") {
        this.baseSeries.levels[0].color = this.colorsByNeed;
        this.baseSeries.label.color = "#fff";
      } else if (this.expectationsBy == "value") {
        this.baseSeries.levels[0].color = this.colorsByValue;
        this.baseSeries.label.color = "#000";
      } else {
        this.baseSeries.levels[0].color = ["#4D8352"];
        this.baseSeries.levels[1].color = this.colorsByMoats;
        //this.baseSeries.label.color = "#000";
      }
    },
    initChart: function(dynamicOptions) {
      //https://echarts.apache.org/examples/en/editor.html?c=treemap-disk
      this.setColors();
      let chartDiv = $(".expectations-map-dashboard-chart")[0];
      this.chart = markRaw(echarts.init(chartDiv));
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getExpectationsMapOptions(echarts);
      let dynamicMapData = dynamicOptions.series[0].data[0];
      this.baseSeries.data = dynamicMapData;
      $.extend(true, this.options.series, this.baseSeries);
      // eslint-disable-next-line no-console
      //      console.log("Exceptions Map Options: " + JSON.stringify(this.options));
      this.chart.setOption(this.options, true);
    },
    loadData: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardExpectationsMap::loadData");
      this.displayDashboard = false;
      this.expectationsBy = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_EXPECTATIONS_MAP_BY_SETTING_TYPE, this.expectationsBy, this.expectations_type_options);
      DashboardService.getExpectationsMap(this.timePeriodId, this.expectationsBy, this.getExceptionsMapCallback, this);
    },
    saveExpectationsBy: function(value) {
      this.expectationsBy = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_EXPECTATIONS_MAP_BY_SETTING_TYPE, this.expectationsBy);
      this.updateCard();
    },
    updateCard: async function(value) {
      // eslint-disable-next-line no-console
      //console.log("DashboardExpectationsMap::updateCard");
      this.displayDashboard = false;
      this.expectationsBy = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_EXPECTATIONS_MAP_BY_SETTING_TYPE, this.expectationsBy, this.expectations_type_options);
      await DashboardService.getExpectationsMap(this.timePeriodId, this.expectationsBy, this.getExceptionsMapCallback, this);
    },
    getExceptionsMapCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayDashboard = true;
        let dynamicOptions = response.data.data.options;
        this.initChart(dynamicOptions);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {}
};
</script>
