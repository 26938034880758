<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="benchmark-company-list container">
      <div class="button-container">
        <div class="button-left"></div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="$modal.show('import-benchmark-data-file')">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Benchmark Data <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a href="/api/v1/admin/download-benchmark-company-import-template" @click.prevent="downloadItem()" id="DownloadBenchmarkCompanyImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>

      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
    <vue-modal ref="import-benchmark-data-file" name="import-benchmark-data-file" :width="368" :height="400">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Benchmark Data</h5>
        </div>
        <div class="form-group form-row p-2">
          {{ returnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importBenchmarkCompanyUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import BenchmarkCompanyService from "../../services/benchmark.company.service";

export default {
  name: "BenchmarkCompanyList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importBenchmarkCompanyImportFile: "",
      returnMessage: "",
      title: "Benchmark Company List",
      table: {
        id: "benchmark-company-list-table",
        columns: BenchmarkCompanyService.getTableColumnData(),
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      BenchmarkCompanyService.getBenchmarkSummaryDataList(this.getTableDataCallback, this);
    },
    getDownloadFilename() {
      return "Benchmark Data Import Template";
    },
    onFileChange: function() {
      this.importBenchmarkCompanyImportFile = this.$refs.importBenchmarkCompanyUpload.files[0];
    },
    async importFile() {
      this.$root.processServerRequest("Importing benchmark data");
      this.setModalLoading(true);
      BenchmarkCompanyService.importBenchmarkSummaryData(this.importBenchmarkCompanyImportFile, this.importFileCallback, this);
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.returnMessage = response.data.message;
        if (this.$refs['import-benchmark-data-file'] != null) {
          this.$refs['import-benchmark-data-file'].visible = false;
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    closeModal: function() {
      if (this.$refs['import-benchmark-data-file'] != null) {
        this.$refs['import-benchmark-data-file'].visible = false;
      }
      this.$router.go(0);
    }
            
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/admin/download-benchmark-company-import-template";
    }
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [
        [2, "desc"],
        [1, "asc"]
      ];
    }
  },
  props: {},
  mounted() {}
};
</script>
