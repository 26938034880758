<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import GoogleService from "../services/google.service";
import SurveyGroupService from '../services/survey.group.service';
import { reactive, toRaw } from 'vue'
export default {
  name: "ChartCriteriaMixins",
  components: {
    /* eslint-disable vue/no-unused-components */
  },
  data() {
    return {
      segments: [],
      criteriaResults: [],
      survey_group_options: [],
      surveyGroup: "",
      survey_location_options: [],
      word_options: [],
      words: [],
      surveyLocation: [],
      resetOption: false,
      showPublicGroups: "N",
      useEqualWeight: "N",
      disableEqualWeight: true,
      surveyGroupList: [],
    };
  },
  props: {},
  created() {},
  methods: {
    onInputWords(value) {
      this.words = value;
    },
    setUseEqualWeight: function() {
      if (this.surveyGroup.length != 0 || this.surveyGroup != ""){
        this.disableEqualWeight = false;
      } else {
        this.useEqualWeight = "N";
        this.disableEqualWeight = true;
      }
    },
    getSurveyGroupOptions: function(){
      return SurveyGroupService.getSurveyGroupOptions(this.surveyGroupList,this.showPublicGroups,this.clientId);
    },    
    showPopup(){
      this.$refs.countsByLocation.loadData();
      this.$modal.show("counts-by-location");
    },
    resetCustomFields: function(){
      this.findCriteria("Custom Time Period").values = [];
      this.findCriteria("Custom Start Date").values = [];
      this.findCriteria("Custom End Date").values = [];
    },
    resetCriteriaResults: function() {
      var criteriaResults = this.criteriaResults;
      for (var index = 0; index < criteriaResults.length; index++) {
        criteriaResults[index].values = [];
      }
    },
    findCriteriaByParameter: function(value) {
      return this.criteriaResults.find(x => x.parameterName == value);
    },
    findCriteria: function(value) {
      return this.criteriaResults.find(x => x.name == value);
    },
    saveValue: function(event, group_label, parameterName, updateValue) {
      this.saveResult(event, group_label, parameterName, updateValue);
    },
    saveResult: function(event, group_label, parameterName, updateValue) {
      this.saveResultValue(event.target.value, group_label, parameterName, updateValue);
    },
    saveCheckboxValue: function(event, group_label, parameterName, updateValue) {
      let checkboxValue = event.target.checked ? "Y" : "N";
      let title = typeof this.$parent.chart != "undefined" ? this.$parent.chart.title : this.chart.title
      GoogleService.logChartClickEvent(title, this.displayType, this.surveyName, group_label, this);
      this.saveResultValue(checkboxValue, group_label, parameterName, updateValue);
    },
    saveDateValue: function(event, group_label, parameterName, updateValue) {
      let dateValue = event.toLocaleDateString("en-US");
      this.saveResultValue(dateValue, group_label, parameterName, updateValue);
    },
    saveResultValue(value, group_label, parameterName, updateValue) {
      if (updateValue) {
        this.findCriteria(group_label).values = [];
        this.findCriteria(group_label).values.push(value);
      } else {
        let remove = this.findCriteria(group_label).values.includes(value);
        if (!remove) {
          this.findCriteria(group_label).values.push(value);
        } else {
          this.findCriteria(group_label).values.splice($.inArray(value, this.findCriteria(group_label).values), 1);
          this.findCriteria(group_label).displayValue = "";
        }
      }
    },
    buildCriteriaUrl: function(params) {
      let segments = [];
      let segmentKey = "segment";
      var criteriaResults = this.criteriaResults;
      for (var index = 0; index < criteriaResults.length; index++) {
        var criteriaValues = criteriaResults[index].values;
        var parameterName = criteriaResults[index].parameterName;
        if (criteriaValues.length > 0) {
          var displayValue = "";
          for (var valueIndex = 0; valueIndex < criteriaValues.length; valueIndex++) {
            displayValue += this.getPrintableValue(criteriaValues[valueIndex], criteriaResults[index].options);
            if (valueIndex < criteriaValues.length - 1) {
              displayValue += ",";
            }
            this.criteriaResults[index].displayValue = displayValue;
            if (parameterName.includes(segmentKey)) {
              let chartSegment = JSON.parse(JSON.stringify(criteriaResults[index]));
              chartSegment.segmentQuestionId = parameterName.split("_")[1];
              delete chartSegment["options"];
              var match = segments.find(x => x.name == chartSegment.name);
              if (typeof match !== "undefined") {
                segments.splice($.inArray(match, segments), 1);
              }
              segments.push(chartSegment);
            } else {
              params.append(parameterName, criteriaValues[valueIndex]);
            }
          }
        }
      }
      if (segments.length > 0) {
        params.append("segments", JSON.stringify(segments));
      }
      if (typeof this.surveyLocation != "undefined" && this.surveyLocation.length > 0) {
        params.append("surveyLocation", this.surveyLocation);
      }      
      if (typeof this.words != "undefined" && this.words.length > 0) {
        params.append("words", this.words);
      }      
      if (typeof this.showNeeds != "undefined" && this.showNeeds.length > 0) {
        for (var showNeedsIndex = 0; showNeedsIndex < this.showNeeds.length; showNeedsIndex++) {
          params.append("show_needs", this.showNeeds[showNeedsIndex].value);
        }
      }
      if (typeof this.showMetrics != "undefined" && this.showMetrics.length > 0) {
        for (var showMetricsIndex = 0; showMetricsIndex < this.showMetrics.length; showMetricsIndex++) {
          params.append("show_metrics", this.showMetrics[showMetricsIndex].value);
        }
      }
      if (typeof this.showMetricGroups != "undefined" && this.showMetricGroups.length > 0) {
        for (var showMetricGroupsIndex = 0; showMetricGroupsIndex < this.showMetricGroups.length; showMetricGroupsIndex++) {
          params.append("show_metric_groups", this.showMetricGroups[showMetricGroupsIndex].value);
        }
      }
      return params;
    },
    getPrintableValue(value, options) {
      if (Number.isInteger(value)) {
        value = value.toString();
      }
      let printableOptions = options.find(x => x.value == value);
      if (printableOptions && printableOptions.name) {
        return printableOptions.name;
      } else {
        return "";
      }
    },
    getSurveyLocationsCallback(response) {
      this.survey_location_options = response;
      this.survey_location_options = this.survey_location_options.filter(function(inputOption) {
        let option = toRaw(inputOption);
        return typeof option.id != "undefined";
      });
      let vm = this
      this.survey_location_options = this.survey_location_options.map(function(inputOption) {
        let option = toRaw(inputOption);
        return vm.resetOptionsForTreeselect(option);        
      });
    },
    getWordCountsCallback(response) {
      this.word_options = response;
      this.word_options = this.word_options.filter(function(inputOption) {
        let option = toRaw(inputOption);
        return typeof option.id != "undefined";
      });
      let vm = this
      this.word_options = this.word_options.map(function(inputOption) {
        let option = toRaw(inputOption);
        return vm.resetOptionsForTreeselect(option);        
      });
    },
    resetOptionsForTreeselect: function(option) {
      let resetClientForOptionsRecursive = function(option) {
        let updatedOption = {};
        updatedOption.value = option.id;
        if (typeof option.children === "undefined") {
          updatedOption.children = [];
        } else {
          updatedOption.children = option.children.map(resetClientForOptionsRecursive);
        }
        if (typeof option.label !== "undefined") {
          updatedOption.name = option.label;
        }
        return updatedOption;
      };

      return resetClientForOptionsRecursive(option);
    },     
  },
  watch: {
    showPublicGroups: function() {
      this.survey_group_options = this.getSurveyGroupOptions();
    },
  },
  mounted() {

  },
  computed: {
    hasSurveyGroups: function() {
      return this.survey_group_options.length > 0;
    },
    disableChartCriteriaWidgets: function() {
      return this.$root.getLoading();
    }
  }
};
</script>
